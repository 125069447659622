import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import Button from "@material-ui/core/Button";
import validator from 'validator';
import { notify } from '../../utils/handler';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { triggerOTP } from '../../utils/Helpers';

const RegisterPersonal = (props) => {

    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
    });

    if(!props.user) return null;

    const handleChange = (e) => {
        const target = e.target;
        setData(prevData => ({
            ...prevData,
            [target.id]: target.value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(data, props);
        if (!validate()) return;
        const request = {
            method: 'POST',
            url: 'admin/updateuserInfo',
            data: data
        };
        const updateUserResponse = await apiRequest(request);
        if (updateUserResponse.success) {
            const otpTriggered = await triggerOTP({...data, email: props.user.email});
            otpTriggered && props.onComplete({
                ...data
            });
        }
        else {
            console.log(updateUserResponse);
            notify(updateUserResponse.data.message);
        }
    }

    const validate = () => {
        if (validator.isEmpty(data.firstName)) {
            notify("Please enter your first name");
            return false;
        }
        if (!validator.isMobilePhone(data.phoneNumber)) {
            notify("Please enter a valid phone number");
            return false;
        }
        return true;
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex align-items-baseline">
                <label className="label w-25" htmlFor="firstName">First Name *</label>
                <input 
                    className="w-75" 
                    id="firstName"
                    name="firstName"
                    value={data.firstName} 
                    onChange={handleChange} 
                    type="text"
                    placeholder="Enter your first name"
                />
            </div>
            <div className="d-flex align-items-baseline">
                <label className="label w-25" htmlFor="lastName">Last Name</label>
                <input 
                    className="w-75" 
                    id="lastName"
                    name="lastName"
                    value={data.lastName} 
                    onChange={handleChange} 
                    type="text"
                    placeholder="Enter your last name"
                />
            </div>
            <div className="d-flex align-items-baseline">
                <label className="label w-25" htmlFor="phoneNumber">Phone Number *</label>
                <PhoneInput 
                    className="w-75" 
                    id="phoneNumber"
                    name="phoneNumber"
                    value={data.phoneNumber} 
                    onChange={value => setData({...data, phoneNumber: value})} 
                    type="text"
                    placeholder="Enter your phone number"
                />
            </div>
            <div className="row">
                <Button type="submit" className="submit_button" >GET OTP</Button>
            </div>
        </form>
    )
}

export default RegisterPersonal;