import React, { useState } from 'react'
import './ForgotPassword.css'
import Page from '../../components/Page';
import LOGO from '../../assets/img/logo/logo_200.png';
import { REGISTEREDMAIL, SIGNINGIN, SUBMIT, FORGOTPASSWORD } from "../../Constants"
import { Link } from 'react-router-dom'
import requests from '../../utils/requests';
import apiRequest from '../../services';
import { notify } from '../../utils/handler';

const ForgotPassword = (props) => {
    const [data, setData] = useState({
        email: '',
    });
    
    const queryParams = new URLSearchParams(props.location.search);
    const requestUrl = queryParams.get('utype') === 'admin' ? requests.forgotPasswordAdmin : requests.forgotPasswordUser;

    const sendForgot = async (e) =>{
        e.preventDefault();
        const request = {
            method: 'POST',
            url: requestUrl,
            data: data
        }
        console.log(request);
        const response = await apiRequest(request);
        if(response.data.error){
            notify(response.data.message,'error');
            return;
        }
        notify(response.data.message, 'success');
    }

    return (
        <Page className="ForgotPasswordPage">
            <div className="ForgotPasswordcontainer">
                <div className="ForgotPasswordform">
                    <form onSubmit={sendForgot} className="container" >
                        <div className="ForgotPasswordimg">
                            <img src={LOGO} alt="logo" />
                        </div>
                        <div className="ForgotPasswordimg">
                            <h4 className='forgotpwd_heading'>{FORGOTPASSWORD}</h4>
                        </div>
                        <div>
                            <label className='labels' >{REGISTEREDMAIL}</label>
                            <input type="text" placeholder="Please enter your registered email id"  required 
                            onChange={(e) => setData({
                                email: e.target.value
                              })} />
                            <br />
                            <div >
                                <span className="password"><Link className='forgotpwd' to="/client">{SIGNINGIN}</Link></span>
                            </div>
                            <br /> <br />
                            <button type="submit" >{SUBMIT}</button>
                        </div>
                    </form>
                </div>
            </div>
        </Page>
    )
}

export default ForgotPassword


