import axios from 'axios';
import Requests from '../utils/requests';
import Cookies from 'universal-cookie';

axios.defaults.baseURL = Requests.BASE_URL
const timeout = 100000

export default async function apiRequest(apiParams) {
    const cookies = new Cookies();
    const token = cookies.get('SID');
    if (token) axios.defaults.headers.common['x-access-token'] = token;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.crossDomain = true;
    const parseErrorMessage = (error) => {
        if (error.code === 'ECONNABORTED') {
            return 'Please check your network connection and try again.'
        }
        return error.message
    }
    return await axios({ timeout, ...apiParams }).then((response) => {
            return { data: response.data ?  response.data.data ? response.data.data : response.data  : response, success: response.data.error ? false : true };
    }).catch((error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log("=>>>>3",error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
        } else {
            // Something happened in setting up the request that triggered an Error
        }
        const errorObj = error && error.response && error.response.data && error.response.data && error.response.data.message ? error.response.data.message   : error.toJSON();
        return {
            ...errorObj,
            message: parseErrorMessage(errorObj),
            // data: errorObj.data&& errorObj.data. data ? errorObj.data.data : {},
            // name: err.name,
            // stack: exception.stack,
            success: false
        };
    });;
}


export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}