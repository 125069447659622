import apiRequest from "../services";
import requests from "./requests";
import { notify } from "./handler";

export const triggerOTP = async (data) => {
    const otpTriggerRequest = {
      method: 'POST',
      url: requests.otp,
      data: {
        phoneNumber: data.phoneNumber,
        email: data.email,
      },
    };
    const otpTriggerResponse = await apiRequest(otpTriggerRequest);
    if (otpTriggerResponse.success) {
      notify(otpTriggerResponse.data.message, 'success');
      return true;
    } else {
      notify(otpTriggerResponse.data.message);
      return false;
    }
  }

  export const fetchUser = async () => {
    const response = await apiRequest({
      method: 'GET',
      url: requests.user
    });
    console.log("fetchUser =>", response);
    if (response.success && response.data) {
      return response.data;
    }
  }