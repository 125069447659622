import React, { useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Button } from 'reactstrap';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const AdminKYCAction = (props) => {
    console.log(props);
    const [undo, setundo] = useState(false);
    return (
        <div>
            <div className="document d-flex flex-column">
                {props.docPath ?
                    <TransformWrapper
                        initialScale={1}
                        initialPositionX={0}
                        initialPositionY={0}
                        centerZoomedOut={true}
                        limitToBounds
                    >
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <React.Fragment>
                                <div className="tools d-flex justify-content-center my-2">
                                    <button className="btn btn-light" onClick={() => zoomIn()}>
                                        <i class="fa fa-search-plus" aria-hidden="true"></i>
                                    </button>
                                    <button className="btn btn-light mx-2" onClick={() => zoomOut()}>
                                        <i class="fa fa-search-minus" aria-hidden="true"></i>
                                    </button>
                                    <button className="btn btn-light" onClick={() => resetTransform()}>
                                        <i class="fa fa-undo" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <TransformComponent
                                    contentClass="d-flex justify-content-center h-100"
                                    wrapperStyle={{height: '20rem'}}
                                >
                                    {props.docPath.includes("pdf") ?
                                        <object height="100%" data={props.docPath} type="application/pdf"></object>
                                        :
                                        <img src={props.docPath} alt="proof document" />
                                    }
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                    :
                    <div 
                        style={{height: '23.5rem'}}
                        className="d-flex justify-content-center align-items-center"
                    >
                        No Document Available
                    </div>
                }                   
            </div>
            <div className="d-flex justify-content-center my-3">
                {(props.isApproved || props.isRejected) && !undo ?
                    <React.Fragment>
                        {props.isApproved && 
                        <React.Fragment>
                            <Chip label="Approved" color="success" icon={<CheckCircleIcon />} />
                            <Button 
                                outline
                                color="dark"
                                size="sm"
                                className="mx-2"
                                onClick={() => {setundo(true)}}
                            >
                                <i class="fa fa-undo" aria-hidden="true"></i>
                            </Button>

                        </React.Fragment>
                        }
                        {props.isRejected && 
                            <React.Fragment>
                                <Chip label="Rejected" color="error" icon={<ErrorIcon />} />
                                <Button 
                                    outline
                                    color="dark"
                                    size="sm"
                                    className="mx-2"
                                    onClick={() => {props.handleUploadPrompt(props.docType)}}
                                >
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                </Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {props.docPath ?
                            <React.Fragment>
                                <Button 
                                    color="success" 
                                    size="sm" 
                                    className="mx-2"
                                    onClick={() => {props.handleApproveReject(props.docType, true)}}
                                >
                                    Approve
                                </Button>{' '}
                                <Button 
                                    
                                    color="danger"
                                    size="sm"
                                    className="mx-2"
                                    onClick={() => {props.handleApproveReject(props.docType, false)}}
                                >
                                    Reject
                                </Button>
                            </React.Fragment>
                            :
                            null
                        }
                        <Button 
                            outline
                            color="dark"
                            size="sm"
                            className="mx-2"
                            onClick={() => {props.handleUploadPrompt(props.docType)}}
                        >
                            <i class="fa fa-upload" aria-hidden="true"></i>
                        </Button>
                    </React.Fragment>
                }
            </div>
        </div>
    );
}

export default AdminKYCAction;