import React, { useState, useMemo, useEffect } from 'react';
import Select from 'react-select';
import Button from "@material-ui/core/Button";
import validator from 'validator';
import countryList from 'react-select-country-list';
import moment from 'moment';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import 'react-phone-number-input/style.css'
import {
    DATEOFBIRTHS, COUNTRYOFBIRTHS, SUBMIT, NATIONALITYS, CITYS, ADDRESSS, ZIPCODES
} from "../../Constants"

const RegisterAddress = (props) => {

    const [data, setData] = useState({
        dob: '',
        countyOfBirth: '',
        nationality: '',
        city: '',
        address: '',
        zipCode: ''
    });

    const options = useMemo(() => countryList().getData(), []);

    useEffect(() => {
        setData({
            ...props.user,
            countyOfBirth: {value: props.user.countyOfBirth, label: props.user.countyOfBirth},
            nationality: {value: props.user.nationality, label: props.user.nationality}
        });
    }, []);

    if (!props.user) return null;

    const handleChange = (e) => {
        const target = e.target;
        setData(prevData => ({
            ...prevData,
            [target.id]: target.value
        }));
    }

    const handleCountryChange = (value) => {
        setData(prevData => ({
            ...prevData,
            countyOfBirth: value
        }));
    }

    const handleNationalityChange = (value) => {
        setData(prevData => ({
            ...prevData,
            nationality: value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;
        const getKycResponse = await apiRequest({
            method: 'GET',
            url: requests.kyc
        });
        const endpoint = (getKycResponse.success && getKycResponse.data) ? requests.kycUpdate : requests.kyc;
        const request = {
            method: 'POST',
            url: endpoint,
            data: {
                ...data,
                countyOfBirth: data.countyOfBirth.label,
                nationality: data.nationality.label,
                userId: props.user.id,
                email: props.user.email
            }
        };
        const response = await apiRequest(request);
        if (response.success) {
            props.onComplete({
                ...data,
                countyOfBirth: data.countyOfBirth.label,
                nationality: data.nationality.label
            });
        } else {
            notify(response.message || response.data.message || 'Registration failed!')
        }
    };

    const validate = () => {
        const emptyInputs = Object.values(data).filter(item => item === "");
        if (emptyInputs.length) {
            notify("Please enter all the details");
            return false;
        }
        if (!validator.isDate(data.dob)) {
            notify("Please enter a valid date");
            return false;
        }
        const ageCheckpoint = moment().subtract(21, 'years').format("YYYY-MM-DD");
        if (!validator.isBefore(data.dob, ageCheckpoint)) {
            notify("You have to be 21 years or older to sign up for an account.");
            return false;
        }
        return true;
    }
    
    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-25">
                    <label className="label" htmlFor="birthday">{DATEOFBIRTHS}</label>
                </div>
                <div className="col-75">
                    <input 
                        className="register1_formfield1" 
                        onChange={handleChange} 
                        type="date" 
                        id="dob" 
                        name="dob" 
                        value={data.dob} 
                        required 
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-25">
                    <label className="label" htmlFor="country">{COUNTRYOFBIRTHS}</label>
                </div>
                <div className="col-75">
                    <Select 
                        id="countyOfBirth"
                        options={options} 
                        value={data.countyOfBirth} 
                        onChange={handleCountryChange} 
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-25">
                    <label className="label" htmlFor="nationality">{NATIONALITYS}</label>
                </div>
                <div className="col-75">
                    <Select 
                        id="nationality"
                        options={options} 
                        value={data.nationality} 
                        onChange={handleNationalityChange} 
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-25">
                    <label className="label" htmlFor="text">{CITYS}</label>
                </div>
                <div className="col-75">
                    <input 
                        className="register1_formfield1" 
                        type="text" 
                        id="city" 
                        name="city" 
                        placeholder="Please enter your city" 
                        value={data.city} 
                        onChange={handleChange} 
                        required 
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-25">
                    <label className="label" htmlFor="text">{ADDRESSS}</label>
                </div>
                <div className="col-75">
                    <input 
                        className="register1_formfield1" 
                        type="text" 
                        id="address" 
                        name="address" 
                        value={data.address} 
                        onChange={handleChange} 
                        placeholder="Please enter your address" 
                        required 
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-25">
                    <label className="label" htmlFor="text">{ZIPCODES}</label>
                </div>
                <div className="col-75">
                    <input 
                        className="register1_formfield1" 
                        type="text" 
                        id="zipCode" 
                        name="zipCode" 
                        value={data.zipCode} 
                        onChange={handleChange} 
                        placeholder="Please enter your zip code" 
                        required 
                    />
                </div>
            </div>
            <br />
            <div className="row">
                <span><Button className="submit_button" type="submit" value="Submit" >{SUBMIT}</Button></span>

            </div>
        </form>
    )
}

export default RegisterAddress;