import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import Button from "@material-ui/core/Button";
import apiRequest from "../../services";
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import { OTPDESCRIPTION } from "../../Constants";

const VerifyOTP = (props) => {

    const [otp, setOtp] = useState(''); 
    const cookies = new Cookies();

    const handleChange = (e) => {
        const target = e.target;
        setOtp(() => target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await apiRequest({
            method: 'POST',
            url: requests.otplogin,
            data: {
                ...props.data,
                "otp": otp,
            }
        });
        if (response.success) {
            props.onComplete(response.data);
        } else {
            notify(response.message || response.data.message || 'OTP verification failed!');
            props.onFailure();
        }
    }

    return (
        <section className="formsection">
            <div className="formcontainer">
                <form onSubmit={handleSubmit}>
                    <p className='otpdescription'>
                        Please verify your request with the 6 digit OTP you have received on your phone number.
                    </p>
                    <div className="d-flex align-items-baseline">
                        <label className="label w-25" htmlFor="number">Enter OTP:</label>
                        <input 
                            className="register1_formfield" 
                            type="number" 
                            id="otp" 
                            name="otp"
                            value={otp} 
                            onChange={handleChange}
                            placeholder="Please enter the OTP" required />
                    </div>
                    <div className="d-flex justify-content-end my-3">
                        <Button 
                            className="submit_button" 
                            type="submit" 
                            value="Submit" 
                        >
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default VerifyOTP;