import React, { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { AdminMainLayout } from '../../components/Layout';
import Page from '../../components/Page';
import requests from '../../utils/requests';
import apiRequest from '../../services';
import tableIcons from '../../components/MaterialTableIcons';
import { notify } from '../../utils/handler';
import './AdminLogs.css';

let columnsRaw = [
    { title: "Date & Time", field: "dateAndTime", type: "date" },
    { title: "Admin Email", field: "adminEmail", type: "string" },
    { title: "Client ID", field: "memberId", type: "string" },
    { title: "Log Entry", field: "logEntry", type: "string" },
];

let columns = columnsRaw.map(column => {
    return {...column}
});

const AdminLogs = (props) => {
    
    const [dateRange, setDateRange] = useState({startDate: "", endDate: ""});
    const [error, setError] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (dateRange.startDate > dateRange.endDate) {
            setError("Start date cannot be greater than end date");
        }
    }, [dateRange]);
    
    const handleChange = (e) => {
        const target = e.target;
        setError("");
        setDateRange(prevDateRange => ({
            ...prevDateRange, 
            [target.id]: target.value
        }));
    }
    
    const handleSubmit = async (e) => {
        if (!error) {
            setLoading(true);
            const response = await apiRequest({
                method: "POST",
                url: requests.adminLogs,
                data: dateRange
            });
            if (response.success) {
                const logs = mapLogsData(response.data);
                setData(logs);
                notify(`${logs.length} entries found for the selected date range`, "success");
            } else {
                console.log("Failed to fetch logs. Response:", response);
                notify("Failed to fetch logs");
            }
            setLoading(false);
        } 
    }

    const mapLogsData = (data) => {
        const logs = data.filter(log => !!log.adminId && !!log.userId).map(log => {
            return {
                dateAndTime: log.createAt, 
                adminEmail: log.adminId.email, 
                memberId: log.userId.memberId, 
                logEntry: log.logs
            }
        });
        return logs;
    }
    
    return (
        <AdminMainLayout>
            <Page>
                <div className='d-flex align-items-center justify-content-end'>
                    <div class="form-floating mb-3">
                        <input 
                            type="date" 
                            class="form-control" 
                            id="startDate"
                            value={dateRange.startDate}
                            onChange={handleChange}
                        />
                        <label for="startDate">Start date</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input 
                            type="date" 
                            class="form-control" 
                            id="endDate"
                            value={dateRange.endDate}
                            onChange={handleChange}
                        />
                        <label for="endDate">End date</label>
                    </div>
                    <button 
                        className='btn btn-primary'
                        type='submit'
                        onClick={handleSubmit}
                    >
                        Show Admin Logs
                    </button>
                </div>
                <small className='text-danger'>{error}</small>
                <MaterialTable
                    title={`Admin Logs`}
                    icons={tableIcons} data={data} columns={columns} isLoading={loading}
                />
            </Page>
        </AdminMainLayout>
    );
}

export default AdminLogs;