import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import RegisterAddress from '../Forms/RegisterAddress';
import { fetchUser } from '../../utils/Helpers';
import { setUser } from '../../store/userSlice';

const ChangeAddress = (props) => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user.info);

    const onAddressUpdate = async () => {
        const userData = await fetchUser();
        dispatch(setUser(userData));
        props.handleClose();
    }

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header>
                Update Address
            </Modal.Header>
            <Modal.Body>
                <RegisterAddress onComplete={onAddressUpdate} user={user} />
            </Modal.Body>
        </Modal>
    );
}

export default ChangeAddress;