import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from "@material-ui/core/Button";
import {
    SUBMIT, CHOOSEIDTYPE, IDENTIFICATIONNO, UPLOADIDPROOF, MAXIMUMFILESIZE,
    KYCRULE1, KYCRULE2, KYCRULE3, SKIP
} from "../../Constants";
import { convertBase64 } from '../../services';
import requests from '../../utils/requests';
import 'react-phone-number-input/style.css';
import { notify } from '../../utils/handler';

const IDProofUploader = (props) => {

    const [data, setData] = useState({
        email: '',
        idType: '',
        idNumber: '',
    });
    const [file, setFile] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (props.user) {
            setData(prevData => ({
                ...prevData,
                email: props.user.email
            }));
        }
    }, [props.user]);

    const onChangeFile = async (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        const base64 = await convertBase64(e.target.files[0]);
        setFile(base64);
        props.setPreviewFile(base64);
    };

    const onUpload = async (event) => {
        event.preventDefault();
        if (!data.idType || !data.idNumber) {
            notify("Please enter all required fields");
            return;
        }
        const url = props.adminUpload ? requests.kycAdminIdUpload : requests.kycIdUpload;
        const payload = {...data, pic: file};
        console.log(payload);
        props.handleUpload(url, payload);
    }

    return (
        <div className="KYCVerification2">
            <section className="formsection">
                <div className="formcontainer d-flex flex-column">
                    {props.isSignupFlow && !props.adminUpload &&  <button 
                        className="btn btn-link align-self-end mb-4"
                        onClick={props.handleAbortKYC}
                    >
                        Do this later?
                    </button>}
                    <form>
                        <h5 className='kycheading'>{UPLOADIDPROOF}</h5><br />
                        <ol>
                            <li>{KYCRULE1}</li>
                            <li>{KYCRULE2}</li>
                            <li>{KYCRULE3}</li>
                        </ol>
                        <div className="row">
                            <div className="col-25">
                                <label className="label" htmlFor="text">{CHOOSEIDTYPE}</label>
                            </div>
                            <div className="col-75">
                                <select id="idtype" name="idtype" className="register1_formfield1" onChange={(e)=>{
                                    setData({
                                        ...data,
                                        idType: e.target.value
                                    })
                                }}>
                                    <option value="Choose ID Type">Choose ID Type</option>
                                    <option value="Passport">Passport</option>
                                    <option value="National ID">National ID</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label className="label" htmlFor="idNumber">{IDENTIFICATIONNO}</label>
                            </div>
                            <div className="col-75">
                                <input className="register1_formfield1" onChange={(e)=>{
                                    setData({
                                        ...data,
                                        idNumber: e.target.value
                                    })
                                }} type="text" id="idNumber" name="idNumber" placeholder="NRIC or Passport number" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            </div>
                            <div className="col-75">
                                <input className="register1_formfield1" type="file" id="myFile" name="filename" onChange={onChangeFile} /><br /><br />
                                <p>{MAXIMUMFILESIZE}</p>
                            </div>
                        </div>
                        <br />

                        <div className="buttonrow">
                        <span><Button className='submit_button' onClick={onUpload} type="submit" value="Submit" >{SUBMIT}</Button></span>
                        <span><Button className='preview_button' onClick={props.handlePreview} type="submit" value="Preview" >Preview</Button></span>

                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}
export default IDProofUploader;