import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';

const UpdateKYC = props => {
  
  const user = useSelector(state => state.user.info);
  const [otpInput, setOtpInput] = useState('');

  if (!user) {
    return null;
  }

  const kycUploadDone = user.idPath && user.addressPath && user.selfiePath
                          && !user.idProofReject 
                          && !user.addressProofReject 
                          && !user.selfieProofReject;
  const isKycApproved = user.isAddressProofApproved 
                          && user.isIdProofApproved
                          && user.isSelfieProofApproved;

  const docs = {
      id: {title: "ID Proof", path: user.idPath},
      address: {title: "Address Proof", path: user.addressPath},
      selfie: {title: "Selfie Proof", path: user.selfiePath}
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const request = {
      method: 'POST',
      url: requests.verifyotp,
      data: {
        email: user.email,
        phoneNumber: user.phoneNumber,
        otp: otpInput,
      },
    };
    const response = await apiRequest(request);
    if (response.success) {
      notify('OTP verified successfully', 'success');
      props.otpSuccessCallback(response);
    } else {
      notify(response.data.message);
    }
  };

  const handleChange = e => {
    setOtpInput(e.target.value);
  };
  return (
    <Modal show={props.show} onHide={props.handleClose} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>KYC Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!kycUploadDone 
            ?
            <div>
              <p>You have not submitted one or more of your KYC documents.</p>
              <p>Missing Documents:</p>
              <ul>
                  {
                      Object.keys(docs).map(key => {
                          console.log(key + "ProofReject", user[key + "ProofReject"])
                          if (!docs[key].path || user[key + "ProofReject"]) {
                              return <li key={docs[key].title}>{docs[key].title}</li>
                          }
                      })
                  }
              </ul>
              <p>Would you like to submit the missing documents now?</p>
              <div className="d-flex justify-content-end">
                  <Link className="btn btn-primary mx-3" to="/submit-kyc">Submit Now</Link>
                  <button className="btn btn-secondary" onClick={props.handleClose}>Later</button>
              </div>
            </div>
            :
            <div className="d-flex flex-column">
              {isKycApproved
                ?
                <p>Congratulations! Your KYC has been approved. You're all set.</p>
                :
                <p>
                  Your KYC is pending with the admin. If you would like to
                  follow up on the status, please contact the support team.
                </p>}
              <button className="btn btn-primary align-self-end" onClick={props.handleClose}>OK</button>
            </div>
            
            
        }
      </Modal.Body>
    </Modal>
  );
};

export default UpdateKYC;
