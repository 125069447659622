import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';

const ClientDetail = (props) => {

  const [formData, setFormData] = useState(props.userInfo);

  useEffect(() => {
    // The names are in full caps in the DB. Capitalize them properly.
    console.log(formData);
    if (formData) {
      const kycInfo = !!formData.kycInfo && !!formData.kycInfo[0] ? formData.kycInfo[0] : formData;
      const isKycSubmitted = kycInfo.idPath
                              && kycInfo.addressPath
                              && kycInfo.selfiePath;
      const isKycApproved = kycInfo.idProofAccept
                              && kycInfo.addressProof
                              && kycInfo.selfieProofAccept;
      setFormData(prevFormData => ({
        ...prevFormData,
        firstName: prevFormData.firstName && prevFormData.firstName.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),
        lastName: prevFormData.lastName && prevFormData.lastName.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),
        userId: prevFormData._id || prevFormData.id,
        registeredDate: moment(prevFormData.createAt).format('MM-DD-YYYY'),
        kycStatus: isKycSubmitted ? (isKycApproved ? 'Approved' : 'Pending') : 'Incomplete'
      }));
    }
  }, []);

  if (!props.userInfo) {
    return null;
  }
  
  const updateUser = async () => {
    console.log(formData);
    delete formData.createAt;
    delete formData.registeredDate;
    const response = await apiRequest({
      method: 'POST',
      url: requests.updateUser,
      data: formData
    });
    if (response.data && !response.data.error) {
      notify(response.data.message, 'success');
      props.refreshTable(true);
    }
    else {
      notify(response.data.message);
    }
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser();
  }

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Client Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="container" onSubmit={handleSubmit}>
          <div className="form-group d-flex">
            <label htmlFor="clientFirstName" className="text-nowrap w-50">First Name:</label>
            <input 
              type="text" 
              id="clientFirstName"
              name="firstName"
              onChange={handleChange}
              value={formData.firstName || ''}
              readOnly={props.readOnly}
            />
          </div>
          <div className="form-group d-flex">
            <label htmlFor="clientLastName" className="text-nowrap w-50">Last Name:</label>
            <input 
              type="text" 
              id="clientLastName"
              name="lastName"
              onChange={handleChange}
              value={formData.lastName || ''}
              readOnly={props.readOnly}
            />
          </div>
          <div className="form-group d-flex">
            <label htmlFor="memberId" className="text-nowrap w-50">Client ID:</label>
            <input 
              type="text" 
              id="memberId"
              name="memberId"
              onChange={handleChange}
              value={formData.memberId}
              readOnly={props.readOnly}
            />
          </div>
          <div className="form-group d-flex">
            <label htmlFor="clientEmail" className="text-nowrap w-50">Email:</label>
            <input 
              type="text" 
              id="clientEmail"
              name="email"
              onChange={handleChange}
              value={formData.email || ''}
              readOnly={props.readOnly}
            />
          </div>
          <div className="form-group d-flex">
            <label htmlFor="clientPhone" className="text-nowrap w-50">Phone:</label>
            <input 
              type="text" 
              id="clientPhone"
              name="phoneNumber"
              onChange={handleChange}
              value={formData.phoneNumber || ''}
              readOnly={props.readOnly}
            />
          </div>
          <div className="form-group d-flex">
            <label htmlFor="registeredDate" className="text-nowrap w-50">Registered Date:</label>
            <input
              type="text" 
              id="registeredDate"
              name="registeredDate"
              value={formData.registeredDate}
              readOnly={true}
            />
          </div>
          <div className="form-group d-flex">
            <label htmlFor="clientKYCStatus" className="text-nowrap w-50">KYC Status:</label>
            <input 
              type="text" 
              id="clientKYCStatus"
              name="kycStatus"
              value={formData.kycStatus}
              readOnly={true}
            />
          </div>
          <div className="form-group d-flex">
            <label htmlFor="totalTokens" className="text-nowrap w-50">Reserve Balance:</label>
            <input 
              type="number" 
              id="totalTokens" 
              name="totalTokens"
              value={formData.totalTokens}
              onChange={handleChange}
              readOnly={props.readOnly}
            />
          </div>
          <div className="form-group d-flex">
            <label htmlFor="withdrawTokens" className="text-nowrap w-50">Withdrawable Balance:</label>
            <input 
              type="number" 
              id="withdrawTokens" 
              name="withdrawTokens"
              value={formData.withdrawTokens}
              onChange={handleChange}
              readOnly={props.readOnly}
            />
          </div>
          <div className="form-group d-flex justify-content-end mt-3">
            {props.readOnly ? 
              (null) 
              :
              (<Button variant="primary" type="submit">
                Submit
              </Button>)
            }
          </div>
        </form>
        <div className="d-flex justify-content-end">
          {props.readOnly ?
            (<Button variant="secondary" onClick={props.handleClose}>
              Close
            </Button>)
            :
            (null)
          }
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ClientDetail;