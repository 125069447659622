import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
import './Login.css'
import Page from '../../components/Page';
import VerifyOTP from '../../components/Forms/VerifyOTP';
import LOGO from '../../assets/img/logo/logo_200.png';
import { REGISTEREDMAIL, REGISTEREDPWD, FORGOTPWD, SIGNIN } from "../../Constants"
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import { triggerOTP, fetchUser } from '../../utils/Helpers';
import { setUser, clearUser } from '../../store/userSlice';
import { Modal } from 'react-bootstrap';

let loginFlag = false;

const Login = (props) => {

  const cookies = new Cookies();
  const sessionId = cookies.get('SID');
  const userType = cookies.get('userType');
  
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.info);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [data, setData] = useState({
    email: '',
    password: '',
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight
  });
  
  useEffect(() => {window.scrollTo(0, 0)}, []);
  
  useEffect(() => {
    if (sessionId && userType === 'client' && !user) {
      updateUser();
    }
    if (sessionId && user && userType === 'client' && !loginFlag) {
      props.history.push('/dashboard');
    }
  }, [sessionId, userType, user]);

  const handleClose = () => setOtpModalOpen(false);

  const onLogin = async (event) => {
    event.preventDefault();
    // Set a flag to prevent redirecting to dashboard in the next mount after cookies are set.
    // Login should stay mounted to fetch full user data, dispatch it to state, and check for 2FA.
    loginFlag = true;
    if (!data.email && !data.password) {
      return;
    }
    const response = await apiRequest({
      method: 'POST',
      url: requests.login,
      data
    });
    if (!response.success) {
      notify(response.data.message || "Login failed.");
      return;
    }
    if (response.success && response.data.is2FactorAuthEnabled) {
      setOtpModalOpen(true);
    }
    else if (response.success && response.data.token) {
      grantAccess(response.data);
    }
  }

  const updateUser = async () => {
    const userData = await fetchUser();
    if (userData) {
      dispatch(setUser(userData));
    }
  }

  const grantAccess = async (data = null) => {
    let userData;
    if (data) {
      const date = new Date();
      date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
      cookies.set('SID', data.token, {expires: date});
      cookies.set('userType', 'client', {expires: date});
      userData = await fetchUser();
      console.log(userData);
      dispatch(setUser(userData));
    }
    else {
      userData = user;
    }
    const welcomeMsg = `Welcome, ${userData.firstName}! You have successfully logged in`;
    notify(welcomeMsg, 'success');
    props.history.push('/dashboard');
  }

  const flushSession = () => {
    cookies.remove('SID');
    cookies.remove('userType');
    dispatch(clearUser());
  }

  const inputChangeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  }

  return (
    <Page className="LoginPage">
      <div className="logincontainer">
        <div className="loginform">
          <form onSubmit={onLogin} action="/" method="post" className="container">
            <div className="loginimg">
              <img src={LOGO} alt="logo" />
            </div>
            <br />
            <div>
              <label className='labels' htmlFor="username">{REGISTEREDMAIL}</label>
              <input 
                type="text" 
                placeholder="Please enter your registered email id" 
                name="email" 
                required
                onChange={inputChangeHandler} 
              />
              <label className='labels' htmlFor="password">{REGISTEREDPWD}</label>
              <input 
                type="password" 
                placeholder="Please enter your password" 
                name="password" 
                required 
                onChange={inputChangeHandler} 
              />
              <br />
              <div >
                <span className="password"><Link className='forgotpwd' to="/forgotpassword?utype=client">{FORGOTPWD}</Link></span>
              </div>
              <br /> <br />
              <button type="submit">{SIGNIN}</button>
            </div>
          </form>
        </div>
      </div>
      <Modal
        show={otpModalOpen}
        onHide={handleClose}
        keyboard={false}
      >
        <Modal.Body>
          <VerifyOTP data={data} onComplete={grantAccess} onFailure={flushSession} />
        </Modal.Body>
      </Modal>
    </Page>
  )
}

export default Login;