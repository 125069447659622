import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

const AdminPrivateRoute = ({component: Component, ...rest}) => {
    const cookies = new Cookies();
    const token = cookies.get('SID');
    const userType = cookies.get('userType');
    const isAuthorized = !!token && userType === 'admin';
    return <Route {...rest} render={(props) => (
        isAuthorized
        ? <Component {...props} />
        : <Redirect to="/" />
    )} />
}

const ClientPrivateRoute = ({component: Component, ...rest}) => {
    const cookies = new Cookies();
    const token = cookies.get('SID');
    const userType = cookies.get('userType');
    const isAuthorized = !!token && userType === 'client';
    return <Route {...rest} render={(props) => (
        isAuthorized
        ? <Component {...props} />
        : <Redirect to="/client" />
    )} />
}

export { AdminPrivateRoute, ClientPrivateRoute };