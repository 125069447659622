import Page from '../../components/Page';
import React from 'react';
import './Withdrawal.css'
import { HOME, WITHDRAWL, WITHDRAWLADDRESS, TOKENSWITHDRAW, SUBMIT, MAXITOKENS, MYWALLET } from "../../Constants"
import { MainLayout } from '../../components/Layout';
import ConfirmClientWithdrawal from '../../components/Modals/ConfirmClientWithdrawal';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { useState,useEffect } from 'react';
import { notify } from '../../utils/handler';



const Withdrawal = () => {
    const [withdrawToken, setWithdrawToken] = useState(0)
    const [amount,setamount]  = useState('')
    const [transactionAddress,settransactionAddress]=useState('')
    const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);

    const getuserInfo=async()=>{
      const response = await apiRequest({
        method: 'GET',
        url: requests.user,
      });
      if (response.success && response.data) {
        setWithdrawToken(response.data.withdrawTokens)

      }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getuserInfo()
    
      }, [])
      
      const submitData = async(e)=> {
        e.preventDefault();
        if(transactionAddress!==(null||undefined) && amount!==(null||undefined)){
          if(withdrawToken >= amount){
            setWithdrawModalOpen(true);
          }else{
            notify("You dont have sufficient balance",'error')
          }
        }else{
          notify("Please Fill all the Fields",'warning')
        }
      }

    return (
        <MainLayout>
            <Page className="withdrawal_page" >
                <h6 className="pageheader"> <span><i className="fa fa-home pageheader"></i><a href="/#/dashboard" >{HOME} </a>{'>'}{ MAXITOKENS }{'>'} { MYWALLET }{'>'} {WITHDRAWL}</span></h6>
                <div className="withdrawl_change">
                    <div className="withdrawlheader">
                        <h5 className="withdrawl_header">Withdrawal</h5>
                        <form onSubmit={submitData}>
                            <div className="withdrawl_body">
                                <p>{WITHDRAWLADDRESS}</p>
                                <input type="text" placeholder="Please enter Address" value={transactionAddress} onChange={(e) => settransactionAddress(e.target.value)} required /><br />
                                <p>{TOKENSWITHDRAW}</p>
                                <input type="number" placeholder="Please enter No. of tokens to withdraw"  value={amount} onChange={(e) => setamount(e.target.value)} required /><br />
                                <button type="submit" className="submit_button">{SUBMIT}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Page>
            <ConfirmClientWithdrawal 
              show={withdrawModalOpen} 
              handleClose={() => {setWithdrawModalOpen(false)}}
              amount={amount}
              address={transactionAddress}
            />
        </MainLayout>
    )
}

export default Withdrawal
