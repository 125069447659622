//ACCINFO PAGE
export const HOME = ' Home '
export const PROFILE = ' Profile '
export const ACCOUNTINFO = ' Account Information '
export const REGISTRATION = ' Registration Details '
export const KYCDOCUMENTS = 'KYC Document Status'
export const EMAILID = 'Email ID'
export const EMAILVERIFICATION = 'Email Verification'
export const POIPOR = 'POI/POR Documents Upload Status'
export const DATE = '23-11-2021 '
export const TIME = '11:09:50'
export const ID = 'ID Proof:'
export const SELF = 'Selfie Proof:'
export const ADDRESS = 'Address Proof:'
export const REJECTED = 'Rejected'
export const USERMAIL = 'nivetha@tradala.tech'
export const NOTYET = 'Not Yet Verified'
export const CLICKHERE = 'Click here '
export const PENDING = 'Pending'
export const EMAILVERIFIED = ' to verify your email id.'
//DASHBOARD PAGE
export const HIUSERS = 'Hi Users, Welcome back!'
export const LASTLOGIN = 'Last login time: '
export const LASTLOGINTIME = ' Friday, November 26th 2021, 4:55:24 am'
export const OVERVIEW = 'Overview'
export const DEPOSITADD = 'Deposit Address'
export const TOKENSALE = 'Token Sale'
export const BALANCE = ' Balance'
export const TOKENSYOUOWN = ' Tokens you own'
export const CREATEWALLET = ' Create wallet'
export const RECEIVETOKENS = ' Withdrawn Tokens'
export const BUYMAXITOKENS = ' Buy Maxi tokens'
export const ETHEREUMADDRESS = 'Ethereum address to deposit'
export const DEPOSITADDRESS = 'Deposit Address: '
export const CLIPBOARDADD = '0x4Bccfad31B437fA427Ae4e9aB d76BC7C1d02B853'
export const COPIEDMSG = 'Deposit address copied.'
export const RECENTNEWSFEED = 'Recent News Feed'
export const NEWS1 = 'Crypto Firm FINMA Unlawfully Received Money from 38,000 Investors'
export const NEWS2 = 'An Indian Police Academy to Host Course on Cryptocurrencies'
export const NEWS3 = 'Binance Conducts Fourth India Meetup in Bangalore'
export const NEWS4 = 'A Guide to Crypto Hacking: How to stay safe'
export const NEWS5 = 'Zimbabwe’s Economy Thriving On Bitcoin Despite the Ban'
export const NEWS6 = 'India’s Native Blockchain Protocol Launches Testnet'
export const NEWS7 = 'Telegram’s Blockchain Is Live in Private Beta Mode'
export const NEWS8 = 'EOS can be Manipulated Anytime According to Statistics'
export const NEWS9 = 'FCA Becomes Target of Bitcoin Email Fraud'
export const NEWS10 = 'Bitcoin’s 2021 Outlook Clouded by Several Risk Factors'
export const NEWSTIME1 = '29-08-2021 14: 50: 56'
export const NEWSTIME2 = '27-08-2021 14: 17: 12'
export const NEWSTIME3 = '26-08-2021 14: 17: 51'
export const NEWSTIME4 = '25-08-2021 15: 11: 20'
export const NEWSTIME5 = '21-08-2021 14: 24: 27'
export const NEWSTIME6 = '20-08-2021 14: 27: 24'
export const NEWSTIME7 = '19-08-2021 14: 18: 57'
export const NEWSTIME8 = '18-08-2021 14: 23: 41'
export const NEWSTIME9 = '09-09-2021 14: 26: 47'
export const NEWSTIME10 = '04-09-2021 19: 21: 23'
export const MAXITOKEN = 'Maxi Tokens'
export const TOKENSBOUGHT = 'No. Of Tokens You Own - '
export const BOUGHT = '18482'
export const TOKENSRECEIVED = 'No. Of Tokens Received - '
export const RECEIVED = '1200'
export const PERCENTAGE = '65%'
export const BUY = 'BUY'
export const BUYVALUE = '6,095'
export const RECEIVING = 'RECEIVING'
export const RECEIVINGVALUE = '3,905'
export const HEREYOU = 'Here you can find the token sale details.'
export const TOKENSOLD = ' 78000000120'
export const SOLDPERCENTAGE = '(66%)'
export const NOOFTOKENSOLD = ' No. of Tokens sold'
export const CURRENTRATETOKEN = ' 1000'
export const CURRENTTOKEN = ' Current rate of the Token'
export const AVAILABLETOKENSVALUE = ' 40700000012'
export const AVAILABLEPERCENTAGE = '(34%)'
export const AVAILABLETOKENS = ' Available Tokens'
export const NEXTTOKENDATE = ' 30th April 2019 '
export const NEXTTOKEN = ' Next Token sale details'
export const YOURWALLET = 'YOUR WALLET'
export const CLICKTOKENS = 'Click on the respective buttons to transfer or receive tokens.'
export const BTCWALLET = 'ERC-20 Wallet '
export const TRANSFER = 'Transfer'
export const RECEIVE = 'Withdraw'
export const BTCVALUE = 'BTC = $4865.23'
export const RECENTTRANSACTION = ' RECENT TRANSACTIONS'
export const TRANSACTIONDATE1 = '  Nov 11, 2018'
export const TRANSACTIONVALUE1 = 'b981976c4e8f8'
export const TRANSACTIONRATE1 = '+0.0013'
export const TRANSACTIONRATE2 = '+0.0153'
export const TRANSACTIONRATE3 = '+1.0000'
//DEPOSIT PAGE
export const DEPOSIT = ' Deposit '
//PASSWORD PAGE
export const CHANGEPASSWORD = ' Change Password '
export const CHANGEYOURPWD = ' Change your Password'
export const CURRENTPWD = 'Enter your current password'
export const NEWPWD = 'Enter your new password'
export const DESCRIPTION = '(A minimum of eight characters which includes atleast one uppercase, lowercase and one number)'
//PHONENUMBER PAGE
export const CHANGEPHONE = ' Change Phone Number  '
export const CHANGEYOURPHONE = 'Change Your Phone Number'
export const CURRENTPHONE = 'Your Current Phone Number'
export const CURRENTPHONENO = '+919865045038'
export const NEWNO = 'Enter your new phone number'
//TRANSACTION PAGE
export const TRANSACTIONS = ' Transactions '
export const TIMEDATE = 'Time And Date'
export const MEMBERID = 'Client Id'
export const TRANSADDRESS = 'Address'
export const TRANSACTIONSTATUS = 'Transaction Status'
export const TIMEDATE1 = '20-01-2019 13:45:25'
export const MEMBERID1 = '201990'
export const ADDRESS1 = 'ETH564654'
export const STATUS1 = 'Complete'
//WALLET PAGE
export const MAXITOKENS = ' Maxi Token'
export const MYWALLET = ' My Wallet'
export const TOKENSOWNED = 'Number of tokens owned'
export const HUNDRED = '100'
export const TOKENSAVAILABLE = ' Number of Tokens available to withdraw'
export const WITHDRAW = 'WITHDRAW'
//PROFILE PAGE
export const PROFILEDETAILS = " Profile Details "
export const YOURPROFILEDETAILS = "Your Profile Details"
export const YOURPROFILEPICTURE = "Your Profile Picture"
export const TWOFACTORAUTH = "2-Factor Authentication"
export const UPDATEKYCDETAILS = "Update your KYC Details"
export const UPDATEPROFILE = "Update Profile"
export const PHONENUMBER = "Phone Number"
export const DOB = "DOB"
export const DOBVALUE = "30-12-1994"
export const NATIONALITY = "Nationality"
export const NATIONALITYVALUE = "Singaporean"
export const CITY = "City"
export const CITYVALUE = "Test"
export const USERADDRESS = "Address"
export const USERADDRESSVALUE = "Test"
export const COUNTRYOFBIRTH = "Country of Birth"
export const BIRTHCOUNTRY = "Singapore"
export const ZIPCODE = "Zip Code"
export const ZIPCODEVALUE = "6000018"
export const AUTHENTICATION = "2-Factor Authentication Enabled"
export const YESORNO = "NO"
export const PROFILEPICTURE = "Profile Picture"
export const YOURIDPICTURE = "Your ID Picture "
export const YOURIDTYPE = "Your Id Type "
export const YOURIDNUMBER = "Your Id Number "
export const YOURSELFIEPIC = "Your selfie picture"
export const YOURADDRESSPROOF = "Your address proof"
export const NULL = "Null"
//LOGIN PAGE
export const REGISTEREDMAIL = "Enter your registered email id"
export const REGISTEREDPWD = "Enter your password"
export const FORGOTPWD = "Forgot password?"
export const SIGNIN = "Sign In"
export const NEWUSER = "New Registration"
//REGISTER PAGE
export const SIGNUPFORM = "Signup Form"
export const USERDETAILS = " User Details"
export const EMAILDETAILS = " Email Details"
export const PERSONALINFO = " Personal Info"
export const KYCVERIFICATION = " KYC Verification"
export const EMAILIDS = "Email ID *"
export const CONFIRMMAILID = "Confirm Email ID *"
export const PASSWORD = "Password *"
export const PASSWORDDESCRIPTION = "(A minimum of eight characters which includes atleast one uppercase, lowercase and one number)"
export const SUBMIT = "Submit"
//REGISTER1 PAGE
export const FIRSTNAME = "First Name *"
export const LASTNAME = "Last Name"
export const PHONENUMBERS = "Phone Number *"
export const GETOTP = "GET OTP"
//REGISTER2 PAGE
export const OTPDESCRIPTION = "You've been sent a 6 digit verification code to your phone number. Please input the number into this text box below."
export const OTP = "Enter the OTP *"
export const DIFFERENTNUMBER = "Specify a Different Number"
//REGISTER3 PAGE
export const COUNTRYOFBIRTHS = "County Of Birth *"
export const DATEOFBIRTHS = "Date of Birth *"
export const NATIONALITYS = "Nationality *"
export const ADDRESSS = "Address *"
export const CITYS = "City *"
export const ZIPCODES = "Zip/Postal Code *"
//KYC VERIFICATION PAGE
export const UPLOADIDPROOF = "Upload your ID Proof"
export const KYCRULE1 = " Please upload a full-scanned image of your identity proof. Passports are preferred and        will ensure your account gets approved quickly."
export const KYCRULE2 = " Your full name, full view of your face and the passport/id number must be clearly visible."
export const KYCRULE3 = " Kindly upload jpeg, png or jpg files only and should be in high resolution."
export const CHOOSEIDTYPE = "Choose your ID type"
export const IDENTIFICATIONNO = "Identification number"
export const MAXIMUMFILESIZE = "Maximum file size which can be uploaded is 5MB"
export const SKIP = "SKIP"
//ADMIN TRANSACTION PAGE
export const RATE = "Rate"
export const STATUS2 = "Pending"
export const RATEVALUE = "100"
//ADMIN DASHBOARD PAGE
export const HIADMINS = "Hi Tradala Trade solutions, Welcome back!"
export const RECENTDEPOSIT = "Recent Deposit"
export const VALUE1 = " 5669"
export const VALUE2 = " 5306"
export const VALUE3 = " 320"
export const VALUE4 = " 325"
export const VALUE5 = " 119"
export const VALUE6 = " 5455"
export const VALUE7 = " 5441"
export const VALUE8 = " 5583"
export const MEMBERSSIGNEDUP = " Clients signed up"
export const MEMBERSAPPROVED = " Clients approved"
export const IDPROOFREJECTED = " ID proof rejected"
export const SELFIEPROOFREJECTED = " Selfie proof rejected"
export const ADDRESSPROOFREJECTED = " Address Proof rejected"
export const IDPROOFUPLOADED = " ID proof uploaded"
export const SELFIEPROOFUPLOADED = " Selfie proof uploaded"
export const ADDRESSPROOFUPLOADED = " Address Proof uploaded"
export const CLIENTLIST = " Client list"
export const REGISTEREDCLIENTS = " Please find the list of registered clients."
export const LISTOFRECENTDEPOSIT = "Here is a list of recent deposits."
export const DEPOSITMODE = "Deposit Mode"
export const DEPOSITDATETIME = "Deposit Date & Time"
export const RECENTDEPOSTI = "Recent Deposit"
export const CLIENTNAME = "Client Name"
export const EMAIL = "Email"
export const PHONE = "Phone"
export const REGISTEREDDATE = "Registered Date"
export const KYCSTATUS = "KYC Status"
export const ACCOUNTBALANCE = "Account Balance"
export const TOKENSOWNEDHEADER = "Tokens Owned"
//KYC1 VERIFICATION PAGE
export const UPLOADSELFIEPROOF = "Upload your Selfie picture"
export const KYC1RULE1 = " Upload a selfie holding a piece of paper where your full name and the current date is clearly shown."
export const KYC1RULE2 = " Please ensure your entire face is visible in the shot and the piece of paper doesn't block your facial features."
export const KYC1RULE3 = " Kindly upload jpeg, png or jpg files only and should be in high resolution."
////KYC2 VERIFICATION PAGE
export const UPLOADADDRESSPROOF = "Upload your Address Proof"
export const KYC2RULE1 = " Please upload a full scanned image of your address proof. This can be either your"
export const KYC2RULE2 = " This document must be less than 6 months old and it must show your name and address exactly as per the details you had mentioned in the signup form earlier."
export const KYC2RULE3 = " No screenshots and please upload PDF, jpeg, png or jpg files only."
export const KYCSUBRULE1 = " Bank statement,"
export const KYCSUBRULE2 = " Utility bill,"
export const KYCSUBRULE3 = " Tax return statement or"
export const KYCSUBRULE4 = " A Government issued letter"
//THANKYOU
export const THANKYOU = "Thank you for signing up with Maxi Token."
export const THANKYOUPARA1 = " We need all your KYC documents for your account to be approved. If your documents haven't yet been submitted, please email them to us at  "
export const THANKYOUPARA2 = " If all documents have been submitted, our support team will be reviewing your account signup and documents provided and get back to you within 5-7 business days."
export const MAIL = " contact@maxi.exchange"
export const THANKYOUPARA11 = " or login to your client area to submit."
//ADMIN WALLET PAGE
export const WALLETS = ' Wallets'
export const WALLET = 'Wallet'
export const AMOUNTRAISED = 'Amount Raised For Withdrawal'
export const ACTION = '  Action '
export const TOKENOWNED = ' Tokens Owned '
//CLIENT REPORT PAGE
export const CLIENTREPORT = 'Client Report'
export const CLIENTREPORTDESCRIPTION = 'Please find the list of registered clients.'
//CLIENT LIST PAGE
export const CLIENTLISTDESC = 'Please find the list of registered clients.'
//WITHDRAWL PAGE
export const WITHDRAWL = 'Withdrawal '
export const WITHDRAWLADDRESS = 'Address '
export const TOKENSWITHDRAW = 'No.of tokens to withdraw '
//ADMIN CLIENT EDIT
export const UPLOADPASSPORTPROOF = 'Upload Passport proof'
export const IDPROOFSTATUS = "ID Proof Status"
export const APPROVENOW = ' Approve Now '
export const SENDMAIL = " Send Mail "
export const KYCAPPROVENOW = ' KYC Approve Now '
export const BACK = 'Back'
export const CLIENTS = " Clients "
export const UPDATECLIENTINFORMATION = ' Update Client Information'
export const UPDATECLIENTPROFILEDETAILS = 'Update Client Profile Details'
export const EDITADDRESS = 'Address'
export const FIRSTNAMES = 'First Name'
export const IDTYPE = 'ID Type'
export const IDNUMBER = 'ID Number'
//ADMIN CLIENT VIEW
export const CLIENTINFORMATION = ' Client Information'
export const CLIENTPROFILEDETAILS = ' Client Profile Details '
export const EDITCLIENTPROFILE = 'Edit Client Profile'
export const ACCOUNTSTATUS = 'Account Status'
export const ACTIVE = 'Active'
export const NO = 'No'
export const ACTIVATIONCODE = "Activation Code"
export const OTPVERIFIEDSTATUS = "OTP Verified Status"
export const TWOFACTORAUTHENABLE = "2-Factor Authentication Enabled"
export const SELFIEPROOFSTATUS = "Selfie Proof Status"
export const ADDRESSPROOFSTATUS = 'Address Proof Status'
export const ADDRESSPIC = "Your Address Picture"
export const SELFIEPIC = "Your Selfie Picture"
export const PASSPORTPIC = "Your Passport Picture"
//FORGOT PASSWORD
export const FORGOTPASSWORD = "Forgot Password"
export const SIGNINGIN = "Sign In ?"