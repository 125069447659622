import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { setUser } from '../../store/userSlice';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { fetchUser } from '../../utils/Helpers';
import { notify } from '../../utils/handler';

const ChangePersonalInfo = (props) => {

    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        dobStr: '',
        emailNotifications: true
    });
    const user = useSelector(state => state.user.info);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            setData(user);
        }
    }, [user]);

    const handleChange = (e) => {
        const target = e.target;
        setData(prevData => ({
            ...prevData,
            [target.id]: target.value
        }));
    }

    const handleNotificationToggle = (e) => {
        const target = e.target;
        setData(prevData => ({
            ...prevData,
            [target.id]: target.checked
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(data);
        const response = await apiRequest({
            method: 'POST',
            url: requests.updateuserinfo,
            data: data
        });
        if (response.success) {
            notify(response.data.message, "success");
            const userData = await fetchUser();
            dispatch(setUser(userData));
            props.handleClose();
        }
        else {
            notify("Failed to store user details.");
        }
    }

    return (
        <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>Change Personal Details</Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit}>
                <div className="d-flex">
                    <input 
                        className="mx-2"
                        type="text"
                        id="firstName"
                        placeholder="First Name"
                        value={data.firstName}
                        onChange={handleChange}
                    />
                    <input 
                        type="text"
                        id="lastName"
                        placeholder="Last Name"
                        value={data.lastName}
                        onChange={handleChange}
                    />
                </div>
                <div className="d-flex">
                    <input 
                        className="mx-2"
                        type="text"
                        id="email"
                        placeholder="Email"
                        value={data.email}
                        onChange={handleChange}
                    />
                </div>
                <div className="d-flex justify-content-between align-items-center my-3">
                    <label className="d-block mx-2 text-nowrap" htmlFor="emailNotifications">Subscribe to Email Notifications</label>
                    <input 
                        type="checkbox"
                        id="emailNotifications"
                        checked={data.emailNotifications}
                        onChange={handleNotificationToggle}
                    />
                </div>
                <div>
                    <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={handleSubmit}
                    >
                        Update
                    </button>
                </div>
            </form>
        </Modal.Body>
        </Modal>
    )
}

export default ChangePersonalInfo;