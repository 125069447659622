import { toast } from 'react-toastify';

export const notify= (msg, type='error', theme='colored') =>{
    toast[type](msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme
      });
}