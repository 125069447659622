import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from "@material-ui/core/Button";
import apiRequest from "../../services";
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import { OTPDESCRIPTION } from "../../Constants";

const RegisterVerifyOTP = (props) => {

    const user = useSelector(state => state.user.info);
    const [otp, setOtp] = useState('');
    const [counter, setCounter] = useState(null);

    useEffect(() => {
        if (!counter) {
            setCounter(60);
        }
        return () => {
            setCounter(null);
        }
    }, []);

    useEffect(() => {
        if (counter) {
            counter > 0 && setTimeout(() => {
                setCounter(prevCounter => prevCounter - 1)
            }, 1000);
        }
    }, [counter]);

    if (!props.user) return null;

    const handleChange = (e) => {
        const target = e.target;
        setOtp(() => target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await apiRequest({
            method: 'POST',
            url: requests.verifyotp,
            data: {
                'phoneNumber': props.user.phoneNumber,
                "otp": otp,
                "email": props.user.email
            }
        });
        if (response.success) {
            props.onComplete();
        } else {
            notify(response.message || response.data.message || 'Registration failed!');
        }
    }

    const resendOTP = async (e) => {
        e.preventDefault();
        const response = await apiRequest({
            method: 'POST',
            url: requests.resendOtp,
            data: user
        });
        if (response.success) {
            notify("A fresh OTP has been sent to your registered mobile number", "success");
            setCounter(60);
        }
        else {
            notify(response.data.message);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <p className='otpdescription'>{OTPDESCRIPTION}</p>
            <div className="d-flex align-items-baseline">
                <label className="label w-25" htmlFor="number">Enter OTP:</label>
                <input 
                    className="register1_formfield" 
                    type="number" 
                    id="otp" 
                    name="otp"
                    value={otp} 
                    onChange={handleChange}
                    placeholder="Please enter the OTP" required />
            </div>
            <div className="d-flex flex-row-reverse justify-content-start align-items-baseline my-3">
                <Button 
                    className="submit_button" 
                    type="submit" 
                    value="Submit" 
                >
                    Submit
                </Button>
                {counter !== null && (counter > 0 ?
                    <p className="text-info mx-3">Resend OTP in {counter} seconds</p>
                    :
                    <button className="mx-3 btn btn-link" onClick={resendOTP}>Resend OTP</button>)
                }
            </div>
        </form>
    )
}

export default RegisterVerifyOTP;