import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AdminMainLayout } from '../../components/Layout';
import Page from '../../components/Page';
import ClientInfo from '../../components/Fragments/ClientInfo';
import AdminKYCAction from '../../components/Fragments/AdminKYCAction';
import AddressProofUploader from '../../components/KYC/AddressProofUploader';
import IDProofUploader from '../../components/KYC/IDProofUploader';
import SelfieProofUploader from '../../components/KYC/SelfieProofUploader';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { Link } from 'react-router-dom';
import './AdminKYCDetailView.css';
import { notify } from '../../utils/handler';

const AdminKYCDetailView = (props) => {
    
    const kyc = props.location.state.entry;
    console.log(kyc);
    const [docs, setDocs] = useState({
        idProof: '',
        addressPath: '',
        selfiePath: ''
    });
    const [showPreview, setShowPreview] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);
    const [showUploader, setShowUploader] = useState(false);
    const [uploader, setUploader] = useState(null);

    useEffect(() => {
        getKYCDocs();
    }, []);

    const upload = async (apiUrl, payload) => {
        if (!payload.email || !payload.pic) {
            console.log('fields missing');
            return false;
        }
        const response = await apiRequest({
            method: 'POST',
            url: apiUrl,
            data: payload
        });
        if (response.success) {
            notify("KYC Document uploaded successfully!", "success");
            getKYCDocs();
            setShowUploader(false);
        } else {
            console.log(response);
            notify(response.message || response.data.message || 'Document upload failed!');
        }
    }

    const handlePreview = (e) => {
        e.preventDefault();
        setShowPreview(true);
    }

    const handleUploadPrompt = (docType) => {
        setUploader(uploaders[docType]);
        setShowUploader(true);
    }

    const handleClosePreview = () => setShowPreview(false);
    const handleCloseUploader = () => setShowUploader(false);

    const uploaderProps = {
        handleUpload: upload,
        handlePreview: handlePreview,
        setPreviewFile: setPreviewFile,
        user: kyc.userId,
        isSignupFlow: false,
        adminUpload: true
    }

    const uploaders = {
        address: <AddressProofUploader {...uploaderProps} />,
        idproof: <IDProofUploader {...uploaderProps} />,
        selfie: <SelfieProofUploader {...uploaderProps} />
    }

    if (!props.location.state) {
        window.location = '/#/kyclist';
        return null;
    }

    const getKYCDocs = async () => {
        const response = await apiRequest({
            method: 'POST',
            url: requests.kycViewImg,
            data: {
                userId: kyc.userId._id
            }
        });
        if (response.data && response.success) {
            setDocs(response.data);
            console.log(response.data);
        }
    }

    const approveRejectDoc = async (docType, approvalFlag) => {
        const request = {
            method: 'POST',
            url: requests.kycApproveReject,
            data: {
                userId: kyc.userId._id,
                picType: docType,
                flag: approvalFlag
            }
        };
        const response = await apiRequest(request);
        console.log(response);
        notify(response.data.message, "success");
    }

    return (
        <AdminMainLayout {...props}>
            <Page className="KYCListPage">
                <h6 className="pageheader"> <span><i className="fa fa-home pageheader"></i><Link to="/admindashboard" >Home </Link>{'>'} KYC Detail</span></h6>
                <Link to="/kyclist">Back</Link>
                <div className="container d-flex">
                    <div className="kyc-item d-flex flex-column">
                        <h5 className="text-center">ID Proof</h5>
                        <AdminKYCAction 
                            docPath={docs.idProof}
                            docType="idproof"
                            isApproved={kyc.idProofAccept}
                            isRejected={kyc.idProofReject}
                            handleApproveReject={approveRejectDoc}
                            handleUploadPrompt={handleUploadPrompt}
                        />
                    </div>
                    <div className="kyc-item d-flex flex-column">
                        <h5 className="text-center">Address Proof</h5>
                        <AdminKYCAction 
                            docPath={docs.addressPath}
                            docType="address"
                            isApproved={kyc.addressProof}
                            isRejected={kyc.addressProofReject}
                            handleApproveReject={approveRejectDoc}
                            handleUploadPrompt={handleUploadPrompt}
                        />
                    </div>
                    <div className="kyc-item d-flex flex-column">
                        <h5 className="text-center">Selfie</h5>
                        <AdminKYCAction 
                            docPath={docs.selfiePath}
                            docType="selfie"
                            isApproved={kyc.selfieProofAccept}
                            isRejected={kyc.selfieProofReject}
                            handleApproveReject={approveRejectDoc}
                            handleUploadPrompt={handleUploadPrompt}
                        />
                    </div>
                </div>
                <ClientInfo kyc={props.location.state.entry} />
            </Page>
            <Modal size='lg' show={showUploader} onHide={handleCloseUploader}>
                <Modal.Header>Upload Proof Document</Modal.Header>
                <Modal.Body>
                    {uploader}
                </Modal.Body>
            </Modal>
            <Modal
                show={showPreview}
                onHide={handleClosePreview}
            >
                <Modal.Header>
                    Preview
                </Modal.Header>
                <Modal.Body>
                    <img src={previewFile} />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="submit_button" onClick={handleClosePreview}>OK</Button>
                </Modal.Footer>
            </Modal>
        </AdminMainLayout>
    )
}

export default AdminKYCDetailView;