import Page from '../../components/Page';
import React from 'react';
import './AdminClientView.css'
import { AdminMainLayout } from '../../components/Layout';
import {
    CLIENTS, CLIENTINFORMATION, CLIENTPROFILEDETAILS, EDITCLIENTPROFILE, BACK, IDTYPE, IDNUMBER, MEMBERID, CLIENTNAME,
    EMAILID, PHONENUMBER, DOB, NATIONALITY, CITY, EDITADDRESS, COUNTRYOFBIRTH, ZIPCODE, PENDING, REJECTED, ACTIVE, NO,
    ACCOUNTSTATUS, KYCSTATUS, IDPROOFSTATUS, ACTIVATIONCODE, OTPVERIFIEDSTATUS, HOME, EMAILVERIFICATION, TWOFACTORAUTHENABLE,
    SELFIEPROOFSTATUS, ADDRESSPROOFSTATUS, PASSPORTPIC, ADDRESSPIC, SELFIEPIC
} from "../../Constants"
import { Link } from 'react-router-dom'
import PASSPORT from '../../assets/img/passport-pic.jpg';
import SELFIE from '../../assets/img/selfie-pic.jpg';
import ADDRESSPROOF from '../../assets/img/addressproof-pic.jpg';
const AdminClientView = () => {
    return (
        <AdminMainLayout>
            <Page className="AdminClientView" >
                <h6 className="pageheader"> <span><i className="fa fa-home pageheader"></i><Link to="/admindashboard" >{HOME} </Link>{'>'} <Link to="/adminclientList" > {CLIENTS} </Link> {'>'} {CLIENTINFORMATION}</span></h6>
                <div className="clientview_change">
                    <div className="clientviewheader">
                        <h5 className="clientview_header">
                            <div>{CLIENTPROFILEDETAILS}</div>
                            <div><Link className="adminview" to='/adminclientedit'><i className="fa fa-edit"></i> {EDITCLIENTPROFILE} </Link> </div>
                            <div><Link className="adminview" to='/adminclientlist'>  {BACK} <i className="fa fa-arrow-left"></i></Link> </div>
                        </h5>
                        <div className='clientviewbody'>
                            <div className='clientviewbody1'>
                                <div className='clientviewbody2'>
                                    <div className='clientviewbody3'>
                                        <div className='clientdata'>{IDTYPE}</div>
                                        <div className='clientdata'>{IDNUMBER}</div>
                                        <div className='clientdata'>{MEMBERID}</div>
                                    </div>
                                    <div className='clientdata'>{CLIENTNAME}</div>
                                    <div className='clientdata'>{EMAILID}<br /><b>nerin93010@zoeyy.com</b></div>
                                    <div className='clientdata'>{PHONENUMBER}</div>
                                    <div className='clientdata'>{DOB}</div>
                                    <div className='clientdata'>{NATIONALITY}</div>
                                    <div className='clientdata'>{CITY}</div>
                                    <div className='clientdata'>{EDITADDRESS}</div>
                                </div>
                                <div className='clientviewbody2'>
                                    <div className='clientdata'>{COUNTRYOFBIRTH}</div>
                                    <div className='clientdata'>{ZIPCODE}</div>
                                    <div className='clientdatainfo'>
                                        <div className='clientdata'>{ACCOUNTSTATUS}<br /><div className='statusactive'><b>{ACTIVE}</b></div></div>
                                        <div className='clientdata'>{EMAILVERIFICATION}<br /><div className='statuspending'><b>{PENDING}</b></div></div>
                                        <div className='clientdata'>{ACTIVATIONCODE}<br /><div className='statuspending'><b>{PENDING}</b></div></div>
                                        <div className='clientdata'>{OTPVERIFIEDSTATUS}<br /><div className='statuspending'><b>{PENDING}</b></div></div>
                                        <div className='clientdata'>{TWOFACTORAUTHENABLE}<br /><div className='statuspending'><b>{NO}</b></div></div>
                                    </div>
                                </div>
                            </div>
                            <div className='clientviewbody1'>
                                <div className='clientviewbody4'>
                                    <div className='clientviewbody5'>
                                        <div className='clientdata'><b>{KYCSTATUS}<br /><div className='statuspending1'>{PENDING}</div></b></div>
                                    </div>
                                    <div className='clientviewbody3'>
                                        <div className='clientdata'><b>{IDPROOFSTATUS}<br /><div className='statuspending'>{REJECTED}</div></b></div>
                                        <div className='clientdata'>{PASSPORTPIC}<br />
                                            <a href={PASSPORT} target="_blank" rel="noopener noreferrer">
                                                <img className="proofsize" src={PASSPORT} alt="address" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='clientviewbody3'>
                                        <div className='clientdata'><b>{SELFIEPROOFSTATUS}<br /><div className='statuspending'>{REJECTED}</div></b></div>
                                        <div className='clientdata'>{SELFIEPIC}<br />
                                            <a href={SELFIE} target="_blank" rel="noopener noreferrer">
                                                <img className="proofsize" src={SELFIE} alt="address" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='clientviewbody3'>
                                        <div className='clientdata'><b>{ADDRESSPROOFSTATUS}<br /><div className='statuspending'>{REJECTED}</div></b></div>
                                        <div className='clientdata'>{ADDRESSPIC}<br />
                                            <a href={ADDRESSPROOF} target="_blank" rel="noopener noreferrer">
                                                <img className="proofsize" src={ADDRESSPROOF} alt="address" />
                                            </a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </AdminMainLayout>
    )
}

export default AdminClientView
