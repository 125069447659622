const requests = {
  BASE_URL: 'https://crm.maxi.exchange:3000/api/',
  // BASE_URL: 'https://maxi.tradala.tech:3000/api/',
  // BASE_URL:'http://localhost:5500/api/',
  emailregister: 'user/emailregister',
  distributorResigter: '/user/distributerRegister',
  login: 'user/login',
  otplogin: 'user/otplogin',
  feed: 'feed/',
  BASE_URL_FEED: 'https://www.crypto-news.in/',
  otp: 'user/otp',
  kyc: 'kyc',
  verifyotp: 'user/verifyotp',
  user: 'user/get',
  admin: 'admin/get',
  kycIdUpload: 'kyc/idproof',
  kycAddressUpload: 'kyc/addressproof',
  kycSelfieUpload: 'kyc/selfieproof',
  kycpic: 'kyc/kycpic/',
  kycUpdate: 'kyc/update',
  kycAll: 'kyc/getAllKyc',
  kycPending: 'kyc/pendingKycReq',
  kycApproveReject: 'kyc/admin/approveorreject',
  kycViewImg: 'kyc/viewKycImages',
  updateuser2factor: 'user/updateuser2factor',
  changepassword: 'user/changepassword',
  adminchangepassword: 'admin/changepassword',
  updateuserphoneotp: 'user/updateuserphoneotp',
  updateuserphone: 'user/updateuserphone',
  resendemail: 'user/resendemail',
  balance: 'user/balance',
  profilePic: 'user/profilePic',
  registrationdetails: 'admin/registrationdetails',
  userList: '/user',
  allUserList: '/user/getAllClients',
  adminLogin: 'admin/login',
  adminClientReportPage: '/report/fullreport',
  searchuser: '/user/getuser',
  getAllClients: '/user/getAllClients',
  withdrawReq: '/wallet/withdraw',
  forgotPasswordUser: 'user/forgotpassword',
  forgotPasswordAdmin: 'admin/forgotpassword',
  userTransactions: 'wallet/user/transactionHistory',
  adminTransactions: 'wallet/admin/transactionHistory',
  userWithdrawnTokens: '/wallet/totalWithdrawnTokens',
  withdrawals: 'admin/transactions',
  deleteUser: 'admin/deleteuser',
  enableOrDisableUser: 'admin/userdisableorenable',
  updateUser: 'admin/updateuser',
  newsfeed: 'user/newsFeed',
  resendOtp: 'user/resendotp',
  updateuserinfo: 'admin/updateuserInfo',
  initiatedWithdrawals: 'wallet/admin/initiatedTransaction',
  approveRejectWithdrawal: 'wallet/approveRejectWithdrawRequest',
  searchKyc: 'kyc/searchKycUsers',
  kycAdminAddressUpload: 'kyc/admin/addressproof',
  kycAdminIdUpload: 'kyc/admin/idproof',
  kycAdminSelfieUpload: 'kyc/admin/selfieproof',
  adminLogs: 'superadmin/adminlogs'
};

export default requests;
