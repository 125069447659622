import React from 'react';
import moment from 'moment';

const ClientInfo = (props) => {

    return (
        <div className="row">
            <div className="col-md-6 border-end border-primary px-5 py-3">
                <div className="d-flex justify-content-between my-2">
                    <span>First Name:</span>
                    <span>{props.kyc.userId.firstName}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                    <span>Last Name:</span>
                    <span>{props.kyc.userId.lastName}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                    <span>Date of Birth:</span>
                    <span>{props.kyc.dob}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                    <span>Email:</span>
                    <span>{props.kyc.userId.email}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                    <span>Phone Number:</span>
                    <span>{props.kyc.userId.phoneNumber}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                    <span>Client ID:</span>
                    <span>{props.kyc.userId.memberId}</span>
                </div>
            </div>
            <div className="col-md-6 px-5 py-2">
                <div className="d-flex justify-content-between my-2">
                    <span>Registered on:</span>
                    <span>{moment(props.kyc.userId.createAt).format('DD-MM-YYYY')}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                    <span>Address:</span>
                    <span>{props.kyc.address}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                    <span>City:</span>
                    <span>{props.kyc.city}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                    <span>Country of Birth:</span>
                    <span>{props.kyc.countyOfBirth}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                    <span>Nationality:</span>
                    <span>{props.kyc.nationality}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                    <span>Zip Code:</span>
                    <span>{props.kyc.zipCode}</span>
                </div>
            </div>
        </div>
    )
}

export default ClientInfo;