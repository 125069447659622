import Page from '../../components/Page';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './AdminTransaction.css'
import { AdminMainLayout } from '../../components/Layout';
import { Link } from 'react-router-dom';
import requests from '../../utils/requests';
import apiRequest from '../../services';
import MaterialTable from "@material-table/core";
import tableIcons from "./MaterialTableIcons";
import { HOME, TRANSACTIONS } from "../../Constants"

const columns = [
    { title: "Time and Date", field: "timeanddate", type: "date" },
    { title: "Client ID", field: "memberid", type: "numeric" },
    { title: "Address", field: "address", type: "string" },
    { title: "Rate", field: "rate", type: "numeric" },
    { title: "Transaction Status", field: "transactionstatus", type: "string" }
];

function AdminDashboardPage() {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);

    const mapTransactionData = (data) => {
        return data.map(tx => {
            return {
                timeanddate: moment(tx.createAt).format('MM-DD-YYYY'),
                memberid: tx.userId || 'NIL',
                address: tx.transactionAddress,
                rate: tx.amount,
                transactionstatus: tx.status
            };
        })
    }

    const getAdminTransactions = async () => {
        setLoading(true);
        const transactions = await apiRequest({
            method: 'GET',
            url: requests.adminTransactions,
            data: {}
        });
        if (transactions.success && transactions.data) {
            const tableData = mapTransactionData(transactions.data);
            setTransactions(tableData);
            setLoading(false);
        }
    }

    useEffect(() => {
        getAdminTransactions();
    }, []);
    
    return (
        <AdminMainLayout>
            <Page className="admintransactionPage">
                <h6 className="pageheader"> <span><i className="fa fa-home pageheader"></i><Link to="/admindashboard" >{HOME} </Link>{'>'}{TRANSACTIONS} </span> </h6>       
                <h5 className="transactionheading">{TRANSACTIONS}</h5>
                <MaterialTable 
                    icons={tableIcons} data={transactions} columns={columns} isLoading={loading}
                    options={{
                        sorting: true, search: false ,toolbar:false
                    }} 
                />
            </Page>

        </AdminMainLayout>
    );
}

export default AdminDashboardPage;