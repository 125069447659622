import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, Badge, Chip, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import moment from 'moment';
import Page from '../../components/Page';
import { MainLayout } from '../../components/Layout';
import Update2FA from '../../components/Modals/Update2FA';
import UpdateKYC from '../../components/Modals/UpdateKYC';
import UploadPicture from '../../components/Modals/UploadPicture';
import ProfileInfo from '../../components/Card/ProfileInfo';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import { fetchUser } from '../../utils/Helpers';
import PlaceholderImg from '../../assets/img/profile-pic.jpg';
import { setUser } from '../../store/userSlice';
import './Profile.css';

function ProfilePage(props) {
  const user = useSelector(state => state.user.info);
  const dispatch = useDispatch();
  const [chip2FA, setChip2FA] = useState({label: "2FA disabled", color: "warning", icon: <ErrorIcon />});
  const [chipKYC, setChipKYC] = useState({label: "KYC pending", color: "warning", icon: <ErrorIcon />});  
  const [chipEmail, setChipEmail] = useState({label: "Email Not Verified", color: "warning", icon: <ErrorIcon />})
  const [kycFormData, setKycFormData] = useState({email: '', phoneNumber: ''});
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [kycModalOpen, setKycModalOpen] = useState(false);
  const [profilePicModalOpen, setProfilePicModalOpen] = useState(false);
  const [editPicIconVisible, setEditPicIconVisible] = useState(false);

  useEffect(() => {
    console.log('resolving chips...');
    resolveChips();
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateUser = async () => {
    const userData = await fetchUser();
    dispatch(setUser(userData));
  }

  const resolveChips = () => {
    if (!user) return;
    const kycUploadDone = user.idPath && user.addressPath && user.selfiePath
                            && !user.idProofReject && !user.addressProofReject && !user.selfieProofReject;
    const isKycApproved = user.addressProof 
                            && user.idProofAccept
                            && user.selfieProofAccept;
    if (!kycUploadDone) {
      setChipKYC(prevChipKYC => ({
        ...prevChipKYC,
        label: "KYC not complete"
      }));
    }
    if (isKycApproved) {
      setChipKYC(prevChipKYC => ({
        label: "KYC Approved",
        color: "success",
        icon: <CheckCircleIcon />
      }));
    }
    if (user.isEmailVerified) {
      setChipEmail(prevChipEmail => ({
        label: "Email Verified",
        color: "success",
        icon: <CheckCircleIcon />
      }));
    } else {
      setChipEmail(prevChipEmail => ({
        label: "Email Not Verified",
        color: "warning",
        icon: <ErrorIcon />
      }));
    }
    setChip2FA(prev2FA => ({
      label: `2FA ${user.is2FactorAuthEnabled ? "enabled" : "disabled"}`,
      color: user.is2FactorAuthEnabled ? "success" : "warning",
      icon: user.is2FactorAuthEnabled ? <CheckCircleIcon /> : <ErrorIcon />
    }));
  }
  
  const updateKyc = async () => {
    const response = await apiRequest({
      method: 'POST',
      url: requests.kycUpdate,
      data: kycFormData,
    });
    if (response.success && response.data && response.data) {
      setKycFormData({ ...response.data });
    } else {
      notify(
        (response.data && response.data.message) || 'something went wrong!',
      );
    }
  };
  
  const handle2FAChange = async () => {
    setOtpModalOpen(true);
  };

  const handleKycChange = () => {
    setKycModalOpen(true);
  }

  const updateFactorAuth = async () => {
    const response = await apiRequest({
      method: 'POST',
      url: requests.updateuser2factor,
      data: { 
        is2FactorAuthEnabled: !user.is2FactorAuthEnabled 
      },
    });
    if (response.success && response.data) {
      notify(
        (response.data && response.data.message) ||
          'Two factor authentication successfully changed',
        "success"
      );
    } else {
      notify(
        (response.data && response.data.message) || 'something went wrong!',
      );
    }
    updateUser();
    setOtpModalOpen(false);
  };

  const handle2FAModalClose = () => {
    setOtpModalOpen(false);
  };

  const handleKycModalClose = () => {
    setKycModalOpen(false);
  };

  const handleProfilePicHover = () => {
    if (!editPicIconVisible) {
      setEditPicIconVisible(true);
    }
  }

  const handleProfilePicHoverOut = () => {
    if (editPicIconVisible) {
      setEditPicIconVisible(false);
    }
  }

  const editProfilePic = (e) => {
    e.preventDefault();
    setProfilePicModalOpen(true);
  }

  return (
    <MainLayout {...props}>
      <Page className="ProfilePage">
        <h6 className="pageheader">
          <span>
            {' '}
            <i className="fa fa-home pageheader"></i>
            <a href="/#/dashboard"> Home </a>
            {'>'} Profile Details
          </span>
        </h6>
        <main className="container d-flex flex-column">
          <div className="d-flex w-100">
            <div 
              id="profile-pic" 
              className="px-3" 
              onMouseOver={handleProfilePicHover}
              onMouseOut={handleProfilePicHoverOut}
            >
              <Badge 
                badgeContent={<a href="#!" onClick={editProfilePic}><EditIcon fontSize="small" /></a>} 
                invisible={!editPicIconVisible} 
              >
                <Avatar sx={{height: '10rem', width: '10rem'}} src={(user && user.profilePhoto) || PlaceholderImg} alt="profile picture" />
              </Badge>
            </div>
            <div id="profile-summary" className="d-flex flex-column flex-grow-1">
              <h3>{user && user.firstName + ' ' + user.lastName}</h3>
              <div>
                <div className="caption">Registered on {user && moment(user.createAt).format("LLL")}</div>
                <div className="d-flex caption">
                  <LocationCityIcon sx={{marginRight: '0.5rem'}} fontSize="small" />
                  <div>{user && user.city}, {user && user.countyOfBirth}</div>
                </div>
              </div>
              <div className="my-2">
                <Tooltip title="See what you can do" arrow>
                  <Chip {...chipKYC} size="small" onClick={handleKycChange} sx={{marginRight: '0.5rem'}} />
                </Tooltip>
                <Chip {...chipEmail} size="small" sx={{marginRight: '0.5rem'}} />
                <Tooltip title="Change" arrow>
                  <Chip {...chip2FA} size="small" onClick={handle2FAChange} className="mr-2" />
                </Tooltip>
              </div>
            </div>
            <hr />
          </div>
          <ProfileInfo />
        </main>

        <Update2FA
          data={{}}
          show={otpModalOpen}
          otpSuccessCallback={updateFactorAuth}
          handleClose={handle2FAModalClose}
        />
        <UpdateKYC
          show={kycModalOpen}
          handleClose={handleKycModalClose}
        />
        <UploadPicture 
          show={profilePicModalOpen}
          handleClose={() => {setProfilePicModalOpen(false)}}
          image={user && user.profilePhoto}
          successCallback={updateUser}
        />
      </Page>
    </MainLayout>
  );
}
export default ProfilePage;
