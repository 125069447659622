import React, { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import tableIcons from '../../pages/AdminDashboard/MaterialTableIcons';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import { Modal, Button } from 'react-bootstrap';

const WithdrawalsTable = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [currentAction, setCurrentAction] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const statusFilter = props.filters.status || "initiated";

    const handleClose = () => setShowConfirmationModal(false);

    let columnsRaw = [
        { title: "Requested On", field: "requestedOn", type: "date" },
        { title: "Client Name", field: "name", type: "string" },
        { title: "Client ID", field: "memberId", type: "string" },
        { title: "Address", field: "address", type: "string" },
        { title: "Country of Birth", field: "countryOfBirth", type: "string" },
        { title: "Amount Requested", field: "amount", type: "numeric" },
        { title: "Withdrawable Balance", field: "balance", type: "numeric" },
        { title: "Status", field: "transactionstatus", type: "string", defaultFilter: statusFilter, hidden: true }
    ];

    if (statusFilter === "initiated") {
        columnsRaw.push({title: "Action", field: "action", type: "string"})
    }
    
    let columns = columnsRaw.map(column => {
        return {...column}
    });

    useEffect(() => {
        getWithdrawals();
    }, []);

    const mapWithdrawalData = (data) => {
        return data.map(wd => {
            return {
                ...wd,
                requestedOn: wd.createAt,
                name: wd.userData[0] && `${wd.userData[0].firstName} ${wd.userData[0].lastName}`,
                memberId: wd.userData[0] && wd.userData[0].memberId,
                countryOfBirth: wd.kycData[0] && wd.kycData[0].countyOfBirth,
                address: wd.transactionAddress,
                amount: wd.amount,
                balance: wd.userData[0] && wd.userData[0].withdrawTokens.toFixed(2),
                transactionstatus: wd.status,
                tokenid: wd.tokenId,
                action: 
                    <span className='actionicons'>
                        <i 
                            className="fa fa-check-circle greenicon"
                            title="Approve"
                            onClick={(e) => {handleApproveReject(wd, true)}}
                        >
                        </i>
                        <i 
                            className="fa fa-times-circle redicon"  
                            title="Reject"
                            onClick={() => {handleApproveReject(wd, false)}}
                        >
                        </i>
                    </span>
            }
        });
    }

    const handleApproveReject = async (request, isApproval) => {
        setSelectedRequest(request);
        setCurrentAction(isApproval ? "Approve" : "Reject");
        setShowConfirmationModal(true);
    }

    const processWithdrawal = async () => {
        const isApproval = currentAction === "Approve";
        if (isApproval && selectedRequest.amount > selectedRequest.userData[0].withdrawTokens) {
            notify("Approval failed! Requested amount is greater than the available balance.");
            return;
        }
        const action = isApproval ? "approved" : "rejected";
        const response = await apiRequest({
            method: 'POST',
            url: requests.approveRejectWithdrawal,
            data: {
                _id: selectedRequest._id,
                isApprove: isApproval
            }
        });
        if (response.success) {
            notify(`Withdrawal successfully ${action}!`, "success");
            getWithdrawals();
        }
        else {
            notify(response.data.message);
        }
        setShowConfirmationModal(false);
    }

    const getWithdrawals = async () => {
        setLoading(true);
        const response = await apiRequest({
            method: 'GET',
            url: requests.initiatedWithdrawals,
        });
        const withdrawals = mapWithdrawalData(response.data);
        console.log(withdrawals);
        setData(withdrawals);
        setLoading(false);
    }

    return (
        <React.Fragment>
            <Modal show={showConfirmationModal} onHide={handleClose}>
                <Modal.Header>
                    <h3>Do you want to proceed?</h3>
                </Modal.Header>
                <Modal.Body>
                    <Alert severity="info">
                        You are about to <b>{currentAction}</b> a withdrawal request with the below details.
                    </Alert>
                    <div className="d-flex flex-column my-3">
                        <span>
                            Client Name: {
                                selectedRequest 
                                && `${
                                    selectedRequest.userData[0].firstName
                                } ${
                                    selectedRequest.userData[0].lastName
                                }`
                            }
                        </span>
                        <span>
                            Requested on: {
                                selectedRequest && moment(selectedRequest.createAt).format('DD-MM-YYYY')
                            }
                        </span>
                        <span>Requested Amount: {selectedRequest && selectedRequest.amount}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={processWithdrawal}>{currentAction}</Button>
                    <Button variant="light" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <MaterialTable
                icons={tableIcons} data={data} columns={columns} isLoading={loading}
                options={{
                    sorting: true, search: false ,toolbar:false
                }} 
            />
        </React.Fragment>
    );
}

export default WithdrawalsTable;