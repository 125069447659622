import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton, Switch, Badge } from '@mui/material';
import EditIcon from '@material-ui/icons/Edit';
import ChangePhone from '../Modals/ChangePhone';
import ChangeAddress from '../Modals/ChangeAddress';
import ChangePersonalInfo from '../Modals/ChangePersonalInfo';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import { fetchUser } from '../../utils/Helpers';
import { setUser } from '../../store/userSlice';

const ProfileInfo = () => {

    const user = useSelector(state => state.user.info);
    const [showPhoneEdit, setShowPhoneEdit] = useState(false);
    const [showPersonalEdit, setShowPersonalEdit] = useState(false);
    const [showAddressEdit, setShowAddressEdit] = useState(false);
    const [showPhoneModal, setShowPhoneModal] = useState(false);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showPersonalModal, setShowPersonalModal] = useState(false);

    const dispatch = useDispatch();

    if (!user) return null;

    const updatePhone = (e) => {
        e.preventDefault();
        setShowPhoneModal(true);
    }

    const updatePersonal = (e) => {
        e.preventDefault();
        setShowPersonalModal(true);
    }

    const updateAddress = (e) => {
        e.preventDefault();
        setShowAddressModal(true);
    }

    const handlePhoneHover = () => {
        if (!showPhoneEdit) {
            setShowPhoneEdit(true);
        }
    }

    const handlePhoneHoverOut = () => {
        if (showPhoneEdit) {
            setShowPhoneEdit(false);
        }
    }

    const handlePersonalHover = () => {
        if (!showPersonalEdit) {
            setShowPersonalEdit(true);
        }
    }

    const handlePersonalHoverOut = () => {
        if (showPersonalEdit) {
            setShowPersonalEdit(false);
        }
    }

    const handleAddressHover = () => {
        if (!showAddressEdit) {
            setShowAddressEdit(true);
        }
    }

    const handleAddressHoverOut = () => {
        if (showAddressEdit) {
            setShowAddressEdit(false);
        }
    }

    const BadgeContent = (props) => (
            <a href="#!" onClick={props.onClick}>
                <EditIcon fontSize="small" />
            </a>
    )

    const emailVerification = async (event) => {
        event.preventDefault()
        const response = await apiRequest({
            method: 'POST',
            url: requests.resendemail,
            data: {
                email: user.email
            }
        });
        if (response.success && response.data) {
            notify((response.data && response.data.message) || 'Mail sent successfully', 'success');
            const userUpdated = await fetchUser();
            dispatch(setUser(userUpdated));

        } else {
            notify((response.data && response.data.message) || 'something went wrong!')
        }
    }

    return (
        <div className="container my-4">
            <section 
                className="profile-segment py-3"
                onMouseOver={handlePhoneHover}
                onMouseOut={handlePhoneHoverOut}
            >
                <Badge sx={{width: '100%'}}
                    badgeContent={<BadgeContent onClick={updatePhone} />} 
                    invisible={!showPhoneEdit} 
                >
                    <div className="row w-100">
                        <div className="col-6 text-start">
                            Phone Number:
                        </div>
                        <div className="col-6 text-start">
                            {user.phoneNumber}
                        </div>
                    </div>
                </Badge>
            </section>

            <section 
                className="profile-segment py-3"
                onMouseOver={handlePersonalHover}
                onMouseOut={handlePersonalHoverOut}
            >
                <Badge sx={{width: '100%'}}
                    badgeContent={<BadgeContent onClick={updatePersonal} />} 
                    invisible={!showPersonalEdit} 
                    className="d-flex flex-column"
                >
                    <div className="row">
                        <div className="col-6 text-start">
                            Email:
                        </div>
                        <div className="col-4 text-start">
                            {user.email}
                        </div>
                        <div className="col-2 text-start">
                        {!user.isEmailVerified  && <button className="clickmail btn btn-link bg-white text-dark p-1" onClick={emailVerification}>
                            Verify
                        </button>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 text-start">
                            Email Notifications:
                        </div>
                        <div className="col-6 text-start">
                            <Switch
                                checked={user.emailNotifications}
                                disabled
                            />
                        </div>
                    </div>
                </Badge>
            </section>
            <section 
                className="profile-segment py-3"
                onMouseOver={handleAddressHover}
                onMouseOut={handleAddressHoverOut}
            >
                <Badge sx={{width: '100%'}}
                    badgeContent={<BadgeContent onClick={updateAddress} />} 
                    invisible={!showAddressEdit} 
                    className="d-flex flex-column"
                >
                    <div className="row">
                        <div className="col-6 text-start">
                            Date of Birth:
                        </div>
                        <div className="col-6 text-start">
                            {user.dob}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 text-start">
                            Address:
                        </div>
                        <div className="col-6 text-start">
                            {user.address}, {user.city} - {user.zipCode}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 text-start">
                            Country of Birth:
                        </div>
                        <div className="col-6 text-start">
                            {user.countyOfBirth}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 text-start">
                            Nationality:
                        </div>
                        <div className="col-6 text-start">
                            {user.nationality}
                        </div>
                    </div>
                </Badge>
            </section>
            <ChangePhone show={showPhoneModal} handleClose={() => setShowPhoneModal(false)} />
            <ChangeAddress show={showAddressModal} handleClose={() => setShowAddressModal(false)} />
            <ChangePersonalInfo show={showPersonalModal} handleClose={() => setShowPersonalModal(false)} />
        </div>
    )
}

export default ProfileInfo;