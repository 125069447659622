import React from 'react';
import { Skeleton } from '@mui/material';
import './DashboardCard.css';
import { Col } from 'react-bootstrap';

const DashboardCard = (props) => {
  return (
    <Col lg={12 / props.lgColumns} md={12 / props.mdColumns}>
      <article className="post">
        <div className="details">
          <div className="actions">
            <div
              style={{ backgroundColor: props.color }}
              className="btn btn-secondary btn-round"
            >
              <img src={props.image} alt="signedup" className="icon_img" />
            </div>
          </div>
          <h2>
            <div>{props.title}</div>
          </h2>
          <h5 style={{ color: props.color }}>
            {
              !!props.value
              ? <b>{props.value}</b>
              : <Skeleton />
            }
          </h5>
        </div>
      </article>
    </Col>
  );
};

export default DashboardCard;
