import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './AccInfo.css'
import Page from '../../components/Page';
import { MainLayout } from '../../components/Layout';
import { HOME, PROFILE, ACCOUNTINFO, REGISTRATION, KYCDOCUMENTS, EMAILID, EMAILVERIFICATION, POIPOR, ID, SELF, ADDRESS, NOTYET, CLICKHERE, EMAILVERIFIED } from "../../Constants";
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import moment from 'moment';



const AccInfoPage = (props) => {
    const [userEmail, setUserEmail] = useState()
    const [idProof, setIdProof] = useState()
    const [selfieProof, setSelfieProof] = useState()
    const [addressProof, setAddressProof] = useState()
    const [regDate, setRegDate] = useState()
    const [uploadStatus, setUploadStatus] = useState()
    const [isemailverified,setIsemailverified]=useState()
    const [emailtag,setEmailtag]=useState()
    const userInfo = useSelector(state => state.user.info)
    const kycdetaails = async (event) => {
        const response = await apiRequest({
            method: 'GET',
            url: requests.kyc

        });

        const dateToFormat = response.data.registeredAt;
        setRegDate(dateToFormat)
        setUserEmail(response.data.email)
        if(response.data.length){    
            if (response.data.idProofAccept === false) {
                setIdProof('Rejected')
            }
            else{
                setIdProof('Accepted')

            }
            if (response.data.selfieProofAccept === false) {
                setSelfieProof('Rejected')
            }
            else {
                setSelfieProof('Accepted')

            }
            if (response.data.addressProofSubmitted === false) {
                setAddressProof('Rejected')
            }
            else {
                setAddressProof('Accepted')
            }
            if (response.data.addressProofSubmitted === false && response.data.idProofAccept === false) {
                setUploadStatus('Pending')
            }
            else {
                setUploadStatus('Uploaded')
            }
        }else{
            setIdProof('KYC Pending')
            setSelfieProof('KYC Pending')
            setAddressProof('KYC Pending')
            setUploadStatus("Not Yet Uploaded")
        }

    }

    const userinfomation =async () =>{
        const response = await apiRequest({
          method: 'GET',
          url: "/user/get"
        });
        if (!(response.error) && response.data && response.data) {
            setUserEmail(response.data.email)
            setIsemailverified(response.data.isEmailVerified)
            if(response.data.isEmailVerified){
                setEmailtag("verified")
            }else{
                setEmailtag(`${NOTYET}`)
                // <br ><br /><strong>   <a href="/" className="clickmail" onClick={emailVerification}>{CLICKHERE}</a>{EMAILVERIFIED}</strong>`)
            }
            const dateToFormat = response.data.createAt;
            setRegDate(dateToFormat)
        } else {
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        kycdetaails()
        userinfomation()
    },
        [])

    const emailVerification = async (event) => {
        event.preventDefault()
        const response = await apiRequest({
            method: 'POST',
            url: requests.resendemail,
            data: {
                email: userInfo.email
            }

        });
        if (response.success && response.data) {
            notify((response.data && response.data.message) || 'Mail sent successfully', 'success');

        } else {
            notify((response.data && response.data.message) || 'something went wrong!')
        }
    }
    return (
        <MainLayout {...props}>
            <Page className="AccInfoPage" >
                <h6 className="pageheader"><span><i className="fa fa-home pageheader"></i><a href="/#/dashboard" >{HOME}</a>{' > '}<a href="/#/profiledetails">{PROFILE}</a>{'>'}{ACCOUNTINFO}</span></h6>
                <h5 className="accountadd">{ACCOUNTINFO}</h5>
                <div className="accounttab">
                    <div className="table_account">

                        <table>
                            <tr>
                                <th>{REGISTRATION}</th>
                                <th>{KYCDOCUMENTS}</th>
                                <th>{EMAILID}</th>
                                <th>{EMAILVERIFICATION}</th>
                                <th>{POIPOR}</th>
                            </tr>
                            <tr>
                                <td>
                                    {moment
                                        .utc(regDate, [moment.HTML5_FMT.DATETIME_LOCAL])
                                        .local()
                                        .format("MMM Do, YYYY hh:mm a")
                                    }
                                    <br />
                                </td>
                                <td>{ID} <strong>{idProof}</strong><br />
                                    {SELF} <strong>{selfieProof}</strong><br />
                                    {ADDRESS} <strong>{addressProof}</strong></td>
                                <td>{userEmail}</td>
                                <td>
                                    {isemailverified ? emailtag :
                                    <> {emailtag} <br/><strong>   <button className="clickmail btn btn-link bg-white text-dark p-1" onClick={emailVerification}>{CLICKHERE}</button>{EMAILVERIFIED}</strong>
                                    </>
                                }</td>
                                <td><strong>{uploadStatus}</strong></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </Page>
        </MainLayout>
    );
}
export default AccInfoPage;
