import { createSlice } from '@reduxjs/toolkit';

export const kycSlice = createSlice({
    name: 'kyc',
    initialState: {
        docs: []
    },
    reducers: {
        setDocs: (state, action) => {
            state.docs = action.payload
        }
    }
});

export const { setDocs } = kycSlice.actions;

export default kycSlice.reducer;