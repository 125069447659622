import React from 'react';
import { Navbar, Nav, NavItem } from 'reactstrap';

const Footer = () => {
  return (
    <Navbar>
      <Nav navbar>
        <NavItem>
          <p style={{color:"white"}}>© Copyright 2022 Maxi Trading. All Rights Reserved</p>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
