import Page from '../../components/Page';
import React, { useEffect, useState } from 'react';
import './AdminClientList.css'
import { AdminMainLayout } from '../../components/Layout';
import MaterialTable from "@material-table/core";
import tableIcons from "./MaterialTableIcons";
import {
    HOME, CLIENTLIST, CLIENTLISTDESC } from "../../Constants"
import { Link } from 'react-router-dom';
import ClientDetail from '../../components/Modals/ClientDetail';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import moment from 'moment'
import CsvDownload from "react-json-to-csv";
import ConfirmAdminAction from '../../components/Modals/ConfirmAdminAction';

function AdminClientListPage(props) {
    const [searchText, setSearchText] = useState('')
    const [searchBy, setSearchBy] = useState('email')
    const [users, setUsers] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [downloadData, setDownloadData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [currentUser, setCurrentUser] = useState(null);
    const [showClientDetail, setShowClientDetail] = useState(false);
    const [showAdminActionModal, setShowAdminActionModal] = useState(false);
    const [adminAction, setAdminAction] = useState('');
    const [clientDetailReadOnly, setClientDetailReadOnly] = useState(true);

    const handleClose = () => {
        setShowClientDetail(false);
        setShowAdminActionModal(false);
        setCurrentUser(null);
    };

    useEffect(() => {
        getAllUserDetails();
    }, []);

    useEffect(() => {
        let downloadableUsers = users.map(user => {
            delete user.kycInfo;
            delete user.action;
            return user;
        });
        setDownloadData(downloadableUsers); 
    }, [users]);

    const columnsRaw = [
        { title: "Client ID", field: "memberid", type: "numeric" },
        { title: "Client Name", field: "clientname", type: "string" },
        { title: "Email", field: "email", type: "string" },
        { title: "Phone", field: "phone", type: "numeric" },
        { title: "Registered Date", field: "registereddate", type: "date" },
        { title: "KYC Status", field: "kycstatus", type: "string" },
        { title: "Reserve Balance", field: "reservebalance", type: "numeric" },
        { title: "Withdrawable Balance", field: "withdrawablebalance", type: "numeric" },
        { title: "Action", field: "action", type: "string" },
    ];

    const columns = columnsRaw.map(column => {
        return { ...column };
    })

    const mapUserData = (data, isSearch = false) => {
        return data.map((user) => {
            const kyc = isSearch ? user : (!!user.kycInfo && !!user.kycInfo.length ? user.kycInfo[0] : {})
            const isKycSubmitted = !!kyc.idPath && !!kyc.addressPath && !!kyc.selfiePath
                                        && !kyc.addressProofReject && !kyc.idProofReject && !kyc.selfieProofReject;
            const isKycApproved = kyc.idProofAccept && kyc.addressProof && kyc.selfieProofAccept;
            return {
                ...user,
                memberid: user.memberId || 'NIL',
                clientname: [user.firstName, user.lastName].join(' '),
                email: user.email,
                phone: user.phoneNumber,
                registereddate: moment(user.createAt).format('MM-DD-YYYY'),
                kycstatus: isKycSubmitted ? (isKycApproved ? 'Approved' : 'Pending') : 'Incomplete',
                reservebalance: user.totalTokens ? user.totalTokens.toFixed(2) : 0,
                withdrawablebalance: user.withdrawTokens ? user.withdrawTokens.toFixed(2) : 0,
                emailverification: user.isEmailVerified ? 'Active' : 'Pending',
                action:
                    <span className='actionicons'>
                        <i 
                            className={`fa fa-check-circle${user.isUserEnabled ? " greenicon" : ""}`} 
                            title={`${user.isUserEnabled ? "Deactivate" : "Activate"} this client`} 
                            data-uid={user._id || user.id}
                            data-enabled={user.isUserEnabled}
                            onClick={(e) => {handleEnableOrDisableClient(e, user)}}
                        >
                        </i>
                        <i 
                            className="fa fa-eye orangeicon" 
                            title="View Client"
                            onClick={() => {handleViewClient(user)}}
                        >
                        </i>
                        <i 
                            className="fa fa-edit blueicon" 
                            title="Edit Client"
                            onClick={() => {handleEditClient(user)}}
                        >
                        </i>
                        <i 
                            className="fa fa-trash redicon" 
                            title="Delete Client"
                            data-uid={user._id || user.id}
                            onClick={() => {handleDeleteClient(user)}}
                        >
                        </i>
                    </span>
            }
        });
    }

    const getAllUserDetails = async (forceApiCall = false) => {
        setLoading(true);
        if (allUsers.length && !forceApiCall) {
            console.info("Data locally available");
            setUsers(allUsers);
            setLoading(false);
            return;
        }
        const response = await apiRequest({
            method: 'GET',
            url: requests.allUserList
        });
        if (response.success && response.data) {
            console.log(response.data.users);
            const data = mapUserData(response.data.users);
            setUsers(data);
            setAllUsers(data);
        } else {
            setUsers([]);
        }
        setLoading(false);
    }

    const searchuser = async (e) => {
        e.preventDefault();
        if (searchText === "") {
            getAllUserDetails();
            return;
        }
        setLoading(true);
        let payload = {
            searchField: {}
        };
        payload.searchField = { [searchBy]: searchText };
        const request = {
            method: 'POST',
            url: requests.searchuser,
            data: payload
        }
        console.log(request);
        const response = await apiRequest(request);
        if (!response.data.error) {
            const dataRoot = !!response.data.users ? response.data.users : response.data;
            console.log(dataRoot);
            const data = mapUserData(dataRoot, true);
            setUsers(data);
        } else {
            setUsers([]);
        }
        setLoading(false);
    }  

    const searchChangeHandler = (e) => {
        setSearchText(e.target.value);
    }

    const handleEnableOrDisableClient = (e, user) => {
        const action = user.isUserEnabled ? "Deactivate" : "Activate";
        setCurrentUser(user);
        setAdminAction(action);
        setShowAdminActionModal(true);
    }

    const handleDeleteClient = (user) => {
        setCurrentUser(user);
        setAdminAction('Delete');
        setShowAdminActionModal(true);
    }

    const onDeleteSuccess = () => {
        getAllUserDetails(true);
        handleClose();
    }

    const handleViewClient = (user) => {
        console.log(user);
        setCurrentUser(user);
        setShowClientDetail(true);
        setClientDetailReadOnly(true);
    }

    const handleEditClient = (user) => {
        setCurrentUser(user);
        setShowClientDetail(true);
        setClientDetailReadOnly(false);
    }

    const handleFilterChange = (e) => {
        setSearchBy(e.target.value);
    }
    
    return (
        <AdminMainLayout>
            <Page className="AdminClientListPage" >
                <h6 className="pageheader"> <span><i className="fa fa-home pageheader"></i><Link to="/admindashboard" >{HOME} </Link>{'>'} {CLIENTLIST}</span></h6>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h5>{CLIENTLIST}</h5>
                        <p>{CLIENTLISTDESC}</p>
                    </div>
                    <div className='report_button d-flex justify-content-end'>
                        <CsvDownload className="btn btn-success" filename="clientReport.csv" data={downloadData}> <span><i className="fa fa-download"></i> Download Full Report</span></CsvDownload>
                    </div>
                </div>
                <div className='admin_clientlisttable'>
                    <form className="d-flex justify-content-end align-items-center" name="clientFilter" id="clientFilter" onSubmit={searchuser}>
                        <div className="d-flex align-items-center mx-3">Search By:</div>
                        <select name="searchBy" id="searchBy" onChange={handleFilterChange} className="mx-2 h-75">
                            <option value="email" default>Email</option>
                            <option value="memberId" default>Client ID</option>
                            <option value="firstName" default>First Name</option>
                            <option value="lastName" default>Last Name</option>
                            <option value="phoneNumber" default>Phone Number</option>
                            <option value="countyOfBirth" default>Country of Birth</option>
                        </select>
                        <div className='search_Bar'>
                            <input type="text" onChange={searchChangeHandler} placeholder="Search" />
                        </div>
                    </form>
                    <MaterialTable className='admin_tablecontent'
                        isLoading={loading}
                        icons={tableIcons} columns={columns}
                        options={{
                            sorting: true, search: true, toolbar: false, pageSize: 10, pageSizeOptions: [10, 20, 50], rowStyle: {
                                fontSize: 12,
                            }
                        }}
                        data={users} />
                </div>
            </Page>
            <ClientDetail 
                userInfo={currentUser} 
                show={showClientDetail} 
                handleClose={handleClose}
                readOnly={clientDetailReadOnly}
                refreshTable={getAllUserDetails}
                key={currentUser}
            />
            <ConfirmAdminAction
                show={showAdminActionModal}
                handleClose={handleClose}
                action={adminAction}
                user={currentUser}
                onDeleteSuccess={onDeleteSuccess}
            />
        </AdminMainLayout>
    );
}
export default AdminClientListPage;
