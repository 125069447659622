import Avatar from '../Avatar';
import { UserCard } from '../Card';
import React, {useState,useEffect} from 'react';
import Cookies from 'universal-cookie';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  MdExitToApp,
  MdEdit,

} from 'react-icons/md';
import {
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import "./AdminHeader.css";
import iconMenu from "../../assets/icon-menu.png";
import { useHistory } from "react-router-dom";
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { setUser } from '../../store/userSlice';

const bem = bn.create('header');

const AdminHeader = (props) => {

  const [isOpenUserCardPopover, setIsOpenUserCardPopover] = useState(false)
  const user = useSelector(state => state.user.info)
  const cookies = new Cookies();
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleUserCardPopover = () => {
    setIsOpenUserCardPopover(!isOpenUserCardPopover);
  };

  const handleSidebarControlButton =(event)=> {
    event.preventDefault();
    event.stopPropagation();
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  const logout = () => {
    cookies.remove('SID');
    cookies.remove('userType');
    history.push("/");
  }

  const goToChangePassword = () => {
    history.push('/adminpassword')
  }

  const checkUser = async () => {
    if (!user) {
      const response = await apiRequest({
        method: 'GET',
        url: requests.admin
      });
      if (response.success && response.data) {
        console.log(response.data[0]);
        dispatch(setUser({
          ...response.data[0],
          lastLoggedInTime: moment.utc(
              response.data[0].lastLoggedInTime, 
              [moment.HTML5_FMT.DATETIME_LOCAL_MS, 'dddd, MMMM Do YYYY, hh:mm:ss a']
            )
            .local()
            .format("LLLL")
        }));
      }
    }
  }
  
  useEffect(() => {
    checkUser();
  },[]);

  return (
    <div>
      <Navbar light expand  sticky="top">
        <Nav navbar className="mr-3"  >
          <div onClick={handleSidebarControlButton} className="toggle-menu-btn">
            <img src={iconMenu} alt="iconMenu" />
          </div>
        </Nav>
        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={isOpenUserCardPopover}
              toggle={toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 200 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={!!user ? user.firstName +" " + user.lastName : ""}
                  subtitle={!!user ? user.email : ""}
                  className="border-light"
                >
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light" onClick={goToChangePassword}>
                      <MdEdit /> Change Password
                    </ListGroupItem>

                    <ListGroupItem tag="button" action className="border-light" onClick={logout} >
                      <MdExitToApp /> Sign Out
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  )
}

export default AdminHeader;