import React, { useEffect, useState } from 'react'
import './KYC2.css'
import Footer from '../../components/Layout/Footer'
import 'react-phone-number-input/style.css'
import Button from "@material-ui/core/Button";
//import { Link } from 'react-router-dom'
import {
    SIGNUPFORM, USERDETAILS, EMAILDETAILS, PERSONALINFO, KYCVERIFICATION, SUBMIT, UPLOADADDRESSPROOF, MAXIMUMFILESIZE,
    KYC2RULE1, KYC2RULE2, KYC2RULE3, KYCSUBRULE1, KYCSUBRULE2, KYCSUBRULE3, KYCSUBRULE4
} from "../../Constants"
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';

function KYCVerification2(props) {
    const [data, setData] = useState({
        email: '',
        idType: '',
        idNumber: '',
    })
    const [file, setFile] = useState(null)
    useEffect(() => {
        window.scrollTo(0, 0);
        const initialize = async () => {
            const response = await apiRequest({
                method: 'GET',
                url: requests.user

            });
            if (response.success && response.data  && response.data) {
                const newData = response.data
                setData({
                    ...data,
                    email: newData.email
                })
            }

        }
        initialize()

    }, [])



    const register = async (event) => {
        event.preventDefault()
        if (!data.email || !file) {
            return;
        }
        // let formData = new FormData();
        // formData.append("pic", file);
        // formData.append("idNumber", data.idNumber);
        // formData.append("idType", data.idType);
        // formData.append("email", data.email);

        const response = await apiRequest({
            method: 'POST',
            url: requests.selfieproof,
            // headers: {
            //     'content-type': 'multipart/form-data'
            // },
            data: {
                ...data,
                pic: file
            }
        });
        if (response.success) {
            props.history.push('/thankyou')
        } else {
            notify(response.message || response.data.message || 'Registration failed!')

        }
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const onChangeFile = async (e) => {
        if (!e.target.files && e.target.files.length > 0) {
            return;
        }
        const base64 = await convertBase64(e.target.files[0])
        setFile(base64)
    };


        return (
            <div className="KYCVerification2">
                <section className="LoginHeader">{SIGNUPFORM}
                </section>
                <br />
                <section>
                    <div className="container">
                        <ul className="progressbar">
                            <li className="complete"><i className="fa fa-user"></i> {USERDETAILS}</li>
                            <li className="complete"><i className="fa fa-envelope"></i> {EMAILDETAILS}</li>
                            <li className="complete"><i className="fa fa-user"></i> {PERSONALINFO}</li>
                            <li className="active"><i className="fa fa-user-check"></i> {KYCVERIFICATION}</li>
                        </ul>
                    </div>
                </section>
                <section className="formsection">
                    <div className="formcontainer">
                        <form>
                            <h5 className='kycheading'>{UPLOADADDRESSPROOF}</h5><br />
                            <ol>
                                <li>{KYC2RULE1}</li>
                                <ul>
                                    <li>{KYCSUBRULE1}</li>
                                    <li>{KYCSUBRULE2}</li>
                                    <li>{KYCSUBRULE3}</li>
                                    <li>{KYCSUBRULE4}</li>
                                </ul>
                                <li>{KYC2RULE2}</li>
                                <li>{KYC2RULE3}</li>
                            </ol>
                            <div className="row">

                                <div className="col-100">
                                    <input className="register1_formfield1" type="file" id="myFile" name="filename" onChange={onChangeFile} /><br /><br />
                                    <p>{MAXIMUMFILESIZE}</p>
                                </div>
                            </div>
                            <br />
                            <div className="buttonrow">
                                <span><Button className='submit_button' onClick={register} type="submit" value="Submit" >{SUBMIT}</Button></span>
                            </div>
                        </form>
                    </div>


                </section>
                <section className="footer">
                    <Footer />
                </section>
            </div>
        )
}
export default KYCVerification2;