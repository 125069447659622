import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tab, Box } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import Button from "@material-ui/core/Button";
import { Modal } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import apiRequest from '../../services';
import { notify } from '../../utils/handler';
import AddressProofUploader from '../../components/KYC/AddressProofUploader';
import IDProofUploader from '../../components/KYC/IDProofUploader';
import SelfieProofUploader from '../../components/KYC/SelfieProofUploader';
import { MainLayout } from '../../components/Layout';
import { setDocs } from '../../store/kycSlice';
import { setUser, clearUser } from '../../store/userSlice';
import { fetchUser } from '../../utils/Helpers';

const SubmitKYC = (props) => {
    
    const user = useSelector(state => state.user.info);
    const docs = useSelector(state => state.kyc.docs);
    const dispatch = useDispatch();
    const cookies = new Cookies();

    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('0');
    const [showPreview, setShowPreview] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);
    const [kycCompleted, setKycCompleted] = useState(false);
    const [showAbortKYCWarning, setShowAbortKYCWarning] = useState(false);

    const isSignupFlow = props.location.state && props.location.state.signupFlow;

    useEffect(() => {
        if (!user) {
            updateUser();
        }
        return () => {
            if (!kycCompleted && isSignupFlow) {
                flushSession();
                return;
            }
            updateUser();
        }
    }, [])
    
    useEffect(() => {
        if(user) {
            updateUploaders();
        }
    }, [user]);

    const handleClose = () => setShowPreview(false);
    
    const handleTabChange = (e, newValue) => {
        setValue(newValue)
    };

    const handlePreview = (e) => {
        e.preventDefault();
        setShowPreview(true);
    }

    const handleAbortKYC = () => {
        setShowAbortKYCWarning(true);
    }

    const flushSession = () => {
        cookies.remove("SID");
        cookies.remove("userType");
        dispatch(clearUser());
    }

    const updateUploaders = () => {
        const docsStatic = [
            {label: "address"},
            {label: "id"},
            {label: "selfie"}
        ];
        const docsUpdated = docsStatic.filter(doc => !user[doc.label + "Path"] || user[doc.label + "ProofReject"]);
        dispatch(setDocs(docsUpdated));
    }

    const upload = async (apiUrl, payload) => {
        setLoading(true);
        if (!payload.email || !payload.pic) {
            notify("Please choose the document you'd like to upload.");
            return false;
        }
        const response = await apiRequest({
            method: 'POST',
            url: apiUrl,
            data: payload
        });
        if (response.success) {
            notify("KYC document uploaded successfully. Thank you!", "success");
            console.log(docs.length)
            if(parseInt(value) < (docs.length - 1)) {
                const next = (parseInt(value) + 1).toString();
                setValue(next);
            }
            else {
                setKycCompleted(true);
                updateUser();
                updateUploaders();
                props.history.push({
                    pathname: "/kyc-thankyou",
                    state: {signupFlow: props.location.state}
                })
            }
        } else {
            console.log(response);
            notify(response.message || response.data.message || 'Document upload failed!');
        }
        setLoading(false);
    }

    const updateUser = async () => {
        const userData = await fetchUser();
        dispatch(setUser(userData));
    }

    const uploaderProps = {
        handleUpload: upload,
        handlePreview: handlePreview,
        handleAbortKYC: handleAbortKYC,
        setPreviewFile: setPreviewFile,
        user: user,
        isSignupFlow: isSignupFlow
    }

    const uploaders = {
        address: <AddressProofUploader {...uploaderProps} />,
        id: <IDProofUploader {...uploaderProps} />,
        selfie: <SelfieProofUploader {...uploaderProps} />
    }

    return (
        <MainLayout {...props} noSidebar={isSignupFlow}>
            <div className="container d-flex flex-column">
                <TabContext value={value}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <TabList onChange={handleTabChange}>
                            {docs.length > 0 && docs.map((doc, index) => (
                                <Tab 
                                    label={doc.label}
                                    value={index.toString()} 
                                    key={doc.label}
                                />
                            ))}
                        </TabList>
                    </Box>
                    {docs.length > 0 &&
                        docs.map((doc, index) => (
                            <TabPanel sx={{display: 'flex', flexDirection: 'column'}} value={index.toString()} key={doc.label}>
                                {loading ? <CircularProgress sx={{margin: '20% auto'}} /> : uploaders[doc.label]}
                            </TabPanel>
                        ))
                    }
                </TabContext>
                <Modal
                    show={showPreview}
                    onHide={handleClose}
                >
                    <Modal.Header>
                        Preview
                    </Modal.Header>
                    <Modal.Body>
                        <img src={previewFile} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="submit_button" onClick={handleClose}>OK</Button>
                    </Modal.Footer>
                </Modal>
            </div>
            {!docs.length &&
                <div className="h-100 d-flex flex-column
                 align-items-center justify-content-center">
                    <h3>You're all set</h3>
                    <p>You have no pending KYC documents to upload.</p>
                </div>
            }
            <Modal show={showAbortKYCWarning} onHide={() => setShowAbortKYCWarning(false)}>
                <Modal.Header>
                    <h5>Do you really want to skip the KYC submission?</h5>
                </Modal.Header>
                <Modal.Body>
                    If you click Yes, you will be redirected to the login page. After you login
                    with your credentials, you will be able to submit your KYC documents by
                    visiting your Profile section.
                </Modal.Body>
                <Modal.Footer>
                    <Link to={{pathname: "/client"}} className="btn btn-primary">Yes</Link>
                    <button className="btn btn-default" onClick={() => setShowAbortKYCWarning(false)}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </MainLayout>
    );
}

export default SubmitKYC;