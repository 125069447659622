
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from './store';
import { Provider } from 'react-redux';
import { AdminPrivateRoute, ClientPrivateRoute } from './components/PrivateRoute';
import DepositPage from './pages/Deposit/DepositPage';
import TransactionsPage from './pages/Transaction/TransactionsPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import WalletPage from './pages/Wallet/WalletPage';
import LoginPage from './pages/LoginPage/Login';
import AdminLoginPage from './pages/AdminLoginPage/AdminLogin';
import RegisterPage from './pages/RegisterPage/Register';
import RegisterReferral from './pages/RegisterPage/RegisterReferral';
import ProfilePage from './pages/Profile/ProfilePage';
import AccInfoPage from './pages/AccInfo/AccInfoPage';
import PwdPage from './pages/PasswordChange/PwdPage';
import KYCVerification from './pages/KYCVerificationPage/KYCVerification'
import KYC1 from './pages/KYCVerification1/KYC1'
import KYC2 from './pages/KYCVerification2/KYC2'
import KYCThankyou from './pages/KYCThankyouPage/KYCThankyou'
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
import AdminClientList from './pages/AdminClientList/AdminClientList';
import AdminTokenSale from './pages/AdminTokenSale/AdminTokenSale';
import AdminTransaction from './pages/AdminTransaction/AdminTransaction';
import AdminWallets from './pages/AdminWallets/AdminWallets';
import AdminPassword from './pages/AdminPassword/AdminPassword';
import Withdrawal from './pages/Withdrawal/Withdrawal'
import AdminClientEdit from './pages/AdminClientEdit/AdminClientEdit';
import AdminClientView from './pages/AdminClientView/AdminClientView';
import AdminKYCView from './pages/AdminKYCView/AdminKYCView';
import AdminWithdrawRequests from './pages/AdminWithdrawRequests/AdminWithdrawRequests';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import TokenSale from './pages/TokenSale/TokenSale';
import AdminKYCDetailView from './pages/AdminKYCDetailView/AdminKYCDetailView';
import SubmitKYC from './pages/SubmitKYC/SubmitKYC';
import AdminLogs from './pages/AdminLogs/AdminLogs';

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Switch>
          <Route exact path="/" component={AdminLoginPage} />
          <Route exact path="/admin" component={AdminLoginPage} />
          <Route exact path="/client" component={LoginPage} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/register-ref" component={RegisterReferral} />
          <Route exact path="/kycverification" component={KYCVerification} />
          <Route exact path="/kyc-thankyou" component={KYCThankyou} />
          <Route exact path="/kyc1" component={KYC1} />
          <Route exact path="/kyc2" component={KYC2} />
          <ClientPrivateRoute exact path="/dashboard" component={DashboardPage} />
          <ClientPrivateRoute exact path="/deposits" component={DepositPage} />
          <ClientPrivateRoute exact path="/transactions" component={TransactionsPage} />
          <ClientPrivateRoute exact path="/wallets" component={WalletPage} />
          <ClientPrivateRoute exact path="/withdraw" component={Withdrawal} />
          <ClientPrivateRoute exact path="/profiledetails" component={ProfilePage} />
          <ClientPrivateRoute exact path="/accounts" component={AccInfoPage} />
          <ClientPrivateRoute exact path="/passwords" component={PwdPage} />
          <ClientPrivateRoute exact path="/submit-kyc" component={SubmitKYC} />
          <AdminPrivateRoute exact path="/admindashboard" component={AdminDashboard} />
          <AdminPrivateRoute exact path="/adminclientlist" component={AdminClientList} />
          <AdminPrivateRoute exact path="/admintokensale" component={AdminTokenSale} />
          <AdminPrivateRoute exact path="/admintransactions" component={AdminTransaction} />
          <AdminPrivateRoute exact path="/adminwallets" component={AdminWallets} />
          <AdminPrivateRoute exact path="/adminpassword" component={AdminPassword} />
          <AdminPrivateRoute exact path="/adminclientview" component={AdminClientView} />
          <AdminPrivateRoute exact path="/adminclientedit" component={AdminClientEdit} />
          <AdminPrivateRoute exact path="/kyclist" component={AdminKYCView} />
          <AdminPrivateRoute exact path="/kycdetail" component={AdminKYCDetailView} />
          <AdminPrivateRoute exact path="/withdrawals" component={AdminWithdrawRequests} />
          <AdminPrivateRoute exact path="/tokensale" component={TokenSale} />
          <AdminPrivateRoute exact path="/admin-logs" component={AdminLogs} />
        </Switch>
        <ToastContainer theme="colored" />
      </Provider>
    );
  }
}
export default App;