import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import AvatarEditor from 'react-avatar-editor';
import 'bootstrap/dist/css/bootstrap.min.css';
import apiRequest, { convertBase64 } from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';

const UploadPicture = props => {

    const allowedFileExtensions = ['jpg', 'jpeg', 'png'];
    const canvasDimensions = {width: 350, height: 350};
    const fileRef = useRef();
    const user = useSelector(state => state.user.info);
    const [file, setFile] = useState(props.image);
    const [editor, setEditor] = useState(null);
    const [cropped, setCropped] = useState(null);
    const [transform, setTransform] = useState({
      scale: 1,
      rotate: 0
    });

    const setEditorRef = (editor) => setEditor(editor);

    useEffect(() => {
      console.log(cropped);
    }, [cropped]);

    useEffect(() => {
      setFile(props.image);
    }, [props.image]);

    useEffect(() => {
      setTransform({scale: 1, rotate: 0});
    }, [file]);

    const handleTransform = (e) => {
      console.log(e.target.value);
      setTransform({
          ...transform,
          [e.target.id]: e.target.value
      });
    }

    const handleCrop = async () => {
      if (editor) {
        const canvas = editor.getImage().toDataURL();
        const res = await fetch(canvas);
        console.log(res);
        const blob = await res.blob();
        const imageURL = window.URL.createObjectURL(blob);
        setCropped(res.url);
      }
    }

    const handleUpload = async () => {
      const response = await apiRequest({
        method: 'POST',
        url: requests.profilePic,
        data: {
          email: user.email,
          pic: cropped
        },
      });
      if (response.success) {
        notify(response.message || response.data.message || 'Upload successful!', 'success');
        props.successCallback();
        props.handleClose();
        setCropped(null);
        fileRef.current.value = '';
      } else {
        notify(response.message || response.data.message || 'Upload failed!');
      }
    };

    const onChangeFile = async e => {
        console.log(e.target.files);
        if (!e.target.files || e.target.files.length === 0) {
          return;
        }
        const file = e.target.files[0];
        const fileExtension = file.name.split('.').pop();
        const fileTypeAllowed = allowedFileExtensions.includes(fileExtension.toLowerCase());
        if (!fileTypeAllowed) {
          e.target.value = '';
          return notify("Please upload a valid image file. Allowed types: JPG, JPEG, PNG");
        }
        const base64 = await convertBase64(file);
        setFile(base64);
    };

    return (
        <Modal show={props.show} onHide={props.handleClose} keyboard={false}>
          <Modal.Header closeButton>
              <Modal.Title>Update Profile Picture</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!cropped ?
            <div className="form-group">
              <AvatarEditor
                  ref={setEditorRef}
                  image={file}
                  width={canvasDimensions.width}
                  height={canvasDimensions.height}
                  border={50}
                  color={[0, 0, 0, 0.6]} // RGBA
                  scale={transform.scale}
                  rotate={transform.rotate}
                  borderRadius={canvasDimensions.width / 2}
              />
              <div className="d-flex justify-content-around w-50 my-4">
                  <span className="d-flex flex-column w-100 align-items-center">
                      <label htmlFor="scale">Zoom</label>
                      <input 
                          id="scale"
                          type="range" 
                          min="1" 
                          max="2"
                          step="0.1" 
                          value={transform.scale} 
                          className="seek-slider mx-5"
                          onChange={handleTransform}
                      />
                  </span>
                  <span className="d-flex flex-column w-100 align-items-center">
                      <label htmlFor="rotate">Rotate</label>
                      <input 
                          id="rotate"
                          type="range" 
                          min="0" 
                          max="360"
                          value={transform.rotate} 
                          className="seek-slider mx-5"
                          onChange={handleTransform}
                      />
                  </span>
              </div>
              <div className="d-flex">
                <input
                    ref={fileRef}
                    onChange={onChangeFile}
                    type="file"
                    name="profile_pic"
                    id="profile_pic"
                />
                <button 
                  className="btn btn-primary"
                  onClick={handleCrop}
                >
                  Continue
                </button>
              </div>
                
            </div>
            :
            <div className="d-flex flex-column">
              <p className="text-center">Would you like to set this as your profile picture?</p>
              <img src={cropped} style={{}} />
              <div className="d-flex justify-content-end pt-3">
                <button className="btn btn-secondary mx-3" onClick={() => {setCropped(null)}}>Go Back</button>
                <button className="btn btn-primary" onClick={handleUpload}>Confirm</button>
              </div>
            </div>
            }
          </Modal.Body>
      </Modal>
    );
};

export default UploadPicture;
