import Page from '../../components/Page';
import React, { useEffect, useState } from 'react';
import './AdminTokenSale.css'
import { AdminMainLayout } from '../../components/Layout';
import {
    HOME, CLIENTLIST, CLIENTLISTDESC, MEMBERID,
    CLIENTNAME,
    EMAIL,
    PHONE,
    REGISTEREDDATE,
    KYCSTATUS,
    ACCOUNTBALANCE,
    TOKENSOWNEDHEADER,
    TOKENSALE
} from "../../Constants"
import { Link } from 'react-router-dom';
import apiRequest from '../../services';
import requests from '../../utils/requests';
// const data = [

//     {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     },

//     {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     }, {
//         memberid: "123456", clientname: "Admin", email: "admin@gmail.com", phone: "9807654321", registereddate: "21-12-2021",
//         kycstatus: "Pending", accbalance: "0", tokensowned: "0",
//         action:
//             <span className='actionicons'>
//                 <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
//                 <i className="fa fa-eye orangeicon" title="View Client"></i>
//                 <i className="fa fa-edit blueicon" title="Edit Client"></i>
//                 <i className="fa fa-trash redicon" title="Delete Client"></i>
//             </span>
//     },
// ];
// const columns = [
//     { title: "Client ID", field: "memberid", type: "number" },
//     { title: "Client Name", field: "clientname", type: "text" },
//     { title: "Email", field: "email", type: "email" },
//     { title: "Phone", field: "phone", type: "number" },
//     { title: "Registered Date", field: "registereddate", type: "date" },
//     { title: "KYC Status", field: "kycstatus", type: "text" },
//     { title: "Account Balance", field: "accbalance", type: "numeric" },
//     { title: "Tokens Owned", field: "tokensowned", type: "numeric" },
//     { title: "Action", field: "action", type: "icon" },
// ];

function AdminToken(props) {
    const [users, setUsers] = useState([])
    const [currentAction, setcurrentAction] = useState("")

    const getAllUserDetails = async () => {
        const response = await apiRequest({
            method: 'GET',
            url: requests.userList,
            params: {
                pageNo: 1,
                size: 10
            }
        });
        
        if (response.success && response.data && response.data) {
            let data = response.data.users.map((user) => {
                return {
                    // memberid: user.id,
                    // clientname: user.firstName,
                    // email: user.email,
                    // phone: user.phoneNumber,
                    // registereddate: user.createAt.split(",")[1],
                    // kycstatus: user.isUserEnabled,
                    // accbalance: user.accountBalance,
                    // tokensowned: user.tokenOwned,
                    ...user,
                    action:
                        <span className='actionicons'>
                            <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
                            <i className="fa fa-eye orangeicon" title="View Client"></i>
                            <i className="fa fa-edit blueicon" title="Edit Client"></i>
                            <i className="fa fa-trash redicon" title="Delete Client"></i>
                        </span>
                }
            })
            setUsers(data)
        } else {
        }

    }
    const onClickHandler = (event) => {
        setcurrentAction(event)
    }
    useEffect(() => {
        getAllUserDetails()
    }, [])
    useEffect(() => {
        setcurrentAction("")
    }, currentAction)
    return (
        <AdminMainLayout>
            <Page className="AdminClientListPage" >
                <h6 className="pageheader"> <span><i className="fa fa-home pageheader"></i><Link to="/admindashboard" >{HOME} </Link>{'>'} {CLIENTLIST}</span></h6>
                <h5>{TOKENSALE}</h5>
                <p>{CLIENTLISTDESC}</p>
                <div className='search_Bar col-lg-4'>
                    <label>Search</label>
                    <input />
                </div>
                <br />
                <br />

                <div className="wallet">
                    <div>
                        {/* <h5>{CLIENTLIST}</h5>
                        <p>{REGISTEREDCLIENTS}</p> */}
                        <div className="clientlist">
                            <div className="table_clientlist">
                                <table className='tablecontent'>
                                    <tr className='clienttableheader'>
                                        <th>{MEMBERID}</th>
                                        <th>{CLIENTNAME}</th>
                                        <th>{EMAIL}</th>
                                        <th>{PHONE}</th>
                                        <th>{REGISTEREDDATE}</th>
                                        <th>{KYCSTATUS}</th>
                                        <th>{ACCOUNTBALANCE}</th>
                                        <th>{TOKENSOWNEDHEADER}</th>
                                        <th>Action</th>
                                    </tr>

                                    {users && users.length > 0 && (
                                        users.map(user => {
                                            return (
                                                <tr key={user.id}>
                                                    <td>{user.id ? user.id : "NIL"}</td>
                                                    <td>{user.firstName ? user.firstName : "NULL"}</td>
                                                    <td>{user.email ? user.email : "NULL"}</td>
                                                    <td>{user.phoneNumber ? user.phoneNumber : "NULL"}</td>
                                                    {/* <td>{user.createAt}</td> */}
                                                    <td>{user.createAt.split(",")[1]}</td>
                                                    <td>{user.isUserEnabled === true ? <span style={{ color: "green" }}>Approved</span> : <span style={{ color: "red" }}>Approved</span>}</td>
                                                    <td>{user.accountBalance}</td>
                                                    <td>{user.tokenOwned}</td>
                                                    <td>
                                                        <div>
                                                            <span className='actionicons'>
                                                                <i onClick={(e) => onClickHandler("deactivate")} value="deactivate" className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
                                                                <i onClick={(e) => onClickHandler("view")} value="view" className="fa fa-eye orangeicon" title="View Client"></i>
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <div>
                                                            <span className='actionicons'>
                                                                <i onClick={(e) => onClickHandler("edit")} value="edit" className="fa fa-edit blueicon" title="Edit Client"></i>
                                                                <i onClick={(e) => onClickHandler("delete")} value="delete" className="fa fa-trash redicon" title="Delete Client"></i>
                                                            </span>
                                                        </div>
                                                        {/* <span className='actionicons'>
                                                            <div onclick={(e) => onClickHandler(e)} value="deactivate">
                                                                <i   className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
                                                            </div>
                                                            <div>
                                                                <i onclick={(e) => onClickHandler(e)} value="view" className="fa fa-eye orangeicon" title="View Client"></i>
                                                            </div>
                                                        </span> */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    )}:{<tr>
                                        <td className="loading">loading ...</td>
                                    </tr>}
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

                {/* <MaterialTable title="CLIENT LIST" icons={tableIcons} columns={columns} data={users} options={{
                    sorting: true, search: false
                }} /> */}
            </Page>
        </AdminMainLayout>
    );
}
export default AdminToken;
