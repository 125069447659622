import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import './Dashboard.css';
import { MainLayout } from '../../components/Layout';
import ClientDeclaration from '../../components/Modals/ClientDeclaration';
import {
  LASTLOGIN,
  OVERVIEW,
  TOKENSOWNED,
  TOKENSAVAILABLE,
  RECEIVETOKENS,
  RECENTNEWSFEED,
  YOURWALLET,
  RECEIVE,
} from '../../Constants';
import { useEffect } from 'react';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { setUser, clearUser } from '../../store/userSlice';
import { fetchUser } from '../../utils/Helpers';
import { notify } from '../../utils/handler';

function DashboardPage(props) {

  const loggedinuser = useSelector(state => state.user.info);
  const [feeds, setFeeds] = useState([]);
  const [withdrawTockens, setWithdrawTockens] = useState(0);
  const [totalTocken, setTotalTocken] = useState(0);
  const [totalWithdrawnTokens, setTotalWithdrawnTokens] = useState(0);
  const [withdrawals, setWithdrawals] = useState([]);
  const [declarationAccepted, setDeclarationAccepted] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    getFeeds();
    getwithdrawTockens();
    getTotalWithdrawnTokens();
    getKYC();
    getRecentWithdrawals();
    greet();
  }, []);

  useEffect(() => {
    if (loggedinuser) {
      setDeclarationAccepted(loggedinuser.isAgreed);
    }
  }, [loggedinuser, declarationAccepted]);

  const getKYC = async () => {
    const response = await apiRequest({
      method: 'GET',
      url: requests.kyc,
    });
    if (response.success && response.data && response.data) {
      setTotalTocken(response.data['totalTokens']);
      setWithdrawTockens(response.data['withdrawTokens']);
    } else {
    }
  };

  const getFeeds = async () => {
    const response = await apiRequest({
      method: 'GET',
      url: requests.newsfeed,
    });
    setFeeds(response.data);
  };

  const getwithdrawTockens = async () => {
    const response = await apiRequest({
      method: 'GET',
      url: requests.user,
      data: {},
    });
    if (response.success && response.data) {
      if (typeof response.data.withdrawTokens !== 'undefined') {
        setWithdrawTockens(response.data.withdrawTokens);
      }
      setTotalTocken(response.data.totalTokens || 0);
      // }
    } else {
    }
  };

  const getTotalWithdrawnTokens = async () => {
    const tokens = await apiRequest({
      url: requests.userWithdrawnTokens,
      method: 'GET',
    });
    if (tokens.data.length) {
      setTotalWithdrawnTokens(tokens.data[0].amount);
    }
  };

  const getRecentWithdrawals = async () => {
    const response = await apiRequest({
      method: 'GET',
      url: requests.userTransactions
    });
    setWithdrawals(response.data);
  }

  const greet = () => {
    const now = new Date();
    const localNow = moment(now).local().hour();
    if (localNow < 12) {
      return 'Good Morning';
    } else if (localNow < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };

  const handleRevokeDeclaration = async () => {
    const request = {
        method: 'POST',
        url: requests.updateuserinfo,
        data: {
            isAgreed: false
        }
    }
    const response = await apiRequest(request);
    if (response.success) {
        notify("Your declaration has been revoked");
        const user = await fetchUser();
        dispatch(setUser(user));
    }
  }

  return (
    <MainLayout {...props}>
      {!!loggedinuser &&
      <div className="DashboardPage">
        <div className="welcome_msg">
          <h5 style={{ color: 'white' }}>
            <b>
              {greet()}, {loggedinuser && loggedinuser.firstName}. Welcome back!
            </b>
          </h5>
          <p>
            <strong>{LASTLOGIN}</strong>
            {loggedinuser && loggedinuser.lastLoggedInTime}
          </p>
        </div>
        <div className="p-2">
          <div className="m-1">
            <h5>{OVERVIEW}</h5>
            <Row>
              <Col lg={4}>
                <article className="post">
                  <div className="details">
                    <div className="actions">
                      <div
                        style={{ cursor: 'default', backgroundColor: '#7bc542' }}
                        className="btn btn-secondary btn-round"
                      >
                        <i
                          className="fa fa-area-chart"
                          style={{ fontSize: '30px' }}
                        ></i>
                      </div>
                    </div>
                    <h2>
                      <div>{TOKENSOWNED}</div>
                    </h2>
                    <h5 style={{ color: `#7bc542` }}>
                      <b> {totalTocken && totalTocken.toFixed(5)}</b>
                    </h5>
                  </div>
                </article>
              </Col>
              <Col lg={4}>
                <article className="post">
                  <div className="details">
                    <div className="actions">
                      <div
                        style={{ cursor: 'default', backgroundColor: '#2596BE' }}
                        className="btn btn-secondary btn-round"
                      >
                        <i
                          className="fa fa-area-chart"
                          style={{ fontSize: '30px' }}
                        ></i>
                      </div>
                    </div>
                    <h2>
                      <div>{TOKENSAVAILABLE}</div>
                    </h2>
                    <h5 style={{ color: `#2596BE` }}>
                      <b> {withdrawTockens && withdrawTockens.toFixed(5)}</b>
                    </h5>
                  </div>
                </article>
              </Col>
              <Col lg={4}>
                <article className="post">
                  <div className="details">
                    <div className="actions">
                      <div
                        style={{ cursor: 'default', backgroundColor: '#1269db' }}
                        className="btn btn-secondary btn-round"
                      >
                        <i
                          className="fa fa-area-chart"
                          style={{ fontSize: '30px' }}
                        ></i>
                      </div>
                    </div>
                    <h2>
                      <div>{RECEIVETOKENS}</div>
                    </h2>
                    <h5 style={{ color: `#1269db` }}>
                      <b> {totalWithdrawnTokens && totalWithdrawnTokens.toFixed(5)}</b>
                    </h5>
                  </div>
                </article>
              </Col>
            </Row>

            {
              loggedinuser.email === "karthik.sivasubramaniam@gmail.com" &&
              <Button onClick={handleRevokeDeclaration} variant="danger">Revoke Declaration</Button>
            }

            <div className="depositsec" id="deposit">
              <div className="deposit" id="deposits">
                <div className="scroll">
                  <div className="newsfeed">
                    <div className="recent-news-feed mh-100">
                      <h5>{RECENTNEWSFEED}</h5>
                      <div className="feed mh-100">
                        <div>
                          <marquee direction="up" className="newsfeed" scrollamount="3" height="100%">
                            {feeds.map(f => {
                              return (
                                <p className="singlefeed" key={f.link}>
                                  <a
                                    href={f.link}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    {f.title}
                                  </a>
                                  <br />
                                  <span className="feedinfo">{f.pubDate}</span>
                                </p>
                              );
                            })}
                          </marquee>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wallet">
                  <div>
                    <h5>{YOURWALLET}</h5>
                  </div>
                  <br />
                  <div className="walletdetails d-flex flex-column align-items-center">
                    <div>
                      <h4>Available Balance: {withdrawTockens && withdrawTockens.toFixed(5)}</h4>
                    </div>
                    <div className="my-3">
                      {' '}
                      <button 
                        className="primary" 
                        onClick={() => {props.history.push('/wallets')}}
                      >
                        {RECEIVE}
                      </button>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div className="btctable">
                    <div className="transaction">
                      <strong>RECENT WITHDRAWALS</strong>
                    </div>
                    {!withdrawals.length ?
                      <div className="d-flex align-items-center justify-content-center py-5">
                        No records found.
                      </div>
                      :
                      <div>
                      {
                        withdrawals.map((tx, index) => {
                          <div className="tablerow" key={index}>
                            <div>
                              <span className="green"></span>
                              {tx.createAt}
                            </div>
                            <div>{tx.transactionAddress}</div>
                            <div>{tx.amount}</div>
                          </div>
                        })
                      }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <br />
            
          </div>
        </div>
      </div>}
      <ClientDeclaration 
        show={declarationAccepted === false 
          && !!totalTocken 
          && loggedinuser 
          && (loggedinuser.email === "karthik.sivasubramaniam@gmail.com")
        } 
      />
    </MainLayout>
  );
}
export default DashboardPage;
