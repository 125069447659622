import React from 'react';
import { useSelector } from 'react-redux';
import './Wallet.css'
import Page from '../../components/Page';
import { HOME, MAXITOKENS, MYWALLET, TOKENSOWNED, TOKENSAVAILABLE, WITHDRAW } from "../../Constants"
import { MainLayout } from '../../components/Layout';
import { useState,useEffect } from 'react';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { Link } from 'react-router-dom'
function WalletPage(props) {
  const [totalToken, setTotalToken] = useState(0);
  const [withdrawToken, setWithdrawToken] = useState(0);
  const [kycDone, setKycDone] = useState(false);
  const user = useSelector(state => state.user.info);
  
  const getuserInfo=async()=>{
    const response = await apiRequest({
      method: 'GET',
      url: requests.user,
    });
    console.log(response);
    if (response.success && response.data) {
      setTotalToken(response.data.totalTokens)
      setWithdrawToken(response.data.withdrawTokens)
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getuserInfo()
  }, []);

  useEffect(() => {
    if (user) {
      const kycStatus = !!user.idPath && !!user.addressPath && !!user.selfiePath;
      setKycDone(kycStatus);
    }
  }, [user]);

  return (
    <MainLayout {...props}>
        {
          kycDone && user.userId.isUserEnabled ?
          <Page className="walletPage">
            <h6 className="pageheader"><span> <i className="fa fa-home pageheader"></i><a href="/#/dashboard" >{HOME} </a>{'>'}{MAXITOKENS}{'>'} {MYWALLET}</span> </h6>
            <div className="walletPage_content">
                <div className="wallet_col">
                    <div className="wallet_row">
                        <div className="wallet_sec">
                          <i className="fa fa-cog wallet_icon" ></i>
                          <span>{totalToken}</span>
                        </div>
                        <div className="wallet_row_description">{TOKENSOWNED}</div>
                    </div>
                    <div className="wallet_row">
                        <div className="wallet_sec">
                            <i className="fa fa-cog wallet_icon"></i>
                            <span>{withdrawToken}</span>
                        </div>
                        <div className="wallet_row_description">{TOKENSAVAILABLE}</div>
                    </div>
                </div>
                <br />
                <div className="withdraw_btn_area">
                    <div><Link to="/withdraw" className="withdraw_button">{WITHDRAW}</Link></div>
                </div>
            </div>
          </Page>
          :
          <div className="h-100 d-flex flex-column align-items-center justify-content-center">
            <h3>Access Restricted</h3>
            <p>You either need to submit your KYC documents or your access to this section has been disabled by admin.</p>
            <p>Please contact admin for assistance or write to us at contact@maxi.exchange</p>
          </div>
        }
    </MainLayout>
  );
}
export default WalletPage;
