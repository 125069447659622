import Page from '../../components/Page';
import React from 'react';
import './TokenSale.css'
import { AdminMainLayout } from '../../components/Layout';
const TokenSale = () => {
    return (
        <AdminMainLayout>
            <Page>
                Token Sale
            </Page>
        </AdminMainLayout>
    )
}
export default TokenSale
