import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import validator from 'validator';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import './Pwd.css'
import { HOME, CHANGEPASSWORD, CHANGEYOURPWD, CURRENTPWD, NEWPWD, DESCRIPTION, SUBMIT } from "../../Constants"
import Page from '../../components/Page';
import { MainLayout } from '../../components/Layout';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import { clearUser } from '../../store/userSlice';

const PwdPage = (props) => {
    
    const [data, setData] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword:"",
        showPassword: false,
        showCurrentPassword: false
    });
    const [passwordChanged, setPasswordChanged] = useState(false);
    const userData = useSelector(state => state.user.info);
    const dispatch = useDispatch();
    const cookies = new Cookies();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        return () => {
            if (passwordChanged) {
                cookies.remove('SID');
                cookies.remove('userType');
                dispatch(clearUser());
            }
        }
    }, [passwordChanged]);

    const changepassword = async (event) => {
        event.preventDefault();
        if(!(data.newPassword===data.confirmPassword)){
            return notify('Password Mismatch')
        }
        if (!validator.isStrongPassword(data.newPassword, {
            minSymbols: 0
        })) {
            return notify("Please choose a strong password.");
        }
        if (!userData) return notify("Failed to fetch your profile details. Please try again.");
        const response = await apiRequest({
            method: 'POST',
            url: requests.changepassword,
            data: {
                email: userData.email,
                currentPassword: data.currentPassword,
                newPassword: data.newPassword
            }
        });
        if (response.success && response.data && response.data) {
            notify('Password updated successfully! Please login with your new password.', 'success');
            setPasswordChanged(true);
            props.history.push('/client');
        } else {
            notify((response.data && response.data.message) || 'something went wrong!')
        }
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.id]: e.target.value
        });
    }

    const handleClickShowPassword = () => {
        setData({ ...data, showPassword: !data.showPassword }); 
    };

    const handleClickShowCurrentPassword = () => {
        setData({ ...data, showCurrentPassword: !data.showCurrentPassword });
    };

    return (
        <MainLayout {...props}>
            <Page className="PwdPage" >
                <h6 className="pageheader"> <span><i className="fa fa-home pageheader"></i><a href="/#/dashboard" >{HOME} </a>{'>'} {CHANGEPASSWORD}</span></h6>
                <div className="password_change">
                    <div className="pass_header">
                        <h5 className="password_header">{CHANGEYOURPWD}</h5>
                        <form onSubmit={changepassword} action="" method="post">
                            <div className="password_body">
                                <p>{CURRENTPWD}</p>
                                <Input 
                                    className="client_formpassword"
                                    id="currentPassword"
                                    type={data.showCurrentPassword ? "text" : "password"} 
                                    placeholder="Please enter your current password" 
                                    value={data.currentPassword} 
                                    required 
                                    onChange={handleChange} 
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowCurrentPassword}
                                            >
                                                {data.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <br />
                                <p>{NEWPWD}</p>
                                <Input 
                                    className="client_formpassword"
                                    id="newPassword"
                                    type={data.showPassword ? "text" : "password"}
                                    placeholder="Please enter your new password" 
                                    value={data.newPassword} 
                                    required 
                                    onChange={handleChange} 
                                />
                                <br />
                                <p>Confirm new password</p>
                                <Input 
                                    className="client_formpassword"
                                    id="confirmPassword"
                                    type={data.showPassword ? "text" : "password"}
                                    onChange={handleChange}
                                    value={data.confirmPassword}
                                    placeholder="Please enter your new password to confirm"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                            >
                                                {data.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    } /><br />
                                <p>{DESCRIPTION}</p>
                                <button 
                                    type="submit" 
                                    className="submit_button" 
                                >
                                    {SUBMIT}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Page>
        </MainLayout>
    )
}

export default PwdPage

