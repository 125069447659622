import React from 'react'
import './Deposit.css'
import Page from '../../components/Page';
import QR from '../../assets/img/download.png';
import { HOME, DEPOSIT, ETHEREUMADDRESS, CLIPBOARDADD, DEPOSITADDRESS } from "../../Constants"
import { MainLayout } from '../../components/Layout';
class DepositPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    } render() {
        return (
            <MainLayout {...this.props}>
            <Page className="depositPage">
                <h6 className="pageheader"><span> <i className="fa fa-home pageheader"></i><a href="/#/dashboard" >{HOME} </a>{'>'} {DEPOSIT} </span></h6>
                <div className="ethereum">
                    <div className="ethereumqr">
                        <h5 className="ethereumadd">{ETHEREUMADDRESS}</h5>
                        <div className="qrimg"><img src={QR} alt="qrcode" />
                        </div>
                        <br />
                        <div className="Daddress">
                            <p>{DEPOSITADDRESS}<strong className="deposit_address">{CLIPBOARDADD}</strong></p>
                        </div>
                    </div>
                </div>
            </Page>
            </MainLayout>
        )
    }
}
export default DepositPage

