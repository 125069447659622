import React from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import { Alert } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearUser } from '../../store/userSlice';

const KYCConsent = (props) => {

    const dispatch = useDispatch();
    const cookies = new Cookies();

    const handleRejectConsent = () => {
        cookies.remove('SID');
        cookies.remove('userType');
        dispatch(clearUser());
        window.location = "/#/client";
    }

    return (
        <div>
            <h3>Upload Your KYC Documents</h3>
            <Alert sx={{margin: '1rem'}} severity="info">
                In order to use our services, we'd require you to 
                upload the following documents.
            </Alert>
            <ul>
                <li>Address proof</li>
                <li>ID proof</li>
                <li>Your selfie photograph</li>
            </ul>
            <p>Would you like to upload them now?</p>
            <div className="d-flex justify-content-end">
                <Link 
                    className="btn btn-primary mx-3" 
                    to={{pathname: "/submit-kyc", state: {signupFlow: true}}}
                >
                    Upload Now
                </Link>
                <button className="btn btn-secondary" onClick={handleRejectConsent}>
                    Submit Later
                </button>
            </div>
        </div>
    )
}

export default KYCConsent;