import React from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { clearUser } from '../../store/userSlice';
import './KYCThankyou.css'
import 'react-phone-number-input/style.css'
import {
    THANKYOU, THANKYOUPARA1, THANKYOUPARA2, MAIL, THANKYOUPARA11
} from "../../Constants"
import THANKYOUPIC from '../../assets/img/logo/thank-you.png';

class Register1 extends React.Component {

    cookies = new Cookies();
    isSignupFlow = this.props.location.state && this.props.location.state.signupFlow;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    processRedirect = (location) => {
        if (this.isSignupFlow) {
            this.cookies.remove('SID');
            this.cookies.remove('userType');
            this.props.clearUser();
            return {
                ...location,
                pathname: "/client"
            }
        }
        else {
            return {
                ...location,
                pathname: "/dashboard"
            }
        }
    }
    render() {
        return (
            <div className="KYCVerification2"><br />
                <section className="formsectionthankyou">
                    <div className="formcontainerthankyou">
                        <img src={THANKYOUPIC} alt="logo" />
                        <form><br />
                            <h5 className='kycheading'>{THANKYOU}</h5><br />
                            <p>{THANKYOUPARA1}<a href='/'><u>{MAIL}</u></a>{THANKYOUPARA11}</p>
                            <p>{THANKYOUPARA2}</p>
                        </form>
                        <Link to={this.processRedirect}>
                            {this.isSignupFlow ? "Login" : "Go Back"} to Dashboard?
                        </Link>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.info
})

const mapDispatchToProps = () => ({
    clearUser
});

export default connect(mapStateToProps, mapDispatchToProps)(Register1);