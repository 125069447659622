import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import ContactsIcon from '@mui/icons-material/Contacts';
import VerifiedIcon from '@mui/icons-material/Verified';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import RegisterEmail from '../../components/Forms/RegisterEmail';
import Footer from '../../components/Layout/Footer';
import { setUser } from '../../store/userSlice';
import { fetchUser } from '../../utils/Helpers';
import './Register.css';
import RegisterPersonal from '../../components/Forms/RegisterPersonal';
import RegisterVerifyOTP from '../../components/Forms/RegisterVerifyOTP';
import RegisterAddress from '../../components/Forms/RegisterAddress';
import KYCConsent from '../../components/Forms/KYCConsent';

const Register = (props) => {

    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        {label: 'Email Registration', icon: <EmailIcon />},
        {label: 'Personal Details', icon: <PersonIcon />},
        {label: 'Verify Phone Number', icon: <PhoneIphoneIcon />},
        {label: 'Address Details', icon: <ContactsIcon />},
        {label: 'KYC Verification', icon: <VerifiedIcon />},
    ]
    const user = useSelector(state => state.user.info);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!user || !user.email) {
            updateUser();
        }
    }, [user]);

    const onStepComplete = (data) => {
        console.log({...user, ...data});
        dispatch(setUser({...user, ...data}));
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    const updateUser = async () => {
        const userData = await fetchUser();
        if (userData) {
            dispatch(setUser(userData));
        }
    }

    return (
        <div className="RegisterPage">
            <section className="LoginHeader">
                Client Registration Form
            </section>
            <br />
            
            <section>
                <div className="container">
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map(({label, icon}, index) => {
                            return (
                                <Step key={index}>
                                    <StepLabel>{icon} {label}</StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                </div>
            </section>
            <section className="formsection">
                <div className="formcontainer">
                    {activeStep === 0 && <RegisterEmail onComplete={onStepComplete} user={user} />}
                    {activeStep === 1 && <RegisterPersonal onComplete={onStepComplete} user={user} />}
                    {activeStep === 2 && <RegisterVerifyOTP onComplete={onStepComplete} user={user} />}
                    {activeStep === 3 && <RegisterAddress onComplete={onStepComplete} user={user} />}
                    {activeStep === 4 && <KYCConsent onComplete={onStepComplete} user={user} />}
                </div>
            </section>
            <section className="footer">
                <Footer />
            </section>
        </div>
    )
}

export default Register;