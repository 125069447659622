import Page from '../../components/Page';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import MaterialTable from '@material-table/core';
import './AdminWallets.css';
import requests from '../../utils/requests';
import apiRequest from '../../services';
import { AdminMainLayout } from '../../components/Layout';
import { Link } from 'react-router-dom';
import tableIcons from '../../components/MaterialTableIcons';
import { HOME, WALLETS, WALLET } from "../../Constants"

const columnsRaw = [
    { title: "Client ID", field: "memberid", type: "numeric" },
    { title: "Client Name", field: "clientname", type: "string" },
    { title: "Email", field: "email", type: "string" },
    { title: "Phone", field: "phone", type: "numeric" },
    { title: "Registered Date", field: "registereddate", type: "date" },
    { title: "Reserve Balance", field: "reservebalance", type: "numeric" },
    { title: "Withdrawable Balance", field: "withdrawablebalance", type: "numeric" },
    { title: "Email Verification", field: "emailverification", type: "string" },
];

const columns = columnsRaw.map(column => {
    return { ...column };
});

function AdminDashboardPage() {

    const [allUsers, setAllUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchBy, setSearchBy] = useState("email")
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        getReportDetails();
    }, []);

    const mapUserData = (data) => {
        return data.map((user) => {
            const isKycSubmitted = !!user.kycInfo && !!user.kycInfo[0] && !!user.kycInfo[0].idPath && !!user.kycInfo[0].addressPath && !!user.kycInfo[0].selfiePath;
            const isKycApproved = user.idProofAccept && user.addressProof && user.selfieProofAccept;
            return {
                ...user,
                memberid: user.memberId || 'NIL',
                clientname: user.firstName ? `${user.firstName} ${user.lastName}` : "NULL",
                email: user.email,
                phone: user.phoneNumber ? user.phoneNumber : 'NULL',
                registereddate: moment(user.createAt).format('MM-DD-YYYY'),
                kycstatus: isKycSubmitted ? (isKycApproved ? 'Approved' : 'Pending') : 'Incomplete',
                reservebalance: user.totalTokens ? user.totalTokens.toFixed(2) : 0,
                withdrawablebalance: user.withdrawTokens ? user.withdrawTokens.toFixed(2) : 0,
                emailverification: user.isEmailVerified ? 'Active' : 'Pending',
                action:
                    <span className='actionicons'>
                        <i className="fa fa-check-circle greenicon" title="Deactivate this client"></i>
                        <i className="fa fa-eye orangeicon" title="View Client"></i>
                        <i className="fa fa-edit blueicon" title="Edit Client"></i>
                        <i className="fa fa-trash redicon" title="Delete Client"></i>
                    </span>
            }
        });
    }

    const getReportDetails = async (query) => {
        setLoading(true);
        if (allUsers.length) {
            console.info("Data locally available");
            setUsers(allUsers);
            setLoading(false);
            return;
        }
        const response = await apiRequest({
            method: 'GET',
            url: requests.allUserList
        });
        if (response.success && response.data) {
            const data = mapUserData(response.data.users);
            setUsers(data);
            setAllUsers(data);
        } else {
            setUsers([]);
        }
        setLoading(false);
    }
    function updateshow(e){
        e.preventDefault();
        searchuser();
    }
    
    const searchChangeHandler = (e) =>{
        setSearch(e.target.value);
    }
    const searchuser = async (query) => {
        if (search === "") {
            getReportDetails();
            return;
        }
        setLoading(true);
        let payload = {
            searchField: {}
        };
        payload.searchField = { [searchBy]: search }
        console.log(payload)
        const response = await apiRequest({
            method: 'POST',
            url: requests.searchuser,
            data: payload
        });
        if (!response.data.error) {
            const dataRoot = !!response.data.users ? response.data.users : response.data;
            const data = mapUserData(dataRoot);
            setUsers(data);
        } else {
            setUsers([]);
        }
        setLoading(false);
    }    

    const handleFilterChange = (e) => {
        setSearchBy(e.target.value);
    }

    return (
        <AdminMainLayout>
            <Page className="adminwalletpage">
                <h6 className="pageheader"><span> <i className="fa fa-home pageheader"></i><Link to="/admindashboard" >{HOME} </Link>{'>'}{WALLETS} </span> </h6>
                <h5 className="transactionheading">{WALLET}</h5>
                <form className="d-flex justify-content-end" name="clientFilter" id="clientFilter" onSubmit={updateshow}>
                    <div className="d-flex align-items-center mx-3">Search By:</div>
                    <div className="d-flex align-items-center radio-wrapper">
                        <input 
                            className="radio-button"
                            type="radio" 
                            name="searchBy" 
                            id="email" 
                            value="email" 
                            defaultChecked
                            onChange={handleFilterChange}
                        />
                        Email
                    </div>
                    <div className="d-flex align-items-center radio-wrapper mx-2">
                        <input 
                            className="radio-button"
                            type="radio" 
                            name="searchBy" 
                            id="memberId" 
                            value="memberId" 
                            onChange={handleFilterChange}
                        />
                        Client ID
                    </div>
                    <div className="d-flex align-items-center radio-wrapper mx-2">
                        <input 
                            className="radio-button"
                            type="radio" 
                            name="searchBy" 
                            id="firstName" 
                            value="firstName" 
                            onChange={handleFilterChange}
                        />
                        First Name
                    </div>
                    <div className="d-flex align-items-center radio-wrapper mx-2">
                        <input 
                            className="radio-button"
                            type="radio" 
                            name="searchBy" 
                            id="lastName" 
                            value="lastName" 
                            onChange={handleFilterChange}
                        />
                        Last Name
                    </div>
                    <div className="d-flex align-items-center radio-wrapper mx-2">
                        <input 
                            className="radio-button"
                            type="radio" 
                            name="searchBy" 
                            id="phoneNumber" 
                            value="phoneNumber" 
                            onChange={handleFilterChange}
                        />
                        Phone Number
                    </div>
                    <div className='search_Bar'>
                        <input type="text" onChange={searchChangeHandler} placeholder="Search" />
                    </div>
                </form>
                <div className="tab_client">
                    <MaterialTable isLoading={loading} style={{ minWidth: '100%', maxWidth: '100%' }} className='tablecontent' title="CLIENT LIST" icons={tableIcons} columns={columns}
                        options={{
                            sorting: true, search: true, toolbar: false, pageSize: 10, rowStyle: {
                                fontSize: 12,
                            }
                        }}
                        data={users}>
                    
                    </MaterialTable>
                </div>
            </Page>
        </AdminMainLayout>
    );
}
export default AdminDashboardPage;
