import logo from "../../assets/logo/logo.png";
import sidebarBgImage from '../../assets/img/sidebar/sidebar-4.jpg';
import React from 'react';
import {
  MdHome,
  MdRepeat,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdPersonPin,
  MdFolder,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  Collapse,
  Nav,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import PlaceholderImg from '../../assets/img/profile-pic.jpg';
import "./Sidebar.css";
import { connect } from "react-redux";


const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navComponents = [
  { to: '/profiledetails', name: 'Profile', exact: false, Icon: MdKeyboardArrowRight },
  //{ to: '/accounts', name: 'Account Info', exact: false, Icon: MdKeyboardArrowRight },
  { to: '/passwords', name: 'Change Password', exact: false, Icon: MdKeyboardArrowRight },
];

const navItems = [
  { to: '/dashboard', name: 'dashboard', exact: true, Icon: MdHome },
  { to: '/transactions', name: 'transactions', exact: false, Icon: MdRepeat },
  { to: '/wallets', name: 'My Wallets', exact: false, Icon: MdFolder },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
        <div className="top-section">
        <div className="logo">
          <img src={logo} alt="webscript" />
        </div>
        <div>
          <h6 className="mb-0" style={{color:"white"}} > Maxi Trading</h6>
        </div>
      </div>

      <Nav vertical>
            <div className="clientName">
        <div className="avatar">
          <img src={(this.props.user && this.props.user.profilePhoto) || PlaceholderImg} alt="userGirl" />
        </div>
        <div className="user-info">
          <h5>
            {
              this.props.user && this.props.user.firstName
              ? [this.props.user.firstName, this.props.user.lastName].join(' ')
              : "USER"
            }
          </h5>
        </div>
      </div>
            {!this.props.noSidebar && navItems.map(({ to, name, exact, Icon }, index) => (

              <NavItem key={index} className={bem.e('nav-item')}>

                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-capitalize"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            {!this.props.noSidebar && <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Components')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdPersonPin className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">PROFILE</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenComponents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>}
            <Collapse isOpen={this.state.isOpenComponents}>
              {!this.props.noSidebar && navComponents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>


          </Nav>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info
});

export default connect(mapStateToProps)(Sidebar);
