import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import moment from 'moment';
import './AdminLogin.css'
import Page from '../../components/Page';
import LOGO from '../../assets/img/logo/logo_200.png';
import { REGISTEREDMAIL, REGISTEREDPWD, FORGOTPWD, SIGNIN } from "../../Constants"
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import { setUser } from '../../store/userSlice';

const Login = (props) => {
  
  const cookies = new Cookies();
  const sessionId = cookies.get('SID');
  const userType = cookies.get('userType');
  const user = useSelector(state => state.user.info);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (sessionId && userType === 'admin' && !user) {
      fetchAdminUser();
    }
    if (sessionId && user && userType === 'admin') {
      props.history.push('/admindashboard');
    }
  }, [user]);
  
  const [data, setData] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchAdminUser = async () => {
    const response = await apiRequest({
      method: 'GET',
      url: requests.admin
    });
    console.log(response);
    if (response.success && response.data) {
      dispatch(setUser(response.data[0]));
    }
  }

  const onLogin = async (event) => {
    event.preventDefault()
    if (!data.email && !data.password) {
      return;
    }
    const response = await apiRequest({
      method: 'POST',
      url: requests.adminLogin,
      data
    });

    if (response.success && response.data && response.data.token) {
      const date = new Date();
      date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
      const welcomeMsg = `${response.data.profile.firstName} ${response.data.message}`;
      notify(welcomeMsg, 'success');
      cookies.set('SID', response.data.token, {expires: date});
      cookies.set('userType', 'admin', {expires: date});
      console.log(response.data.profile);
      dispatch(setUser({
        ...response.data.profile,
        lastLoggedInTime: moment.utc(
            response.data.profile.lastLoggedInTime,
            [moment.HTML5_FMT.DATETIME_LOCAL_MS, 'dddd, MMMM Do YYYY, hh:mm:ss a']
          )
          .local()
          .format("LLLL")
      }));
      props.history.push('/admindashboard')
    } else {
      notify(response.message || response.data.message || 'Password Mismatch');
    }
  }

  const inputChangeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  }

  return (
    <Page className="LoginPage">
      <div className="logincontainer">
        <div className="loginform">
          <form onSubmit={onLogin} action="/" method="post" className="container">
            <div className="loginimg">
              <img src={LOGO} alt="logo" />
            </div>
            <br />
            <div>
              <label className='labels' htmlFor="username">{REGISTEREDMAIL}</label>
              <input 
                type="text" 
                placeholder="Please enter your registered email id" 
                name="email" 
                required
                onChange={inputChangeHandler} 
              />
              <label className='labels' htmlFor="password">{REGISTEREDPWD}</label>
              <input 
                type="password" 
                placeholder="Please enter your password" 
                name="password" 
                required 
                onChange={inputChangeHandler} 
              />
              <br />
              <div > 
                <span className="password"><a className='forgotpwd' href="/#/forgotpassword?utype=admin">{FORGOTPWD}</a></span>
              </div>
              <br /> <br />
              <button type="submit">{SIGNIN}</button>
            </div>
          </form>
        </div>
      </div>
    </Page>
  )
}

export default Login


