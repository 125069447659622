import React from 'react';
import { useSelector } from 'react-redux';
import './AdminDashboard.css';
import DashboardCard from '../../components/DashboardCard/DashboardCard'
import { Row, Col } from "react-bootstrap";
import signedup from "../../assets/dashboard_icons/signedup.png";
import approved from "../../assets/dashboard_icons/approved.png";
import idproof from "../../assets/dashboard_icons/idproof.png";
import selfie from "../../assets/dashboard_icons/selfie.png";
import addressproof from "../../assets/dashboard_icons/addressproof.png";
import { AdminMainLayout } from '../../components/Layout';
import MaterialTable from "@material-table/core";
import tableIcons from "./MaterialTableIcons";
import { useState, useEffect } from 'react';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import moment from 'moment';

const columnsRaw = [
  { title: "Client ID", field: "memberid", type: "number" },
  { title: "Client Name", field: "clientname", type: "text" },
  { title: "Email", field: "email", type: "email" },
  { title: "Phone", field: "phone", type: "number" },
  { title: "Registered Date", field: "registereddate", type: "date" },
  { title: "KYC Status", field: "kycstatus", type: "text" },
  { title: "Reserve Balance", field: "reservebalance", type: "numeric" },
  { title: "Withdrawable Balance", field: "withdrawablebalance", type: "numeric" },
];

const columns = columnsRaw.map(column => {
  return {
    ...column
  }
});

function AdminDashboardPage(props) {

  let [clientStats, setClientStats] = useState({
    totalUsers: 0,
    approvedUsers: 0,
    addressProofRejected: 0,
    addressProofUploaded: 0,
    idProofRejected: 0,
    idProofUploaded: 0,
    selfieProofRejected: 0,
    selfieProofUploaded: 0
  });
  const [users, setUsers] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const loggedinUser = useSelector(state => state.user.info);
  const cardInfo = {
    addressProofRejected: {title: 'Address Proof Rejected', image: addressproof, color: `#FF003D`},
    addressProofUploaded: {title: 'Address Proof Uploaded', image: addressproof, color: `#2596BE`},
    idProofRejected: {title: 'ID Proof Rejected', image: idproof, color: `#FF003D`},
    idProofUploaded: {title: 'ID Proof Uploaded', image: idproof, color: `#2596BE`},
    selfieProofRejected: {title: 'Selfie Proof Rejected', image: selfie, color: `#FF003D`},
    selfieProofUploaded: {title: 'Selfie Proof Uploaded', image: selfie, color: `#2596BE`},
    approvedUsers: {title: 'Clients Approved', image: approved, color: `#7BC542`},
    totalUsers: {title: 'Clients Signed Up', image: signedup, color: `#1269DB`}
  }
  
  const getClientStats = async () => {
    const response = await apiRequest({
      method: 'POST',
      url: requests.registrationdetails,
      data: {}
    });
    if (response.success && response.data && response.data) {
      setClientStats(prevStats => ({
        ...prevStats,
        addressProofRejected: response.data.addressProofRejectCount,
        addressProofUploaded: response.data.addresscount,
        approvedUsers: response.data.approvedUsers,
        idProofRejected: response.data.idProofRejectCount,
        idProofUploaded: response.data.idcount,
        selfieProofRejected: response.data.selfieProofRejectCount,
        selfieProofUploaded: response.data.selfiecount
      }));
    }
  }

  const getAllUserDetails = async (query) => {
    setTableLoading(true);
    const response = await apiRequest({
        method: 'GET',
        url: requests.getAllClients
    });
    if (response.success && response.data) {
      console.log(response.data.users[0].kycInfo)
      setClientStats(prevStats => ({
        ...prevStats,
        totalUsers: response.data.users.length
      }));
      let data = response.data.users.map((user) => {
          const isKycSubmitted = !!user.kycInfo[0] && !!user.kycInfo[0].idPath && !!user.kycInfo[0].addressPath && !!user.kycInfo[0].selfiePath;
          const isKycApproved =  user.idProofAccept && user.addressProof && user.selfieProofAccept;
          return {
              ...user,
              memberid: user.memberId,
              clientname: user.firstName ? `${user.firstName} ${user.lastName}` : 'NULL',
              email: user.email,
              phone: user.phoneNumber ? user.phoneNumber : 'NULL',
              registereddate: moment(user.createAt).format('MM-DD-YYYY'),
              kycstatus: isKycSubmitted ? (isKycApproved ? 'Approved' : 'Pending') : 'Incomplete',
              reservebalance: user.totalTokens,
              withdrawablebalance: user.withdrawTokens,
              action: null
          }
      })
      setUsers(data);
      setTableLoading(false);
    }
}

  useEffect(() => {
    getAllUserDetails();
    getClientStats();
  }, [])
  

  return (
    <AdminMainLayout>
     <div>
      <div>
        <div className="welcome_msg">
          <h5 style={{ color: "white" }}>
            <b>Hi {loggedinUser && loggedinUser.firstName}, Welcome back!</b>
          </h5>
          <p>Last login time: {loggedinUser && loggedinUser.lastLoggedInTime}</p>
        </div>
        <div className="mt-2">
          <Row className="m-1">
            {
              Object.keys(clientStats).map(key => {
                return <DashboardCard 
                  lgColumns={4} 
                  mdColumns={2} 
                  key={key} 
                  value={clientStats[key]}
                  { ...cardInfo[key] }
                />
              })
            }
          </Row>
        </div>
  
        <Row className="justify-content-md-center align-items-start m-1">
          <Col xs lg="12">
            <Row>
              <Col>
                <div className="title_msg">
                  <h6>
                    <b>Client list</b>
                  </h6>
                  <p>Please find the list of registered clients.</p>
                </div>
              </Col>
            </Row>
            <div className="clientlist mt-2">
                  <div className="table_clientlist">
                  <MaterialTable className='tablecontent'
                        icons={tableIcons} columns={columns} isLoading={tableLoading}
                        options={{
                            sorting: true, search: false, toolbar: false, pageSize: 10, rowStyle: {
                                fontSize: 12,
                            }
                        }}
                        data={users} />
                  </div>
                </div>
          </Col>
        </Row>
      </div>
    </div>
    </AdminMainLayout>
  );
}
export default AdminDashboardPage;
