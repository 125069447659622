import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { Alert } from '@mui/material';
import Cookies from 'universal-cookie';
import { setUser, clearUser } from '../../store/userSlice';
import { fetchUser } from '../../utils/Helpers';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';

const ClientDeclaration = (props) => {

    const cookies = new Cookies();
    const dispatch = useDispatch();
    const [showDisagreeModal, setShowDisagreeModal] = useState(false);

    const handleAcknowledge = async () => {
        const request = {
            method: 'POST',
            url: requests.updateuserinfo,
            data: {
                isAgreed: true
            }
        }
        const response = await apiRequest(request);
        if (response.success) {
            notify("Thank you for your response!", "success");
            const user = await fetchUser();
            dispatch(setUser(user));
        }
    }

    const handleDisagree = () => {
        setShowDisagreeModal(true);
    }

    const handleCloseDisagreeModal = () => {
        setShowDisagreeModal(false);
    }

    const logout = () => {
        cookies.remove('SID');
        cookies.remove('userType');
        dispatch(clearUser());
        window.location = "/#/client";
        return;
    }

    return (
        <React.Fragment>
            <Modal show={props.show} centered>
                <Modal.Header>
                    <h3>Declaration</h3>
                </Modal.Header>
                <Modal.Body>
                    <Alert severity="info">Please read and acknowledge the below declaration statement before continuing to access your account.</Alert>
                    <div className="m-3">
                        <p>I hereby agree that the balance of my wallet under my account is accurate and I have no dispute with it whatsoever.</p>
                        <p>I hereby also agree to indemnify the Company against all costs, claims, losses and/or legal liabilities should there be any discrepancies with this amount now, or at any time in the future.</p>
                        <p>I agree to abide by all Terms and Conditions of MaxiTrading Invest OÜ</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <Button variant="outline-danger" onClick={handleDisagree}>I Disagree</Button>
                        <Button onClick={handleAcknowledge}>I Agree</Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal 
                show={showDisagreeModal}
                size="lg"
                onHide={handleCloseDisagreeModal} 
                backdrop="static"
                centered
            >
                <Modal.Header>Are you sure?</Modal.Header>
                <Modal.Body>
                    <Alert variant='outlined' severity='warning'>
                        If you disagree, you will be logged out of the session.
                        Do you want to continue?
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={logout}>Yes, Log Me Out</Button>
                    <Button variant='secondary' onClick={handleCloseDisagreeModal}>Go Back</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ClientDeclaration;