import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import { Modal, Spinner } from "react-bootstrap";
import apiRequest from "../../services";
import requests from "../../utils/requests";
import { notify } from "../../utils/handler";

const ConfirmClientWithdrawal = (props) => {

    const [confirmStatus, setConfirmStatus] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return () => {
            setConfirmStatus(false);
        }
    }, []);

    const withdrawrequest =async()=>{
        setLoading(true);
        const request = {
          method: 'POST',
          url: requests.withdrawReq,
          data: {
            transactionAddress: props.address,
            amount: props.amount
          }
        };
        const response = await apiRequest(request);
        if (response.success && response.data) {
          setConfirmStatus(true);
        } else {
          notify((response.data && response.data.message) || 'something went wrong!')
        }
        setLoading(false);
    }

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
        >
            <Modal.Header>{props.action} This Client?</Modal.Header>
            <Modal.Body>
                {!confirmStatus ?
                <React.Fragment>
                    <Alert severity="warning">
                        You are about to withdraw {props.amount} Maxi tokens to the below wallet address.
                    </Alert>
                    <pre className="text-center my-3 bg-gray">{props.address}</pre>
                    <div className="d-flex justify-content-end my-3">
                        {loading && <Spinner animation="grow" />}
                        <button className="btn btn-primary mx-2" onClick={withdrawrequest}>Confirm</button>
                        <button className="btn btn-secondary" onClick={props.handleClose}>Go Back</button>
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <p>
                        Your withdrawal has been submitted. 
                        Please confirm by clicking on the verification link in your email address.
                    </p>
                    <p>All withdrawals take 1-2 business days to be processed.</p>
                    <div className="d-flex justify-content-end my-3">
                        <button className="btn btn-primary mx-2" onClick={props.handleClose}>OK</button>
                    </div>
                </React.Fragment>
                }
            </Modal.Body>
        </Modal>
    );
}

export default ConfirmClientWithdrawal;