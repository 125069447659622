import Page from '../../components/Page';
import React from 'react';
import WithdrawalsTable from '../../components/Tables/WithdrawalsTable';
import { AdminMainLayout } from '../../components/Layout';
import { Link } from 'react-router-dom';
import { HOME } from "../../Constants";

function AdminWithdrawRequests() {
    return (
        <AdminMainLayout>
            <Page className="adminwithdrawrequestspage">
                <h6 className="pageheader"><span> <i className="fa fa-home pageheader"></i><Link to="/admindashboard" >{HOME} </Link>{'>'} Withdrawal Requests </span> </h6>
                <div className="transactionheading">
                    <h5 className="text-light">Withdrawals for Approval</h5>
                    <p className="text-light">Withdrawals awaiting Admin action</p>
                </div>
                <WithdrawalsTable filters={{status: 'initiated'}} />
                <div className="transactionheading">
                    <h5 className="text-light">Upcoming Withdrawals</h5>
                    <p className="text-light">Withdrawals awaiting client confirmation</p>
                </div>
                <WithdrawalsTable filters={{status: 'pending'}} />
            </Page>
        </AdminMainLayout>
    );
}
export default AdminWithdrawRequests;
