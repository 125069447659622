import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Alert } from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import { fetchUser } from '../../utils/Helpers';
import { setUser } from '../../store/userSlice';

const ChangePhone = (props) => {

    const user = useSelector(state => state.user.info);
    const dispatch = useDispatch();
    const [otpTriggered, setOtpTriggered] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [counter, setCounter] = useState(null);

    useEffect(() => {
        if (counter) {
            counter > 0 && setTimeout(() => {
            setCounter(prevCounter => prevCounter - 1);
            }, 1000);
        }
    }, [counter]);

    if (!user) return null;

    const handlePhoneChange = (value) => {
        setPhoneNumber(value);
    }

    const handleOtpChange = (e) => {
        const target = e.target;
        setOtp(target.value);
    }

    const handlePhoneSubmit = async (e) => {
        e.preventDefault();
        if (phoneNumber === user.phoneNumber) {
            notify("You are already registered with this phone number.");
            return;
        }
        const response = await apiRequest({
            method: 'POST',
            url: requests.updateuserphoneotp,
            data: {
                phoneNumber: phoneNumber            }
        });
        if (response.success && response.data) {
            notify(response.data.message, "success");
            setOtpTriggered(true);
            setCounter(60);
        } else {
            notify((response.data && response.data.message) || 'something went wrong!')
        }
    }

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        const response = await apiRequest({
            method: 'POST',
            url: requests.updateuserphone,
            data: {
                phoneNumber: phoneNumber,
                otp: otp
            }
        });
        console.log(response);
        if (response.success) {
            notify(response.message || response.data.message, "success");
            let userData = await fetchUser();
            dispatch(setUser(userData));
            setOtpTriggered(false);
            setOtp('');
            props.handleClose();
        } else {
            notify(response.message || response.data.message || 'Registration failed!');
            setOtp('');
            setOtpTriggered(false);
            setPhoneNumber(false);
        }
        setCounter(null);
    }

    const resendOTP = async (e) => {
        e.preventDefault();
        const response = await apiRequest({
          method: 'POST',
          url: requests.resendOtp,
          data: {
              email: user.email,
              phoneNumber: phoneNumber
          }
        });
        if (response.success) {
          notify("A fresh OTP has been sent to your new mobile number", "success");
        }
        else {
          notify(response.data.message);
        }
      }

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
        >
            <Modal.Header>
                Change Phone Number
            </Modal.Header>
            <Modal.Body>
                {!otpTriggered ? 
                    <div>
                        <p>
                            Your phone number associated with this account is {user.phoneNumber}.
                            Please enter the new number in the text box below.
                        </p>
                        <Alert severity="info">
                        Your new phone number will be verified with an OTP upon submitting it.
                        </Alert>
                        <form onSubmit={handlePhoneSubmit}>
                            <div className="d-flex align-items-baseline justify-content-between my-2">
                                <PhoneInput 
                                    className="flex-grow-1 mx-2"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={phoneNumber} 
                                    onChange={handlePhoneChange} 
                                    type="text"
                                    placeholder="Enter your phone number"
                                />
                                <button type="submit" className="submit_button" >GET OTP</button>
                            </div>
                        </form>
                    </div>
                    :
                    <form onSubmit={handleOtpSubmit}>
                        <p className='otpdescription'>
                            Please verify your request with the 6 digit OTP you have received on your phone number.
                        </p>
                        <div className="d-flex align-items-baseline">
                            <label className="label w-25" htmlFor="number">Enter OTP:</label>
                            <input 
                                className="register1_formfield" 
                                type="number" 
                                id="otp" 
                                name="otp"
                                value={otp} 
                                onChange={handleOtpChange}
                                placeholder="Please enter the OTP" required />
                        </div>
                        <div className="d-flex justify-content-end align-items-center my-3">
                            {counter !== null && (counter > 0 ?
                                <p className="text-info mx-3">Resend OTP in {counter} seconds</p>
                                :
                                <button className="mx-3 btn btn-link" onClick={resendOTP}>Resend OTP</button>)
                            }
                            <button 
                                className="submit_button" 
                                type="submit" 
                                value="Submit" 
                            >
                                Verify
                            </button>
                        </div>
                    </form>
                }
            </Modal.Body>
        </Modal>
    );
}

export default ChangePhone;