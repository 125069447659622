import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import validator from 'validator';
import './AdminPassword.css'
import { Link } from 'react-router-dom'
import { AdminMainLayout } from '../../components/Layout';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import Page from '../../components/Page';
import { HOME, CHANGEPASSWORD, CHANGEYOURPWD, CURRENTPWD, NEWPWD, DESCRIPTION, SUBMIT } from "../../Constants"

function AdminDashboardPage() {

    const userData = useSelector(state => state.user.info);
    const [data, setData] = useState({
        currentPassword: '',
        newPassword: '',
        newConfirmPassword: '',
        showPassword: false,
        showCurrentPassword: false
    });

    const handleClickShowPassword = () => {
        setData({ ...data, showPassword: !data.showPassword });
    };

    const handleClickShowCurrentPassword = () => {
        setData({ ...data, showCurrentPassword: !data.showCurrentPassword });
    };

    const handlePasswordChange = (event) => {
        setData({ ...data, [event.target.id]: event.target.value });
    };

    const changepassword = async (event) => {
        event.preventDefault()
        if (data.newPassword !== data.newConfirmPassword) 
            return notify("Passwords don't match. Please try again.");
        if (!userData) return notify("Failed to fetch your profile details. Please try again.");
        if (!validator.isStrongPassword(data.newPassword, {
            minSymbols: 0
        })) {
            return notify("Please choose a strong password.");
        }
        const response = await apiRequest({
            method: 'POST',
            url: requests.adminchangepassword,
            data: {
                email: userData.email,
                currentPassword: data.currentPassword,
                newPassword: data.newPassword
            }
        });
        if (response.success && response.data && response.data) {
            notify((response.data && response.data.message) || 'Password updated Successfully!', 'success')
        } else {
            notify((response.data && response.data.message) || 'something went wrong!')
        }
    }

    return (
        <AdminMainLayout>
            <Page className="PwdPage" >
                <h6 className="pageheader"> <span><i className="fa fa-home pageheader"></i><Link to="/admindashboard" >{HOME} </Link>{'>'} {CHANGEPASSWORD}</span></h6>
                <div className="password_change">
                    <div className="pass_header">
                        <h5 className="password_header">{CHANGEYOURPWD}</h5>
                        <form>
                            <div className="password_body">
                                <p>{CURRENTPWD}</p>
                                <Input 
                                    className="admin_formpassword" 
                                    id="currentPassword"
                                    type={data.showCurrentPassword ? "text" : "password"} 
                                    placeholder="Please enter your current password" 
                                    value={data.currentPassword} 
                                    required 
                                    onChange={handlePasswordChange} 
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowCurrentPassword}
                                            >
                                                {data.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                /><br />
                                <p>{NEWPWD}</p>
                                <Input 
                                    className="admin_formpassword"
                                    id="newPassword"
                                    type={data.showPassword ? "text" : "password"} 
                                    placeholder="Please enter your new password" 
                                    value={data.newPassword}  
                                    required 
                                    onChange={handlePasswordChange} /><br />
                                <p>Confirm new password</p>
                                <Input
                                    className="admin_formpassword"
                                    id="newConfirmPassword"
                                    placeholder="Please enter your new password to confirm"
                                    type={data.showPassword ? "text" : "password"}
                                    onChange={handlePasswordChange}
                                    value={data.newConfirmPassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                            >
                                                {data.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    } required
                                />
                                <br />
                                <p>{DESCRIPTION}</p>
                                <button type="submit" onClick={changepassword} className="submit_button" >{SUBMIT}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Page>
        </AdminMainLayout>
    );
}
export default AdminDashboardPage;
