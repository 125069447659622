import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';
import { triggerOTP } from '../../utils/Helpers';
import ChangePhone from './ChangePhone';

const Update2FA = props => {

  const user = useSelector(state => state.user.info);
  const [otpInput, setOtpInput] = useState('');
  const [actionConfirmed, setActionConfirmed] = useState(false);
  const [phoneModalOpen, setPhoneModalOpen] = useState(false);
  const [counter, setCounter] = useState(null);

  useEffect(() => {
    console.log(counter);
    if (counter) {
      counter > 0 && setTimeout(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);
    }
  }, [counter]);

  if (!props.data || !user) {
    return null;
  }

  const actionLabel = user.is2FactorAuthEnabled ? "Disable" : "Enable";
  
  const handleSubmit = async e => {
    e.preventDefault();
    const request = {
      method: 'POST',
      url: requests.verifyotp,
      data: {
        email: user.email,
        phoneNumber: user.phoneNumber,
        otp: otpInput,
      },
    };
    const response = await apiRequest(request);
    if (response.success) {
      notify('OTP verified successfully', 'success');
      props.otpSuccessCallback(response);
      setActionConfirmed(false);
    } else {
      notify(response.data.message);
    }
    setCounter(null);
    props.handleClose();
  };

  const resendOTP = async () => {
    const response = await apiRequest({
      method: 'POST',
      url: requests.resendOtp,
      data: user
    });
    if (response.success) {
      notify("A fresh OTP has been sent to your registered mobile number", "success");
    }
    else {
      notify(response.data.message);
    }
  }

  const handleOtpConsent = async () => {
    setActionConfirmed(true);
    const otpTriggered = await triggerOTP(user);
    if (!otpTriggered) {
      setActionConfirmed(false);
    }
    else {
      setCounter(60);
    }
  }

  const handleChange = e => {
    setOtpInput(e.target.value);
  };

  const handleCancel = () => {
    props.handleClose();
    setActionConfirmed(false);
    setCounter(null);
  }

  return (
    <div>
      <Modal size="md" show={props.show} onHide={handleCancel} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Two-Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!actionConfirmed ?
          (<div className="container">
            <Alert severity={user.is2FactorAuthEnabled ? "warning" : "info"}>
              You are about to&nbsp;
              <b>{actionLabel.toLowerCase()}</b> 
              &nbsp;Two-Factor Authentication for your account. 
            </Alert>
            <div className="py-3">
              <p>
                If you continue, your phone number will be verified with a OTP.
                The OTP will be sent to {user.phoneNumber}
              </p>
              <div className="d-flex justify-content-end">
                <button className="btn btn-primary mx-3" onClick={handleOtpConsent}>{actionLabel} 2FA</button>
                <button className="btn btn-primary-outline" onClick={() => setPhoneModalOpen(true)}>Change Phone Number</button>
              </div>
            </div>
          </div>)
          :
          (<form className="container py-3" onSubmit={handleSubmit}>
            <p>
              Please verify your request with the OTP you received on your phone.
            </p>
            <div className="form-group d-flex justify-content-end mt-3">
              <input
                type="text"
                name="otp"
                placeholder="Enter your OTP here"
                onChange={handleChange}
              />
            </div>
            <div className="form-group d-flex justify-content-end align-items-baseline mt-3">
              {counter !== null && (counter > 0 ?
                <p className="text-info mx-3">Resend OTP in {counter} seconds</p>
                :
                <Button variant="link" className="mx-3" onClick={resendOTP}>Resend OTP</Button>)
              }
              <Button variant="primary" type="submit">
                Verify
              </Button>
            </div>
          </form>)}
        </Modal.Body>
      </Modal>
      <ChangePhone show={phoneModalOpen} handleClose={() => {setPhoneModalOpen(false)}} />
    </div>
  );
};

export default Update2FA;
