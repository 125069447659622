import React from 'react';
import { Modal } from 'react-bootstrap';
import { Alert } from '@mui/material';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import { notify } from '../../utils/handler';

const ConfirmAdminAction = (props) => {

    const deleteClient = async (userId) => {
        const request = {
            method: 'POST',
            url: requests.deleteUser,
            data: {
                userId: userId
            }
        };
        const response = await apiRequest(request);
        if (response.success) {
            notify("User successfully deleted", "error", "dark");
            props.onDeleteSuccess();
        }
        else {
            notify(response.data.message);
        }
    }

    const enableOrDisableClient = async (userId, userEnabled) => {
        const request = {
            method: 'POST',
            url: requests.enableOrDisableUser,
            data: {
                userId: userId,
                flag: !userEnabled
            }
        }
        const response = await apiRequest(request);
        if (response.success) {
            const toastType = !userEnabled ? "success" : "error"
            const toastTheme = userEnabled && "dark";
            console.log(response)
            notify(`Client record has been successfully ${props.action.toLowerCase()}d`, toastType, toastTheme);
            props.handleClose();
            return;
        }
        notify(response.data.message);
    }

    const handleConfirm = async () => {
        if (props.action.toLowerCase() === "delete") {
            await deleteClient(props.user._id);
        }
        else if (props.action.toLowerCase().includes("activate")) {
            await enableOrDisableClient(props.user._id, props.user.isUserEnabled);
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
        >
            <Modal.Header>{props.action} This Client?</Modal.Header>
            <Modal.Body>
                <Alert severity="warning">You are about to {props.action.toLowerCase()} this client. Are you sure you want to continue?</Alert>
                <div className="d-flex justify-content-end my-3">
                    <button className="btn btn-primary mx-2" onClick={handleConfirm}>{props.action}</button>
                    <button className="btn btn-secondary" onClick={props.handleClose}>Go Back</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ConfirmAdminAction;