import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MaterialTable from '@material-table/core';
import { AdminMainLayout } from '../../components/Layout';
import Page from '../../components/Page';
import apiRequest from '../../services';
import requests from '../../utils/requests';
import tableIcons from "../AdminClientList/MaterialTableIcons";
import CsvDownload from "react-json-to-csv";

const columnsRaw = [
    { title: "Client ID", field: "memberid", type: "numeric" },
    { title: "Client Name", field: "clientname", type: "string" },
    { title: "Registered Date", field: "registereddate", type: "date" },
    { title: "Date of Birth", field: "dob", type: "date" },
    { title: "Address", field: "address", type: "string" },
    { title: "City", field: "city", type: "string" },
    { title: "Country", field: "country", type: "string" },
    { title: "Zip Code", field: "zipcode", type: "string" },
    { title: "ID Status", field: "idstatus", type: "string" },
    { title: "Address Status", field: "addressstatus", type: "string" },
    { title: "Selfie Status", field: "selfiestatus", type: "string" },
    { title: "Action", field: "action", type: "string" },
];

const columns = columnsRaw.map(column => {
    return { ...column };
});

const mapKYCData = (data, isSearch = false) => {
    console.log(data[0])
    const downloadData = [];
    const tableData = data.map((entry) => {
        const addressStatus = !!entry.addressPath ?
            (entry.addressProof ? 'Approved' : (entry.addressProofReject ? 'Rejected' : 'Pending')) : 'Incomplete';
        const idStatus = !!entry.idPath ?
            (entry.idProofAccept ? 'Approved' : (entry.idProofReject ? 'Rejected' : 'Pending')) : 'Incomplete';
        const selfieStatus = !!entry.selfiePath ?
            (entry.selfieProofAccept ? 'Approved' : (entry.selfieProofReject ? 'Rejected' : 'Pending')) : 'Incomplete';
        const toDownload = {
            memberid: entry.userId.memberId || 'NIL',
            clientname: entry.userId.firstName ? `${entry.userId.firstName} ${entry.userId.lastName}` : "NULL",
            registereddate: moment(isSearch ? entry.userId.createAt : entry.createAt).format('MM-DD-YYYY'),
            dob: entry.dob,
            address: entry.address,
            city: entry.city,
            country: entry.countyOfBirth,
            addressstatus: addressStatus,
            idstatus: idStatus,
            selfiestatus: selfieStatus,
            zipcode: entry.zipCode,
        }
        downloadData.push(toDownload);
        return {
            ...entry,
            ...toDownload,
             action: <span>
                <Link to={{pathname:"/kycdetail", state: {entry: entry}}}>Review</Link>
            </span>
       }
    });
    return {
        tableData: tableData,
        downloadData: downloadData
    }
}

const tableStyle = {
    width: '80%'
}

const AdminKYCView = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchBy, setSearchBy] = useState('email');
    const [downloadData, setDownloadData] = useState([]);

    const getPendingKYCList = async () => {
        setLoading(true);
        const response = await apiRequest({
            method: 'GET',
            url: requests.kycPending
        });
        if (response.success) {
            console.log(response.data);
            const kycData = mapKYCData(response.data);
            setData(kycData.tableData);
            setDownloadData(kycData.downloadData);
        }
        setLoading(false);
    }

    const handleFilterChange = (e) => {
        setSearchBy(e.target.value);
    }

    const searchChangeHandler = (e) => {
        setSearchText(e.target.value);
    }

    const searchuser = async (e) => {
        e.preventDefault();
        if (searchText === "") {
            getPendingKYCList();
            return;
        }
        setLoading(true);
        let payload = {
            searchField: {}
        };
        payload.searchField = { [searchBy]: searchText };
        try {
            const response = await apiRequest({
                method: 'POST',
                url: requests.searchKyc,
                data: payload
            });
            if (!response.data.error) {
                const dataRoot = !!response.data.users ? response.data.users : response.data;
                const data = mapKYCData(dataRoot, true);
                setData(data.tableData);
            } else {
                setData([]);
            }
        } catch(err) {
            console.log(err);
        }
        setLoading(false);
    }

    useEffect(() => {
        getPendingKYCList();
    }, []);

    return (
        <AdminMainLayout {...props}>
            <Page className="KYCListPage">
                <h6 className="pageheader"> <span><i className="fa fa-home pageheader"></i><Link to="/admindashboard" >Home </Link>{'>'} KYC Submissions</span></h6>
                <div className='report_button d-flex justify-content-start'>
                        <CsvDownload className="btn btn-success" filename="kycReport.csv" data={downloadData}> <span><i className="fa fa-download"></i> Download Full Report</span></CsvDownload>
                    </div>

                <div className='admin_clientlisttable'>
                    <form className="d-flex justify-content-end align-items-center" name="clientFilter" id="clientFilter" onSubmit={searchuser}>
                        <div className="d-flex align-items-center mx-3">Search By:</div>
                        <select name="searchBy" id="searchBy" onChange={handleFilterChange} className="mx-2 h-75">
                            <option value="email" default>Email</option>
                            <option value="memberId" default>Client ID</option>
                            <option value="firstName" default>First Name</option>
                            <option value="lastName" default>Last Name</option>
                            <option value="phoneNumber" default>Phone Number</option>
                            <option value="countyOfBirth" default>Country of Birth</option>
                        </select>
                        <div className='search_Bar'>
                            <input type="text" onChange={searchChangeHandler} placeholder="Search" />
                        </div>
                    </form>
                </div>
                <MaterialTable
                    style={tableStyle}
                    isLoading={loading}
                    icons={tableIcons} columns={columns}
                    options={{
                        sorting: true, search: true, toolbar: false, pageSize: 10, rowStyle: {
                            fontSize: 12,
                        }
                    }}
                    data={data} />
            </Page>
        </AdminMainLayout>
    )
}

export default AdminKYCView;
