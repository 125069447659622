import Avatar from '../Avatar';
import { UserCard } from '../Card';
import React from 'react';
import {
  MdExitToApp,
  MdEdit,
  MdPersonPin,
} from 'react-icons/md';
import { connect } from 'react-redux';
import {
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import Cookies from 'universal-cookie';
import bn from '../../utils/bemnames';
import { fetchUser } from '../../utils/Helpers';
import iconMenu from "../../assets/icon-menu.png";
import PlaceholderImg from "../../assets/img/profile-pic.jpg";
import { setUser, clearUser } from '../../store/userSlice';
import "./Header.css";

const bem = bn.create('header');
const cookies = new Cookies();

class Header extends React.Component {

  componentDidMount() {
    if (!this.props.user) {
      this.updateUser();
    }
  }

  updateUser = async () => {
    const userData = await fetchUser();
    if (userData) {
      this.props.setUser(userData);
    }
  }

  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  logout = async () => {
    cookies.remove('SID');
    cookies.remove('userType');
    this.props.clearUser();
    window.location = "/#/client";
    return;
  }
  goToChangePassword = () => {
    this.props.history.push('/passwords');
  }
  goToProfiledetails = (isEditProfile = false) => {
    if (this.props.location.pathname === "/profiledetails") {
      this.props.history.replace({ pathname: '/profiledetails', state: { isEditProfile } })

    } else {
      this.props.history.push({ pathname: '/profiledetails', state: { isEditProfile } })
    }
  }

  render() {
    return (
      <div>
        <Navbar light expand sticky="top">
          <Nav navbar className="mr-3"  >
            <div onClick={this.handleSidebarControlButton} className="toggle-menu-btn">
            <img src={iconMenu} alt="iconMenu" />
        </div>
          </Nav>
          
          <Nav navbar className={bem.e('nav-right')}>
            <NavItem>
              <NavLink id="Popover2">
                <Avatar
                  src={(this.props.user && this.props.user.profilePhoto) || PlaceholderImg}
                  onClick={this.toggleUserCardPopover}
                  className="can-click"
                />
              </NavLink>
              <Popover
                placement="bottom-end"
                isOpen={this.state.isOpenUserCardPopover}
                toggle={this.toggleUserCardPopover}
                target="Popover2"
                className="p-0 border-0"
                style={{ minWidth: 200 }}
              >
                <PopoverBody className="p-0 border-light">
                  <UserCard
                    title="USER"
                    className="border-light"
                    avatar={(this.props.user && this.props.user.profilePhoto) || PlaceholderImg}
                  >
                    <ListGroup flush>
                      <ListGroupItem tag="button" action className="border-light" onClick={this.goToChangePassword}>
                        <MdEdit /> Change Password
                      </ListGroupItem>
                      <ListGroupItem tag="button" action className="border-light" onClick={() => this.goToProfiledetails(false)}>
                        <MdPersonPin /> My Profile
                      </ListGroupItem>
                      <ListGroupItem tag="button" action className="border-light" onClick={this.logout}>
                        <MdExitToApp /> Sign Out
                      </ListGroupItem>
                    </ListGroup>
                  </UserCard>
                </PopoverBody>
              </Popover>
            </NavItem>
          </Nav>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info
});

const mapDispatchToProps = () => ({
  setUser,
  clearUser
})

export default connect(mapStateToProps, mapDispatchToProps())(Header);
