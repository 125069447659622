import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './Transactions.css';
import Page from '../../components/Page';
import { MainLayout } from '../../components/Layout';
import MaterialTable from "@material-table/core";
import tableIcons from "./MaterialTableIcons";
import requests from '../../utils/requests';
import apiRequest from '../../services';
import { HOME, TRANSACTIONS } from "../../Constants"

const columns = [
    { title: "Time and Date", field: "timeanddate", type: "date" },
    { title: "Client ID", field: "memberid", type: "numeric" },
    { title: "Address", field: "address", type: "string" },
    { title: "Transaction Status", field: "transactionstatus", type: "string" }
];

class TransactionsPage extends React.Component {

    state = {
        transactions: [],
        loading: false,
        kycDone: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        if (!this.state.transactions.length) {
            this.getUserTransactions();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user) {
            const kycStatus = !!this.props.user.idPath && !!this.props.user.addressPath && !!this.props.user.selfiePath;
            if (this.state.kycDone !== kycStatus) {
                this.setState(prevState => ({
                    ...prevState,
                    kycDone: kycStatus
                }));
            }
        }
    }

    componentWillUnmount() {
    }

    mapTransactionData = (data) => {
        return data.map(tx => {
            return {
                timeanddate: moment(tx.createAt).format('MM-DD-YYYY'),
                memberid: tx.userId.memberId || 'NIL',
                address: tx.transactionAddress,
                rate: tx.amount,
                transactionstatus: tx.status
            };
        })
    }

    getUserTransactions = async () => {
        this.setState(() => ({loading: true}));
        const transactions = await apiRequest({
            method: 'GET',
            url: requests.userTransactions,
            data: {}
        });
        if (transactions.success && transactions.data) {
            const tableData = this.mapTransactionData(transactions.data);
            console.log(tableData);
            this.setState(() => ({
                transactions: tableData,
                loading: false
            }));
        }
    }
    
    render() {
        return (
            <MainLayout {...this.props}>
                {
                    this.state.kycDone && this.props.user.userId.isUserEnabled ?
                    <Page className="transactionPage">
                        <h6 className="pageheader"><span> <i className="fa fa-home pageheader"></i><a href="/#/dashboard" >{HOME} </a>{'>'}{TRANSACTIONS} </span> </h6>
                        <h5 className="ethereumadd">{TRANSACTIONS}</h5>
                        <MaterialTable icons={tableIcons} data={this.state.transactions} columns={columns} isLoading={this.state.loading}
                            options={{
                                sorting: true, search: false, toolbar: false
                            }} 
                        />
                    
                    </Page>
                    :
                    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                        <h3>Access Restricted</h3>
                        <p>You either need to submit your KYC documents or your access to this section has been disabled by admin.</p>
                        <p>Please contact admin for assistance or write to us at contact@maxi.exchange</p>
                    </div>
                }
            </MainLayout>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.info
});

export default connect(mapStateToProps)(TransactionsPage);