import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Input, InputAdornment, Button } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import validator from 'validator';
import Cookies from 'universal-cookie';
import { PASSWORDDESCRIPTION } from "../../Constants";
import { notify } from '../../utils/handler';
import requests from '../../utils/requests';
import apiRequest from '../../services';

const RegisterEmail = (props) => {

    const [data, setData] = useState({
        email: '',
        confirmEmail: '',
        password: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const user = useSelector(state => state.user.info);
    const cookies = new Cookies();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleChange = (e) => {
        const target = e.target;
        setData(prevData => ({
            ...prevData,
            [target.id]: target.value
        }));
    }
    const validate = () => {
        const isEmailValid = validator.isEmail(data.email);
        const isConfirmEmailValid = validator.isEmail(data.confirmEmail);
        const isPasswordStrong = validator.isStrongPassword(data.password, {minSymbols: 0});
        if (!isEmailValid || !isConfirmEmailValid) {
            notify("Please enter a valid email");
            return false;
        }
        if (data.email !== data.confirmEmail) {
            notify("Please make sure that the email IDs match");
            return false;
        }
        if (!isPasswordStrong) {
            notify("Please enter a strong password");
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!validate()) return;
        const response = await apiRequest({
            method: 'POST',
            url: requests.emailregister,
            data: {
                email: data.email,
                password: data.password
            }
        });
        if (response.success && response.data && response.data.token) {
            const date = new Date();
            date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
            cookies.set('SID', response.data.token, { expires: date });
            cookies.set('userType', 'client', { expires: date });
            props.onComplete({...response.data, email: data.email});
        } else {
            notify(response.message || response.data.message || 'Registration failed!')
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-25">
                    <label className="label" htmlFor="email">Email ID *</label>
                </div>
                <div className="col-75">
                    <Input 
                        className="register_formfield" 
                        value={data.email} 
                        onChange={handleChange} 
                        type="email" 
                        id="email" 
                        name="email" 
                        placeholder="Kindly enter your email ID" 
                        required 
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-25">
                    <label className="label" htmlFor="email">Confirm Email ID *</label>
                </div>
                <div className="col-75">
                    <Input 
                        className="register_formfield" 
                        value={data.confirmEmail} 
                        onChange={handleChange} 
                        type="email" 
                        id="confirmEmail" 
                        name="email" 
                        placeholder="Please confirm your email ID" 
                        required 
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-25">
                    <label className="label" htmlFor="password">Password *</label>

                </div>
                <div className="col-75">
                    <Input
                        className="register_formpassword"
                        id="password"
                        placeholder="Please enter the password"
                        type={showPassword ? "text" : "password"}
                        onChange={handleChange}
                        value={data.password}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        } required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-25">

                </div>
                <div className="col-75">
                    {PASSWORDDESCRIPTION}
                </div>
            </div>
            <br />

            <div className="row">
                <Button className="submit_button" type="submit" value="Submit"  >Submit</Button>
            </div>
        </form>
    )
}

export default RegisterEmail;