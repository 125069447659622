// import logo from 'assets/img/logo/logo_200.png';
import React from 'react';
import { connect } from 'react-redux';
import {
  MdHome,
  MdRepeat,
  MdPersonPin,
  MdFolder,
  MdEdit,
  MdFileDownload,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  Nav,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import sidebarBgImage from '../../assets/img/sidebar/sidebar-4.jpg';
import bn from '../../utils/bemnames';
import logo from "../../assets/logo/logo.png";
import userGirl from "../../assets/img/users/100_4.jpg"
import "./AdminSidebar.css"
import { MdPlaylistAddCheck } from 'react-icons/md';

const sidebarBackground = {
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navItems = [
  { to: '/admindashboard', name: 'admin dashboard', exact: true, Icon: MdHome },
  { to: '/adminclientlist', name: 'client list', exact: true, Icon: MdPersonPin },
  { to: '/kyclist', name:'KYC list', exact: true, Icon: MdPlaylistAddCheck },
  { to: '/adminwallets', name: 'wallet', exact: true, Icon: MdFolder },
  { to: '/withdrawals', name: 'withdrawals', exact: true, Icon: MdFileDownload },
  { to: '/adminpassword', name: 'change password', exact: true, Icon: MdEdit },
];
const bem = bn.create('sidebar');

class AdminSidebar extends React.Component {

  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
          <div className={bem.e('content')}>
            <div className="top-section">
              <div className="logo">
                <img src={logo} alt="webscript" />
              </div>
              <div>
                <h6 className="mb-0" style={{color:"white"}} > Maxi Trading</h6>
              </div>
            </div>
            <Nav vertical>
              <div className="clientName">
                <div className="avatar">
                  <img src={userGirl} alt="userGirl" />
                </div>
                <div className="user-info">
                  <h5>
                    {
                      !!this.props.user 
                      ? `${this.props.user.firstName} ${this.props.user.lastName}` 
                      : "User"
                    }
                  </h5>
                  <p>Administrator</p>
                </div>
              </div>
              {navItems.map(({ to, name, exact, Icon }, index) => (

                <NavItem key={index} className={bem.e('nav-item')}>

                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-capitalize"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Nav>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info
})

export default connect(mapStateToProps)(AdminSidebar);
