import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from "@material-ui/core/Button";
import {
    SUBMIT, UPLOADADDRESSPROOF, MAXIMUMFILESIZE,
    KYC2RULE1, KYC2RULE2, KYC2RULE3, KYCSUBRULE1, KYCSUBRULE2, KYCSUBRULE3, KYCSUBRULE4
} from "../../Constants";
import { convertBase64 } from '../../services';
import requests from '../../utils/requests';
import 'react-phone-number-input/style.css';

const AddressProofUploader = (props) => {
    
    const [data, setData] = useState({
        email: '',
        idType: '',
        idNumber: '',
    });
    const [file, setFile] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        console.log(props);
        if (props.user) {
            setData(prevData => ({
                ...prevData,
                email: props.user.email
            }));
        }
    }, [props.user]);

    const onChangeFile = async (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        const base64 = await convertBase64(e.target.files[0]);
        setFile(base64);
        props.setPreviewFile(base64);
    };

    const onUpload = async (event) => {
        event.preventDefault()
        const url = props.adminUpload ? requests.kycAdminAddressUpload : requests.kycAddressUpload;
        const payload = {...data, pic: file};
        props.handleUpload(url, payload);
    }

    return (
        <div className="KYCVerification2">
            <section className="formsection">
                <div className="formcontainer d-flex flex-column">
                    {props.isSignupFlow && !props.adminUpload && <button 
                        className="btn btn-link align-self-end mb-4"
                        onClick={props.handleAbortKYC}
                    >
                        Do this later?
                    </button>}
                    <form>
                        <h5 className='kycheading'>{UPLOADADDRESSPROOF}</h5><br />
                        <ol>
                            <li>{KYC2RULE1}</li>
                            <ul>
                                <li>{KYCSUBRULE1}</li>
                                <li>{KYCSUBRULE2}</li>
                                <li>{KYCSUBRULE3}</li>
                                <li>{KYCSUBRULE4}</li>
                            </ul>
                            <li>{KYC2RULE2}</li>
                            <li>{KYC2RULE3}</li>
                        </ol>
                        <div className="row">

                            <div className="col-100">
                                <input className="register1_formfield1" type="file" id="myFile" name="filename" onChange={onChangeFile} /><br /><br />
                                <p>{MAXIMUMFILESIZE}</p>
                            </div>
                        </div>
                        <br />
                        <div className="buttonrow">
                            <span><Button className='submit_button' onClick={onUpload} type="submit" value="Submit" >{SUBMIT}</Button></span>
                            <span><Button className='preview_button' onClick={props.handlePreview} type="submit" value="Preview" >Preview</Button></span>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}
export default AddressProofUploader;